const components = [];
let loaded = false;

export const registerBlock = (blockOfCode) => {
  components.push(blockOfCode);
};

export const importComponents = (coreObj) => {
  if (!loaded) {
    loaded = true;
    components.forEach((blockOfCode) => {
      blockOfCode(coreObj);
    });
  }
};
