import AwsConfig from 'lib/aws-config';

export const setOfflineAWSCredentials = () => {
  console.log('setting credentials');
  AwsConfig.credentials = {
    accessKeyId: '',
    secretAccessKey: '',
    sessionToken: '',
  };
};

export default class OfflineUserPool {
  getCurrentUser() {
    return {
      user: 'offlineUser',
      signOut: () => {
        console.log('Signing out mocked out when offline');
      },
    };
  }
}
