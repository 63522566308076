/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import {
  SubComponentPropsInterface,
  StyledComponentType,
} from 'supportchef-sdk-core';
import { BaseTextComponent, BaseTextProps } from './BaseTextComponent';

export interface TextComponentProps extends SubComponentPropsInterface {}

export interface TextComponentState {}

export class TextComponent extends React.Component<
  TextComponentProps,
  TextComponentState
> {
  static componentName = 'FormText';

  Text: StyledComponentType<BaseTextComponent, BaseTextProps>;

  constructor(props: TextComponentProps) {
    super(props);

    this.Text = props.coreObj.StyledComponentFactory<
      BaseTextComponent,
      BaseTextProps
    >(BaseTextComponent.componentName, props);
  }

  onChange = (evt, options) => {
    const { modifyFormEntryAtPath, path } = this.props;
    // const textUnsafe = evt.target.value;
    const textUnsafe = evt;

    // We do this dance because DynamoDB does not support null fields...
    // Possibly move this validation to the server side
    const text = textUnsafe === '' ? null : textUnsafe;

    modifyFormEntryAtPath(path, { text }, options);
  };

  render() {
    return <this.Text onChange={this.onChange} {...this.props} />;
  }
}
