/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import {
  SubComponentPropsInterface,
  StyledComponentType,
} from 'supportchef-sdk-core';
import { BaseButtonComponent, BaseButtonProps } from './BaseButtonComponent';
import { BaseTextComponent, BaseTextProps } from '../Text/BaseTextComponent';
import { styleModes as textStyles } from '../Text/TextStyles';
import { ChevronRight } from '/Symbols/chevronRight';
import { LoadingSymbol } from '/Symbols/Loading';

export interface ButtonComponentProps extends SubComponentPropsInterface {
  isFocused: any;
  error: any;
  disabled: any;
  // theme: any;
  children: any;
  placeholder?: string;
}

export interface ButtonComponentState {
  value: string;
  isLoading: boolean;
}

const defaultValues = {
  text: 'Placeholder Text',
};

const buttonTextStyle = [textStyles.buttonText.key];

export class ButtonComponent extends React.Component<
  ButtonComponentProps,
  ButtonComponentState
> {
  static componentName = 'ButtonComponent';
  static variableDefs = [{ types: ['string'] }];

  textRef = React.createRef<BaseTextComponent>();
  Button: StyledComponentType<BaseButtonComponent, BaseButtonProps>;
  Text: StyledComponentType<BaseTextComponent, BaseTextProps>;

  constructor(props: ButtonComponentProps) {
    super(props);

    this.state = { value: '', isLoading: false };

    this.Button = props.coreObj.StyledComponentFactory<
      BaseButtonComponent,
      BaseButtonProps
    >(BaseButtonComponent.componentName, props);

    this.Text = props.coreObj.StyledComponentFactory<
      BaseTextComponent,
      BaseTextProps
    >(BaseTextComponent.componentName, props);
  }

  componentDidMount = () => {
    this.setState({ isLoading: false });
  };

  onChange = (evt, options) => {
    const { modifyFormEntryAtPath, path, isEditing } = this.props;
    const textUnsafe = evt;

    // We do this dance because DynamoDB does not support null fields...
    // Possibly move this validation to the server side
    const text = textUnsafe === '' ? null : textUnsafe;

    modifyFormEntryAtPath(path, { text }, options);

    // modifyCustom({ text });
  };

  onClick = (evt) => {
    const { isEditing, graphTraverser, elementKey } = this.props;
    if (!isEditing) {
      // TODO: Replace this with a user facing "variable" that says this "link" is loading
      // Don't change the styling / customization until this part is complete
      const isLoading = graphTraverser.followLink(elementKey, null, () => {
        this.setState({ isLoading: false });
      });
      if (isLoading) {
        this.setState({ isLoading: isLoading });
      }
    } else {
      this.textRef.current.focus();
    }
  };

  render() {
    const { isEditing, element, placeholder } = this.props;
    const { value, isLoading } = this.state;

    return (
      <this.Button
        onChange={this.onChange}
        onClick={this.onClick}
        {...this.props}
        value={value}
        isLoading={isLoading}
      >
        <div className="textContainer">
          <this.Text
            ref={this.textRef}
            onChange={this.onChange}
            styleNames={buttonTextStyle}
            element={element}
            isEditing={isEditing}
            isLoading={isLoading}
            className="text"
          />
          {isLoading && <LoadingSymbol className="spinner" />}
        </div>
        {element.icon === 'chevron' && <ChevronRight />}
      </this.Button>
    );
  }
}
