import _cloneDeepWith from 'lodash/cloneDeepWith';
// import _cloneDeep from 'lodash/cloneDeep'
import _toPath from 'lodash/toPath';

export function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function unwrapVal(val, safe) {
  return val !== undefined ? val : safe;
}

export function escapeJson(str) {
  return str.replace(/[\n]/g, '\\n');
}

export function sortString(str1, str2) {
  var a = str1.toUpperCase(); // ignore upper and lowercase
  var b = str2.toUpperCase(); // ignore upper and lowercase
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // Strings must be equal
  return 0;
}

/**
 * Capitalize a string
 *
 * @param {string} str - The string to capitalize
 * @returns {string} - The string with the first letter capitalized
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getUnixTs = () => {
  return new Date().getTime();
};

const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
  ''
);
// A more performant, but slightly bulkier, RFC4122v4 solution.  We boost performance
// by minimizing calls to random()
export const uuid = function () {
  const chars = CHARS,
    uuid = new Array(36);
  let rnd = 0,
    r;
  for (let i = 0; i < 36; i++) {
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      uuid[i] = '-';
    } else if (i === 14) {
      uuid[i] = '4';
    } else {
      if (rnd <= 0x02) rnd = (0x2000000 + Math.random() * 0x1000000) | 0;
      r = rnd & 0xf;
      rnd = rnd >> 4;
      uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
    }
  }
  return uuid.join('');
};

// Returns if a button is escape, and if it is - stops propagation
export const isEscape = (event) => {
  let isEscape = false;
  if ('key' in event) {
    isEscape = event.key === 'Escape' || event.key === 'Esc';
  } else {
    isEscape = event.keyCode === 27;
  }
  if (isEscape) {
    event.stopPropagation();
  }
  return isEscape;
};

export const isEscapeAndModalClosed = (event) => {
  const isPressEscape = isEscape(event);
  if (isPressEscape && isModalOverlayActive()) {
    return false;
  }
  return isPressEscape;
};

function internalDeepCloneToPath(object, path) {
  // console.log(`cloning: ${JSON.stringify(object)} at path ${path}`);

  if (Array.isArray(object) && path[0]) {
    return object.map((item, index) => {
      // Replace the item at specified index
      // This is == on purpose (force casting to int)
      if (index === parseInt(path[0])) {
        const [, ...newPath] = path;
        return internalDeepCloneToPath(item, newPath);
      }

      // Leave every other item unchanged
      return item;
    });
  }

  return _cloneDeepWith(object, (value, key, object, stack) => {
    // console.log(
    //   `cloning inside function ${JSON.stringify(value)} and key ${key}`
    // );

    // If we are cloning the first part of the path we're interested in
    // then we recurse, and return the new object
    if (key === path[0] && path[0]) {
      const [, ...newPath] = path;
      return internalDeepCloneToPath(value, newPath);
    }

    // In general we make the deep copy shallow by just returning
    // the value here - meaning it doesn't recurse further.
    // However if the key is undefined - it means we're copying
    // the root object, and we should just let lodash do it's thing
    if (key !== undefined) {
      return value;
    }
  });
}

// A version of cloneDeep that only clones deeply down to a
// specified path.
// This is useful when you are modifying a specific path and don't
// want the performance hit of copying all the other elements
export function cloneDeepToPath(object, path) {
  // If there are issues then you can use the below with a perf hit
  // return _cloneDeep(object);
  const pathArray = _toPath(path);
  return internalDeepCloneToPath(object, pathArray);
}

export const generateNodeId = () => {
  return `n-${makeid()}`;
};

export const generateLinkId = () => {
  return `l-${makeid()}`;
};

export const generateEntryId = () => {
  return `e-${makeid()}`;
};

export const generateVariableId = () => {
  return `v-${makeid()}`;
};

export const generateInputId = () => {
  return `i-${makeid()}`;
};

export const generateConditionalId = () => {
  return `c-${makeid()}`;
};

// https://stackoverflow.com/a/1349426
export function makeid(length = 5) {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

let overlayNode;

export function getOverlayNode() {
  if (!overlayNode) {
    overlayNode = document.getElementById('support-chef-overlay');
  }
  return overlayNode;
}

export function isNodeInsideOverlay(target) {
  return getOverlayNode().contains(target);
}

// We make a separate function that inspects if the modal is active or not
// This is used to not trigger escapes in certain cases. While the modal properly
// stops propogation, it's not in the same DOM node, so it has issues
export function isModalOverlayActive() {
  const hasModal = getOverlayNode().getElementsByClassName('ant-modal-mask')[0];
  if (hasModal) {
    const modalIsHidden = getOverlayNode().getElementsByClassName(
      'ant-modal-mask-hidden'
    )[0];
    if (modalIsHidden) {
      return false;
    }
    return true;
  }
  return false;
}

export function buildCanonicalQueryString(queryParams) {
  if (Object.keys(queryParams).length < 1) {
    return '';
  }

  let sortedQueryParams = [];
  for (let property in queryParams) {
    if (queryParams.hasOwnProperty(property)) {
      sortedQueryParams.push(property);
    }
  }
  sortedQueryParams.sort();

  let canonicalQueryString = '';
  for (let i = 0; i < sortedQueryParams.length; i++) {
    canonicalQueryString +=
      sortedQueryParams[i] +
      '=' +
      encodeURIComponent(queryParams[sortedQueryParams[i]]) +
      '&';
  }
  return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
}
