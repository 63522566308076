/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import { DebugInterface } from './DebugInterface';

export interface DebugButtonProps {
  reason: string;
  versions: any;
  projectVer: any;
  setVersion: (string) => void;
}

export interface DebugButtonState {
  isDebugOpen: boolean;
}

const defaultValues = {
  text: 'Placeholder Text',
};

export class DebugButton extends React.Component<
  DebugButtonProps,
  DebugButtonState
> {
  static componentName = 'DebugButton';

  constructor(props: DebugButtonProps) {
    super(props);

    this.state = {
      isDebugOpen: false,
    };
  }

  async componentDidUpdate(prevProps) {
    const { isDebugOpen } = this.state;
    if (isDebugOpen && this.props.projectVer !== prevProps.projectVer) {
      this.setState({ isDebugOpen: false });
    }
  }

  swapDebugOpenState = () => {
    this.setState((state) => ({ isDebugOpen: !state.isDebugOpen }));
  };

  render() {
    const { reason, versions, projectVer, setVersion } = this.props;
    const { isDebugOpen } = this.state;

    return (
      <React.Fragment>
        <div
          css={css`
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 16px;
            bottom: 16px;
            height: 54px;
            background: blue;
            background-image: linear-gradient(141deg, #00ffde 0%, #319df1 100%);
            border-radius: 27px;
            padding: 0 15px;
            color: white;
            cursor: pointer;
            box-sizing: border-box;
            width: ${isDebugOpen ? '103px' : '54px'};
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            z-index: 1001;
            @supports (padding-bottom: env(safe-area-inset-bottom)) {
              --safe-area-inset-bottom: env(safe-area-inset-bottom);
              bottom: calc(var(--safe-area-inset-bottom) + 16px);
            }
          `}
          onClick={this.swapDebugOpenState}
        >
          {isDebugOpen && (
            <div
              css={css`
                margin-right: 10px;
              `}
            >
              Close
            </div>
          )}
          <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={isDebugOpen ? '0 0 352 512' : '0 0 512 512'}
            css={css`
              width: ${isDebugOpen ? '22px' : '26px'};
              margin-top: ${isDebugOpen ? '0px' : '-2px'};
            `}
          >
            {isDebugOpen ? (
              <path
                fill="white"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              />
            ) : (
              <path
                fill="white"
                d="M511.988 288.9c-.478 17.43-15.217 31.1-32.653 31.1H424v16c0 21.864-4.882 42.584-13.6 61.145l60.228 60.228c12.496 12.497 12.496 32.758 0 45.255-12.498 12.497-32.759 12.496-45.256 0l-54.736-54.736C345.886 467.965 314.351 480 280 480V236c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v244c-34.351 0-65.886-12.035-90.636-32.108l-54.736 54.736c-12.498 12.497-32.759 12.496-45.256 0-12.496-12.497-12.496-32.758 0-45.255l60.228-60.228C92.882 378.584 88 357.864 88 336v-16H32.666C15.23 320 .491 306.33.013 288.9-.484 270.816 14.028 256 32 256h56v-58.745l-46.628-46.628c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0L141.255 160h229.489l54.627-54.627c12.498-12.497 32.758-12.497 45.256 0 12.496 12.497 12.496 32.758 0 45.255L424 197.255V256h56c17.972 0 32.484 14.816 31.988 32.9zM257 0c-61.856 0-112 50.144-112 112h224C369 50.144 318.856 0 257 0z"
              />
            )}
          </svg>
        </div>
        {isDebugOpen && (
          <DebugInterface
            reason={reason}
            versions={versions}
            projectVer={projectVer}
            setVersion={setVersion}
          />
        )}
      </React.Fragment>
    );
  }
}
