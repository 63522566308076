import React from 'react';
import { ThemedComponentClass } from './themes/ThemeProvider/ThemeHoc';
import { ComponentRegisterProps } from './ComponentInterface';

const componentDict = {};
const editorOnlyComponentDict = {};

export const registerComponent = (Component: { componentName: string }) => {
  // console.log('Registering component', Component.componentName);
  componentDict[Component.componentName] = Component;
};

export const registerEditorOnlyComponent = (Component: {
  componentName: string;
}) => {
  // console.log('Registering component', Component.componentName);
  editorOnlyComponentDict[Component.componentName] = Component;
};

const _componentFactory = (className: string, isEditing: boolean) => {
  if (isEditing && editorOnlyComponentDict[className]) {
    return editorOnlyComponentDict[className];
  }
  if (!componentDict[className]) {
    console.error(
      'Could not find component for class',
      className,
      componentDict
    );
  }
  return componentDict[className];
};

export const ComponentFactoryFromClass = (
  className: string,
  registerProps: ComponentRegisterProps
) => {
  return _componentFactory(className, registerProps.isEditing);
};

export const ComponentFactory = (
  componentDescription: {
    config: { class: string };
  },
  registerProps?: ComponentRegisterProps
) => {
  if (!componentDescription.config) {
    console.error('Failed to load component description', componentDescription);
    return null;
  }
  const isEditing = registerProps ? registerProps.isEditing : false;
  return _componentFactory(componentDescription.config.class, isEditing);
};

export function registerStyledComponent(
  styleObj,
  StyledComponent: {
    componentName: string;
  },
  stylePropModifier?: any,
  onThemeUpdate?: any,
  editorOnly?: boolean
) {
  const styledComp = this.withThemedComponent(
    styleObj.styleCategory,
    stylePropModifier,
    onThemeUpdate
  )(StyledComponent);
  // console.log('Registering component', StyledComponent.componentName);
  if (editorOnly) {
    editorOnlyComponentDict[StyledComponent.componentName] = styledComp;
  } else {
    componentDict[StyledComponent.componentName] = styledComp;
  }
}

// export interface StyledComponentType<
//   T extends React.Component,
//   OriginalProps extends {}
// >
//   extends ThemedComponentClass<
//     Omit<Omit<OriginalProps, 'themedProps'>, 'coreObj'> & React.RefAttributes<T>
//   > {}

export type StyledComponentType<T, OriginalProps> = ThemedComponentClass<
  Pick<
    Pick<OriginalProps, Exclude<keyof OriginalProps, 'themedProps'>>,
    Exclude<Exclude<keyof OriginalProps, 'themedProps'>, 'coreObj'>
  > &
    React.RefAttributes<T>
>;

// export interface StyledComponentType<
//   T extends React.Component,
//   OriginalProps extends {}
// >
//   extends ThemedComponentClass<
//     Pick<
//       Pick<OriginalProps, Exclude<keyof OriginalProps, 'themedProps'>>,
//       Exclude<Exclude<keyof OriginalProps, 'themedProps'>, 'coreObj'>
//     > &
//       React.RefAttributes<T>
//   > {}

export const StyledComponentFactory = <
  T extends React.Component,
  OriginalProps extends {}
>(
  className: string,
  registerProps: ComponentRegisterProps
) => {
  return ComponentFactoryFromClass(
    className,
    registerProps
  ) as StyledComponentType<T, OriginalProps>;
};
