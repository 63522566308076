import { InputComponent } from './InputComponent';
import registerBaseInputComponent from './BaseInputComponent';
import * as InputStyles from './InputStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  registerBaseInputComponent(coreObj);
  coreObj.registerComponent(InputComponent);
  coreObj.registerStyles(InputStyles);
});

export * from './InputComponent';
export * from './BaseInputComponent';
export { InputStyles };
