/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import {
  CoreType,
  ThemePropsType,
  SubComponentPropsInterface,
} from 'supportchef-sdk-core';
import * as inputStyle from './InputStyles';

const SIZE = {
  default: 'default',
  compact: 'compact',
};

export interface BaseInputProps extends SubComponentPropsInterface {
  focused?: boolean;
  adjoined?: any;
  error?: any;
  disabled?: any;
  size?: any;
  theme: any;
  children?: any;
  autoFocus?: any;
  inputRef?: any;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  value?: string;
  placeholder?: string;
  themedProps: ThemePropsType;
  coreObj: CoreType;
}

export interface BaseInputComponentState {
  focused: boolean;
}

const defaultValues = {
  text: 'Placeholder Text',
};

export class BaseInputComponent extends React.Component<
  BaseInputProps,
  BaseInputComponentState
> {
  static componentName = 'BaseInputComponent';

  static defaultProps = {
    'aria-label': null,
    'aria-labelledby': null,
    'aria-describedby': null,
    autoComplete: 'on',
    autoFocus: false,
    disabled: false,
    error: false,
    name: '',
    inputRef: React.createRef(),
    onBlur: () => {},
    onChange: () => {},
    onKeyDown: () => {},
    onKeyPress: () => {},
    onKeyUp: () => {},
    onFocus: () => {},
    overrides: {},
    placeholder: '',
    required: false,
    size: SIZE.default,
    type: 'text',
  };

  constructor(props: BaseInputProps) {
    super(props);

    this.state = {
      focused: this.props.autoFocus || false,
    };
  }

  componentDidMount() {
    const { autoFocus, inputRef } = this.props;
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }

  onFocus = (e) => {
    this.setState({ focused: true });
    this.props.onFocus(e);
  };

  onBlur = (e) => {
    this.setState({ focused: false });
    // https://github.com/apache/cordova-ios/issues/417#issuecomment-531773144
    // TODO: This should be applied to everything that makes the keyboard
    // show up and should only apply to iOS
    window.scrollBy(0, 0);
    this.props.onBlur(e);
  };

  getInputProps = (props) => {
    const {
      autoComplete,
      disabled,
      error,
      id,
      inputRef,
      ref,
      name,
      onChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      required,
      rows,
      type,
      value,
      autoFocus,
    } = props;

    return {
      ref: ref || inputRef,
      'aria-label': props['aria-label'],
      'aria-labelledby': props['aria-labelledby'],
      'aria-describedby': props['aria-describedby'],
      'aria-invalid': !!error,
      'aria-required': required,
      autoComplete,
      disabled,
      id,
      name,
      onBlur: this.onBlur,
      onChange,
      onFocus: this.onFocus,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      type,
      autoFocus,
      value: value ? value : '',
      // ...(type === CUSTOM_INPUT_TYPE.textarea ? { rows } : {}),
    };
  };

  render() {
    const {
      isEditing,
      element,
      placeholder,
      disabled,
      themedProps,
      coreObj,
    } = this.props;
    const { focused } = this.state;
    const { StyledComponent: InputContainer, classNames } = themedProps;

    const inputSharedProps = {
      ...this.props,
      ...this.state,
    };

    const inputProps = this.getInputProps(inputSharedProps);

    const className =
      classNames +
      ' ' +
      coreObj.boolPropsToClassName(inputSharedProps, [
        'error',
        'disabled',
        'focused',
      ]);

    return (
      <InputContainer className={className}>
        <input
          className={className}
          {...inputProps}
          value={isEditing && focused ? placeholder : inputProps.value}
        />
      </InputContainer>
    );
  }
}

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(inputStyle, BaseInputComponent);
