import {
  FETCHING_USER,
  FETCH_USER_FAILED,
  FETCHED_USER,
  UPDATE_USER_HELP_CONTENT,
  UPDATED_USER,
  UPDATE_USER_FAILED,
} from '../actions/types';

// Maintains a map of cognitoId to user objects
export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_USER:
      return {
        ...state,
        fetchingUser: true,
        me: undefined,
        error: null,
      };
    case FETCH_USER_FAILED:
      return {
        ...state,
        fetchingUser: false,
        error: action.error,
        me: undefined,
        [action.identityId]: undefined,
      };
    case FETCHED_USER:
      return {
        ...state,
        fetchingUser: false,
        error: null,
        me: action.user,
        [action.identityId]: action.user,
      };
    case UPDATE_USER_HELP_CONTENT:
      const { helpContent } = action;
      return { ...state, me: { ...(state.me || {}), helpContent } };
    case UPDATED_USER:
      return { ...state };
    case UPDATE_USER_FAILED:
      return { ...state };
    default:
      return state;
  }
};
