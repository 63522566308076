import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'Text';

export const metadata = {};

export const styleModes = {
  default: {
    key: 'default',
  },
  maxWidth: {
    key: 'menuItem',
    prio: defaultStylePrio + 1,
  },
  menuItem: {
    key: 'menuItem',
    prio: defaultStylePrio,
  },
  header: {
    key: 'header',
    prio: defaultStylePrio,
  },
  label: {
    key: 'label',
    prio: defaultStylePrio,
  },
  caption: {
    key: 'caption',
    prio: defaultStylePrio,
  },
  // multipleChoice: {
  //   key: 'multipleChoice',
  //   prio: defaultStylePrio,
  // },
  buttonText: {
    key: 'buttonText',
    prio: defaultStylePrio,
  },
  description: {
    key: 'description',
    prio: defaultStylePrio,
  },
  menuHeader: {
    key: 'menuHeader',
    prio: defaultStylePrio,
  },
  largeImageCaption: {
    key: 'largeImageCaption',
    prio: defaultStylePrio,
  },
};

export const styles = {
  default: {
    background: 'transparent',
    resize: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    minWidth: '1px', // This way the editor will always show the cursor
  },
  [styleCategoryKey(styleModes.maxWidth)]: {
    width: '100%',
  },
  [styleCategoryKey(styleModes.header)]: {
    font: styleKey(styleKeys.font.t500),
    fontWeight: 500,
    color: styleKey(styleKeys.colors.foreground),
    cursor: 'auto',
    display: 'block',
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    marginTop: styleKey(styleKeys.sizing.t300),
    marginRight: '0',
    marginBottom: styleKey(styleKeys.sizing.t300),
    marginLeft: '0',
    ['&.disabled']: {
      color: styleKey(styleKeys.colors.foregroundAlt),
      cursor: 'not-allowed',
    },
  },
  [styleCategoryKey(styleModes.label)]: {
    font: styleKey(styleKeys.font.t350),
    fontWeight: 500,
    color: styleKey(styleKeys.colors.foreground),
    cursor: 'auto',
    display: 'block',
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    marginTop: styleKey(styleKeys.sizing.t300),
    marginRight: '0',
    marginBottom: styleKey(styleKeys.sizing.t300),
    marginLeft: '0',
    ['&.disabled']: {
      color: styleKey(styleKeys.colors.foregroundAlt),
      cursor: 'not-allowed',
    },
  },
  [styleCategoryKey(styleModes.caption)]: {
    font: styleKey(styleKeys.font.t200),
    color: styleKey(styleKeys.colors.foregroundAlt),
    paddingTop: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '0',
    marginTop: styleKey(styleKeys.sizing.t300),
    marginRight: '0',
    marginBottom: styleKey(styleKeys.sizing.t300),
    marginLeft: '0',

    ['&.error']: {
      color: styleKey(styleKeys.colors.negative.t400),
    },
  },
  // [styleKey(styleModes.multipleChoice)]: {
  //   font: styleKey(styleKeys.font.t200),
  //   color: styleKey(styleKeys.colors.foreground),
  //   paddingTop: '0',
  //   paddingRight: '0',
  //   paddingBottom: '0',
  //   paddingLeft: '0',
  //   marginTop: styleKey(styleKeys.sizing.t300),
  //   marginRight: '0',
  //   marginBottom: styleKey(styleKeys.sizing.t300),
  //   marginLeft: '0',
  //   width: '100%',
  // },
  [styleCategoryKey(styleModes.buttonText)]: {},
  [styleCategoryKey(styleModes.description)]: {
    color: styleKey(styleKeys.colors.foregroundAlt),
  },
  [styleCategoryKey(styleModes.menuHeader)]: {
    color: styleKey(styleKeys.colors.mono.t700),
    font: styleKey(styleKeys.font.t300),
    width: customStyleTypes.fullWidth.key,
    paddingTop: '30px',
    paddingBottom: '5px',
    paddingLeft: '20px',
    borderBottom: `solid ${styleKey(styleKeys.colors.mono.t500)}`,
    borderWidth: '0.5px',
  },
  [styleCategoryKey(styleModes.largeImageCaption)]: {
    color: styleKey(styleKeys.colors.mono.t700),
    paddingTop: '10px',
    paddingBottom: '5px',
    fontSize: '30px',
    textAlign: 'center',
  },
};
