import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'GenericContainer';

export const metadata = {
  htmlType: 'div',
  elements: {
    svg: {
      desc: 'The svg image',
    },
  },
  basicControl: {},
};

export const styleModes = {
  fill: {
    key: 'fill',
    prio: defaultStylePrio,
  },
  centerVertically: {
    key: 'centerVertically',
    prio: defaultStylePrio,
  },
};

export const styles = {
  default: {},

  [styleCategoryKey(styleModes.fill)]: {
    flexGrow: 1,
  },
  [styleCategoryKey(styleModes.centerVertically)]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
