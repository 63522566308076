/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import * as React from 'react';
import {
  CoreType,
  ThemePropsType,
  ComponentPropsInterface,
} from 'supportchef-sdk-core';
import * as genericContainerStyle from './GenericContainerStyles';

export interface GenericContainerComponentCustomProps {
  isEditing: boolean;
}

export interface GenericContainerComponentProps
  extends ComponentPropsInterface<GenericContainerComponentCustomProps> {
  element: any;
  overrideComponents?: any;
  nodeId?: string;
  themedProps: ThemePropsType;
  coreObj: CoreType;
}

export interface GenericContainerComponentState {
  containerClassNames: string[];
}

class GenericContainerComponent extends React.Component<
  GenericContainerComponentProps,
  GenericContainerComponentState
> {
  static componentName = 'GenericFormComponent';

  static getVariables = (element, path) => {
    return [];
  };

  constructor(props: GenericContainerComponentProps) {
    super(props);

    this.state = {
      containerClassNames: [],
    };
  }

  addContainerClassNames = (classNames) => {
    this.setState((state) => {
      const newContainerClassNames = classNames.concat(
        state.containerClassNames
      );
      return {
        containerClassNames: Array.from(new Set(newContainerClassNames)),
      };
    });
  };

  render() {
    const {
      element,
      path,
      overrideComponents,
      graphTraverser,
      nodeId,
      isEditing,
      themedProps,
      ...rest
    } = this.props;

    const { containerClassNames } = this.state;
    const { StyledComponent: StyledContainerDiv } = themedProps;
    const { config, emptyDesc } = element;

    let varname;
    if ('output' in config) {
      varname =
        config.output.alias || this.props.utils.getVariableKey(this.props);
    }
    // Add varname for all elements - realistically only ones that have outputs
    // will use it.
    // TODO: refactor this handling + type the system
    const vars = { varname };

    const internalComponents = config.elements.map((subElement, index) => {
      // console.log(index);
      // console.log(subElement);
      const { class: elementClass, key, ...elementRest } = subElement;
      let OverrideClass;
      if (overrideComponents) {
        OverrideClass = overrideComponents[elementClass];
      }

      // Default to the Override Class, fall back to default class defined in SDK
      const ElementClass =
        OverrideClass ||
        this.props.coreObj.ComponentFactoryFromClass(elementClass, this.props);
      if (!ElementClass) {
        return <div> Error loading element: {elementClass} </div>;
      }

      // We set a key that is the subElement key + nodeId because
      // If you change screens to the same structure the element won't be
      // re-instantiated (eg - button switched to loading state).
      return (
        <ElementClass
          key={subElement.key + nodeId}
          element={subElement}
          path={`${path}config.elements[${index}]`}
          isEditing={isEditing}
          graphTraverser={graphTraverser}
          addContainerClassNames={this.addContainerClassNames}
          {...vars}
          {...rest}
          {...elementRest}
        />
      );
    });

    if (isEditing && internalComponents.length === 0) {
      return (
        <StyledContainerDiv
          className={containerClassNames.join(' ') + ' emptyEditingElement'}
        >
          {(config.editor && config.editor.emptyDesc) || 'Empty element'}
        </StyledContainerDiv>
      );
    }

    return (
      <StyledContainerDiv className={containerClassNames.join(' ')}>
        {internalComponents}
      </StyledContainerDiv>
    );
  }
}

const stylePropModifier = (props) => {
  const { styleNames } = props.element.config;
  return { ...props, styleNames };
};

export const GenericContainerComponentName =
  GenericContainerComponent.componentName;

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(
    genericContainerStyle,
    GenericContainerComponent,
    stylePropModifier
  );
