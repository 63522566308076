import { PageContainer } from './PageContainer';
import * as PageStyles from './PageStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  coreObj.registerStyledComponent(PageStyles, PageContainer);
  coreObj.registerStyles(PageStyles);
});

export * from './PageContainer';
export { PageStyles };
