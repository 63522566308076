import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

// If children have fullWidth then margins get ignored
const notFullWidthSelector =
  '& > *:not(.fullWidth), body > .passthroughPage > .passthroughPage > .passthroughPage > *:not(.fullWidth), & > .passthroughPage > .passthroughPage > .passthroughPage > *:not(.fullWidth)';

export const styleCategory = 'Page';

export const styleModes = {
  questions: {
    key: 'questions',
    prio: defaultStylePrio,
    name: 'Questions Style',
  },
  menu: {
    key: 'menu',
    prio: defaultStylePrio,
    name: 'Menu Style',
  },
  fillPage: {
    key: 'fillPage',
    prio: defaultStylePrio,
    name: 'Fill Page Style',
  },
};

export const metadata = {
  htmlType: 'div',
  defaultStyles: [styleCategoryKey(styleModes.questions)],
  element: {
    [notFullWidthSelector]: { desc: 'Set margin left and right' },
  },
  basicControl: {},
};

export const styles = {
  default: {
    display: 'flex',
    flexGrow: 1,
    // minHeight: '100%',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
    [notFullWidthSelector]: {
      marginLeft: '28px',
      marginRight: '28px',
    },
    // This is needed so that children can inherit the global color
    // Specificically for the editor interface
    background: 'inherit',
    /**
     * This is primarily needed for simulator
     * Not including this is irrelevant for the rest
     */
    // overflow: 'auto',
  },
  [styleCategoryKey(styleModes.questions)]: {
    padding: '1em 0',
  },
  [styleCategoryKey(styleModes.menu)]: {},
  [styleCategoryKey(styleModes.fillPage)]: {
    paddingTop: '0em',
    paddingBottom: '1em',
    // backgroundColor: '#F9F9F9',
  },
};

export const globalStyles = {
  [styleCategoryKey(styleModes.questions)]: {
    [customStyleTypes.body.key]: {
      backgroundColor: styleKey(styleKeys.colors.background),
    },
  },
  [styleCategoryKey(styleModes.menu)]: {
    [customStyleTypes.body.key]: {
      backgroundColor: styleKey(styleKeys.colors.backgroundMenu),
    },
  },
  [styleCategoryKey(styleModes.fillPage)]: {
    [customStyleTypes.body.key]: {
      backgroundColor: styleKey(styleKeys.colors.background),
    },
  },
};
