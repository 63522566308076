import onlineConfig from './config.json';
import offlineConfig from './offlineConfig.json';

const config = process.env.REACT_APP_IS_OFFLINE ? offlineConfig : onlineConfig;

export default {
  awsIotHost: config.AwsIotHost,
  awsRegion: config.AwsRegion,
  awsCognitoUserPoolId: config.UserPoolId,
  awsCognitoUserPoolAppClientId: config.UserPoolClientId,
  awsCognitoIdentityPoolId: config.IdentityPoolId,
  awsApiGatewayInvokeUrl: config.AwsApiGatewayInvokeUrl,
  externalApiEndpoint: config.ExternalApiEndpoint,
  socialFacebookAppId: config.FacebookAppId,
  socialGoogleClientId: config.GoogleAppId,
  logLevel: config.LogLevel,
  mqttDebugLevel: config.MqttDebugLevel,
  serviceStage: config.ServiceStage,
  isOffline: config.isOffline,
  publicProjectWebsiteUrl: config.PublicProjectWebsiteUrl,
};
