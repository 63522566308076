import {
  styleCategoryKey,
  globalStyleCategories,
} from '/themes/ComponentStyling/customStylesConverter';

// Required fields for a theme, maybe instead this should just be
// in the theme default
export const requiredVarThemes = {
  default: {
    colors: {
      primary: {
        t50: '#e8f0fe',
        t100: '#c3d7fb',
        t200: '#95b8f8',
        t300: '#6195f5',
        t400: '#276ef1',
        t500: '#1d53b5',
        t600: '#143779',
        t700: '#0a1c3c',
        flipGradient: false,
      },

      negative: {
        t50: '#fcecea',
        t100: '#f8cdc8',
        t200: '#f2a69d',
        t300: '#ec7a6d',
        t400: '#e54937',
        t500: '#ac3729',
        t600: '#73251c',
        t700: '#39120e',
        flipGradient: false,
      },

      warning: {
        t50: '#fdf0e6',
        t100: '#fad7bc',
        t200: '#f6b989',
        t300: '#f2964f',
        t400: '#ed6f0e',
        t500: '#b2530b',
        t600: '#773807',
        t700: '#3b1c04',
        flipGradient: false,
      },

      positive: {
        t50: '#e5f5ee',
        t100: '#bae5d1',
        t200: '#86d2ae',
        t300: '#4abc86',
        t400: '#07a35a',
        t500: '#057a44',
        t600: '#04522d',
        t700: '#022917',
        flipGradient: false,
      },

      mono: {
        // t50: white,
        t100: '#ffffff',
        t200: '#f3f3f3',
        t300: '#e5e5e5',
        t400: '#d6d6d6',
        t500: '#c4c4c4',
        t600: '#b0b0b0',
        t700: '#999999',
        t800: '#7d7d7d',
        t900: '#595959',
        t1000: '#000000',
        flipGradient: false,
      },

      rating: {
        t200: '#FFE1A5',
        t400: '#FFC043',
      },
    },

    fontFamily: 'system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },

  [styleCategoryKey(globalStyleCategories.dark)]: {
    colors: {
      // primary: {
      //   t400: '#CCCCCC',
      // },
      // negative: {
      //   defaultVar: '--colors-negative-500',
      // },
      // warning: {
      //   defaultVar: '--colors-warning-500',
      // },
      mono: {
        // t50: gray200,
        t100: '#000000',
        t200: '#595959',
        t300: '#7d7d7d',
        t400: '#999999',
        t500: '#b0b0b0',
        t600: '#c4c4c4',
        t700: '#d6d6d6',
        t800: '#e5e5e5',
        t900: '#f3f3f3',
        t1000: '#ffffff',
      },

      // // Font Color
      // colorPrimary: { defaultVar: '--colors-mono-t100' },
      // colorSecondary: { defaultVar: '--colors-mono-t200' },

      // // Background
      // background: { defaultVar: '--colors-mono-t800' },
      // backgroundAlt: { defaultVar: '--colors-mono-t700' },
      // backgroundInv: { defaultVar: '--colors-mono-t100' },

      // // Foreground
      // foreground: { defaultVar: '--colors-mono-t100' },
      // foregroundAlt: { defaultVar: '--colors-mono-t300' },
      // foregroundInv: { defaultVar: '--colors-mono-t1000' },

      // // Borders
      // border: { defaultVar: '--colors-mono-t600' },
      // borderAlt: { defaultVar: '--colors-mono-t700' },
      // borderFocus: { defaultVar: '--colors-primary-t400' },
      // borderError: { defaultVar: '--colors-negative-t400' },

      // // Buttons
      // buttonPrimaryFill: { defaultVar: '--colors-primary-t400' },
      // buttonPrimaryText: '#FFFFFF',
      // buttonPrimaryHover: { defaultVar: '--colors-primary-t500' },
      // buttonPrimaryActive: { defaultVar: '--colors-primary-t600' },
      // buttonSecondaryFill: { defaultVar: '--colors-mono-t500' },
      // buttonSecondaryText: { defaultVar: '--colors-mono-t100' },
      // buttonSecondaryHover: { defaultVar: '--colors-mono-t400' },
      // buttonSecondaryActive: { defaultVar: '--colors-mono-t300' },
      // buttonTertiaryFill: { defaultVar: '--colors-mono-t700' },
      // buttonTertiaryText: { defaultVar: '--colors-mono-t100' },
      // buttonTertiaryHover: { defaultVar: '--colors-mono-t600' },
      // buttonTertiaryActive: { defaultVar: '--colors-mono-t500' },
      // buttonTertiarySelectedText: { defaultVar: '--colors-mono-t100' },
      // buttonTertiarySelectedFill: { defaultVar: '--colors-primary-t400' },
      // buttonMinimalFill: 'transparent',
      // buttonMinimalText: { defaultVar: '--colors-primary-t400' },
      // buttonMinimalHover: { defaultVar: '--colors-mono-t600' },
      // buttonMinimalActive: { defaultVar: '--colors-mono-t500' },
      // buttonDisabledFill: { defaultVar: '--colors-mono-t700' },
      // buttonDisabledText: { defaultVar: '--colors-mono-t500' },

      // // Input
      // inputFill: { defaultVar: '--colors-mono-t600' },
      // inputFillEnhancer: { defaultVar: '--colors-mono-t500' },
      // inputFillError: { defaultVar: '--colors-mono-t600' },
      // inputFillDisabled: { defaultVar: '--colors-mono-t700' },
      // inputTextDisabled: { defaultVar: '--colors-mono-t500' },
    },
  },
};

// This needs to be changed to have selectors, same as component styles
