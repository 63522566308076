import { TextComponent } from './TextComponent';
import * as TextStyles from './TextStyles';
import registerBaseTextComponent from './BaseTextComponent';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  registerBaseTextComponent(coreObj);
  coreObj.registerComponent(TextComponent);
  coreObj.registerStyles(TextStyles);
});

export * from './TextComponent';
export * from './BaseTextComponent';
export { TextStyles };
