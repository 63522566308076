/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import _isEqual from 'lodash/isEqual';
import AwsConfig from 'lib/aws-config';

import Config from '../config';
import log from 'lib/logging';
import { authUser, getUserToken, getCurrentUser } from './aws-cognito';
import { setOfflineAWSCredentials } from './offline/cognito-offline';
import { buildCanonicalQueryString } from 'lib/utils';

import {
  serializeSafeProject,
  serializeSafeProjectVersion,
} from 'redux/reducers/projectReducer';
import { userNotLoggedInMsg } from './errorHelpers';

// We want to make sure that nowhere in the software has a loop where
// the same endpoint is hit repeatedly as fast as possible
// (due to a server error) - We store the last request, and count
// to ensure this doesn't happen more than MAX_REPEAT_CALL times.
// This does not protect against cycles where multiple different API calls
// are caught in the cycle
let lastApiCallMade = {};
let lastApiCallRepeated = 0;
const MAX_REPEAT_CALL = 5;

export const invokeAPIGateway = async ({
  path,
  method = 'GET',
  headers = {},
  queryParams = {},
  body,
}) => {
  if (!(await authUser())) {
    throw new Error(userNotLoggedInMsg);
  }

  if (!AwsConfig.credentials && Config.isOffline) {
    setOfflineAWSCredentials();
  }

  // Prevent infinite looping in the case of an error
  // (The rest of our code should handle this properly - but this is a backstop)
  const thisCall = { path, method, headers, queryParams, body };
  if (_isEqual(thisCall, lastApiCallMade)) {
    lastApiCallRepeated += 1;
    if (lastApiCallRepeated >= MAX_REPEAT_CALL) {
      if (lastApiCallRepeated === MAX_REPEAT_CALL) {
        log.error('Code is in an API calling loop!');
      }
      // Prevent infinit speed infinit looping
      return new Promise((accept) => window.setTimeout(accept, 1000));
    }
  }
  lastApiCallMade = thisCall;

  log.infoGroup('API Gateway credentials');
  log.info(AwsConfig.credentials);
  log.info(AwsConfig.credentials.accessKeyId);
  log.info(AwsConfig.credentials.secretAccessKey);
  log.info(AwsConfig.credentials.sessionToken);
  log.info(Config.awsRegion);
  log.info(Config.awsApiGatewayInvokeUrl);
  log.infoGroupEnd();

  // const client = sigV4Client.newClient({
  //   accessKey: AwsConfig.credentials.accessKeyId,
  //   secretKey: AwsConfig.credentials.secretAccessKey,
  //   sessionToken: AwsConfig.credentials.sessionToken,
  //   region: Config.awsRegion,
  //   endpoint: Config.awsApiGatewayInvokeUrl,
  // });

  // const signedRequest = client.signRequest({
  //   method,
  //   path,
  //   headers,
  //   queryParams,
  //   body,
  // });

  // TODO: Shouldn't be fetching this everytime!
  const token = await getUserToken(getCurrentUser());
  let url = `${Config.awsApiGatewayInvokeUrl}/api/v1${path}`;
  let queryString = buildCanonicalQueryString(queryParams);
  if (queryString !== '') {
    url += '?' + queryString;
  }

  const signedRequest = {
    headers: { Authorization: `Bearer ${token}` },
    url,
  };

  const signedBody = body ? JSON.stringify(body) : body;
  const signedHeaders = signedRequest.headers;

  const results = await fetch(signedRequest.url, {
    method,
    headers: signedHeaders,
    body: signedBody,
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
};

// export const createUser = async username => {
//   let result;
//   try {
//     result = await invokeAPIGateway({
//       path: '/users',
//       method: 'POST',
//       body: { username },
//     });
//   } catch (error) {
//     log.error(error);
//   }
//   return result;
// };

// export const fetchUser = async identityId => {
//   const result = await invokeAPIGateway({
//     path: `/users/${encodeURIComponent(identityId)}`,
//     method: 'GET',
//   });
//   return result;
// };

export const getUserMe = async () => {
  const result = await invokeAPIGateway({
    path: `/users/me`,
    method: 'GET',
  });

  return result;
};

export const patchUserMe = async (user) => {
  const result = await invokeAPIGateway({
    path: `/users/me`,
    method: 'PATCH',
    body: user,
  });
  return result;
};

//
// Added for project service by Sean
//

export const createProject = async (
  accountId,
  stage,
  name,
  { diagram, version } = {}
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects`,
    method: 'POST',
    body: {
      name,
      diagram,
      version,
    },
  });

  return result;
};

export const fetchAllProjects = async (accountId, stage) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects`,
    method: 'GET',
  });
  return result;
};

export const fetchProject = async (
  accountId,
  stage,
  projectId,
  version = undefined
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}`,
    method: 'GET',
    queryParams: {
      version: encodeURIComponent(version),
    },
  });
  return result;
};

export const fetchProjectVersion = async (
  accountId,
  stage,
  projectId,
  version
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions/${encodeURIComponent(version)}`,
    method: 'GET',
  });
  return result;
};

export const deleteProject = async (accountId, stage, projectId) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}`,
    method: 'DELETE',
  });
  return result;
};

export const updateProject = async (accountId, stage, projectId, project) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}`,
    method: 'PATCH',
    body: serializeSafeProject(project),
  });
  return result;
};

export const createProjectVersion = async (
  accountId,
  stage,
  projectId,
  version,
  { diagram, name } = {}
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions`,
    method: 'POST',
    body: {
      name,
      version,
      diagram,
    },
  });

  return result;
};

export const deleteProjectVersion = async (
  accountId,
  stage,
  projectId,
  version
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions/${encodeURIComponent(version)}`,
    method: 'DELETE',
  });
  return result;
};

export const updateProjectVersion = async (
  accountId,
  stage,
  projectId,
  version,
  projectVer
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions/${encodeURIComponent(version)}`,
    method: 'PATCH',
    body: serializeSafeProjectVersion(projectVer),
  });
  return result;
};

export const updateProjectVersionFields = async (
  accountId,
  stage,
  projectId,
  version,
  { name }
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions/${encodeURIComponent(version)}`,
    method: 'PATCH',
    body: { name },
  });
  return result;
};

export const publishProjectVersion = async (
  accountId,
  stage,
  projectId,
  version,
  { projectVer }
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/stages/${encodeURIComponent(stage)}/projects/${encodeURIComponent(
      projectId
    )}/versions/${encodeURIComponent(version)}/publish`,
    method: 'POST',
    body: projectVer,
  });
  return result;
};

export const getPublicProject = async (projectPath, body) => {
  const path = `/public/projects/${projectPath}`;
  const url = `${Config.awsApiGatewayInvokeUrl}/api/v1${path}`;

  const results = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (results.status !== 200) {
    throw new Error(await results.text());
  }

  return results.json();
};

export const createAccessToken = async (
  accountId,
  name,
  stages,
  { publicKey, storePrivateData }
) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(accountId)}/tokens`,
    method: 'POST',
    body: {
      name,
      stages,
      publicKey,
      storePrivateData,
    },
  });

  return result;
};

export const fetchAllAccessTokens = async (accountId, stage) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(accountId)}/tokens`,
    method: 'GET',
  });
  return result;
};

export const fetchAccessToken = async (accountId, stage, token) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/tokens/${encodeURIComponent(token)}`,
    method: 'GET',
  });
  return result;
};

export const deleteAccessToken = async (accountId, stage, token) => {
  const result = await invokeAPIGateway({
    path: `/accounts/${encodeURIComponent(
      accountId
    )}/tokens/${encodeURIComponent(token)}`,
    method: 'DELETE',
  });
  return result;
};

export const fetchAccountsOnMe = async () => {
  const result = await invokeAPIGateway({
    path: `/users/me/accounts`,
    method: 'GET',
  });
  return result;
};

export const fetchAccountDetails = async (accountId) => {
  const result = await invokeAPIGateway({
    path: `/accounts/{accountId}`,
    method: 'GET',
  });
  return result;
};
