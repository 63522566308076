/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as transforms from '../ProcessTheme/customStyleTransforms';
import { getStyleByName } from '/themes/ComponentStyling/registerStyles';

export const customStyleTypes = {
  fullWidth: {
    key: 'fullWidth',
    desc: 'Makes an element the full width, even if the container has padding',
    isSelector: undefined,
    keepSelectorKey: undefined,
    properties: ['width'],
    transform: transforms.fullWidth,
  },
  fixedActive: {
    key: 'fixedActive',
    desc:
      'Used as a selector to make the :active pseudoselector work across platforms',
    isSelector: true,
    keepSelectorKey: false,
    properties: undefined,
    transform: transforms.fixedActive,
  },
  body: {
    key: 'body',
    desc: 'Used to specify the body of all content',
    isSelector: true,
    keepSelectorKey: false,
    properties: undefined,
    transform: transforms.fixedBody,
  },
  landscape: {
    key: 'landscape',
    desc: 'A selector to make parts only styled when in landscape',
    isSelector: true,
    keepSelectorKey: false,
    properties: undefined,
    transform: transforms.fixedLandscape,
  },
};

const minStylePriority = 0;
export const defaultStylePrio = 10;

// Full spec of fields:
// key: How to identify (globally unique id - user defined should be prefixed)
// desc: Description to show in editor UI
// prio: Ordering when multiple style modes conflict
// sub: Array of other styles that must be applied first (this bumps up the prio of this by these)

export const globalStyleCategories = {
  default: {
    key: 'default',
    prio: minStylePriority,
  },
  dark: {
    key: 'dark',
    desc: 'Device has dark mode preference',
    prio: minStylePriority + 1,
  },
};

const styleDelim = ' ';

export const styleCategoryKey = (...modes) => {
  return modes
    .sort()
    .map((mode) => mode.key)
    .join(' ');
};

const splitStyleKey = (singleStyleKey) => {
  return singleStyleKey.split(styleDelim);
};

// Merge styles
// TODO: should do a deep merge in case of conflicts
export const mergeStyles = (existingStyle, newStyle) => {
  return { ...existingStyle, ...newStyle };
};

function convertStyleToValid(style, props) {
  let convertedStyle = style;
  let classNames = [];

  Object.values(customStyleTypes).forEach((customStyle) => {
    /**
     * Transforms the convertedStyle object using the customStyle being observered
     * @param  {[type]} attr      The matching attribute
     * @param  {[type]} selectors array of selectors inside of convertedStyle
     */
    const transformStyle = (attr, selectors) => {
      if (customStyle.transform) {
        const {
          style: newStyle,
          classNames: newClassNames,
        } = customStyle.transform(convertedStyle, attr, {
          props,
          fullStyle: convertedStyle,
          selectors,
        });

        classNames = classNames.concat(newClassNames);
        convertedStyle = mergeStyles(convertedStyle, newStyle);
      }
    };

    // If the custom style operates on selectors, check that
    if (customStyle.isSelector && style[customStyle.key]) {
      transformStyle(customStyle.key, []);
      if (!customStyle.keepSelectorKey) {
        delete convertedStyle[customStyle.key];
      }
    }

    // Iterate through properties
    customStyle.properties &&
      customStyle.properties.forEach((styleProp) => {
        if (style[styleProp] === customStyle.key) {
          transformStyle(styleProp, []);
        }
      });
  });

  return [convertedStyle, classNames];
}

function convertStylesToValid(styles, props) {
  // console.log('styles', styles);
  let allClassNames = [];
  return [
    styles.map((style) => {
      const [formattedStyle, classNames] = convertStyleToValid(style, props);
      if (classNames && classNames.length > 0) {
        allClassNames = allClassNames.concat(classNames);
      }
      return formattedStyle;
    }),
    allClassNames,
  ];
}

// Non-composite key
const getStyleMode = (singleStyleKey, styleModes) => {
  if (styleModes && styleModes[singleStyleKey]) {
    return styleModes[singleStyleKey];
  }
  return globalStyleCategories[singleStyleKey];
};

// Composite key
const getApplicableStyleModes = (styleKey, styleModes) => {
  const splitKeys = splitStyleKey(styleKey);
  return splitKeys.map((singleStyleKey) =>
    getStyleMode(singleStyleKey, styleModes)
  );
};

/**
 *
 * @param allStyleNames - array of style names (eg: darkMode)
 * @param styles - The style definition itself
 * @param dependentKeysSet - Future merformance improvement, to skip calculation
 *                           of what keys we are dependent on
 */
const getMatchingStyles = (allStyleNames, styles, dependentKeysSet) => {
  const setNeeded = !!dependentKeysSet;
  const dependentKeysSetSafe = setNeeded ? new Set() : dependentKeysSet;

  const matchingStyleKeys = Object.keys(styles).filter((singleStyleKey) => {
    const styleKeys = splitStyleKey(singleStyleKey);

    if (setNeeded) {
      styleKeys.forEach((singleSplitKey) =>
        dependentKeysSetSafe.add(singleSplitKey)
      );
    }
    return styleKeys.every((singleSplitKey) =>
      allStyleNames.includes(singleSplitKey)
    );
  });

  // Always add default styles
  if (styles.default) {
    matchingStyleKeys.push('default');
  }

  return [matchingStyleKeys, dependentKeysSetSafe];
};

/**
 * Gets
 * @param styleNames An array of styles that apply globally - eg: ['darkMode']
 * @param theme Full Theme object
 * @param styles Styles defined outside of the styleKeys
 * @param styleModes An array of styles for this component (eg: ['defaultButton', 'primaryButton'])
 */
export const getApplicableStyles = (styleNames, theme, styles, styleModes) => {
  const safeStyleNames = styleNames || [];

  // TODO: Add in style names that come from theme/styleEngine
  const allStyleNames = safeStyleNames.concat(theme.styleCategories);

  const [styleKeys, dependentKeys] = getMatchingStyles(
    allStyleNames,
    styles,
    undefined
  );

  const prioMapping = {};

  // Get priority mapping first (so we don't have to generate it on every cmp operation)
  styleKeys.forEach((styleKey) => {
    const styleMode = getApplicableStyleModes(styleKey, styleModes);
    prioMapping[styleKeys] = styleMode ? styleMode.prio : defaultStylePrio;
  });

  // Get sorted keys based on priority
  const sortedStyleKeys = styleKeys.sort((styleKey1, styleKey2) => {
    const cmp = prioMapping[styleKey1] - prioMapping[styleKey2];
    // Fall back to alphabetic sorting if priorities are the same
    if (cmp === 0) {
      return styleKey1.localeCompare(styleKey2);
    }
    return cmp;
  });

  // Get the actual applied styles
  const applicableStyles = sortedStyleKeys.map((styleKey) => styles[styleKey]);
  // Sort based on priorities
  return [applicableStyles, dependentKeys];
};

const styleNamesEmpty = (styleNames) => {
  if (!styleNames || styleNames == []) {
    return true;
  }
  if (styleNames.length > 1) {
    return false;
  }
  // In case only style name is null or undefined
  return !styleNames[0];
};

const stripStylePaths = (stylesArray, ignoredStyles) => {
  console.log('Stripping style paths', stylesArray, ignoredStyles);
  return stylesArray.map((singleStyle) => {
    ignoredStyles.forEach((stylePaths) => {
      if (singleStyle[stylePaths[0]]) {
        delete singleStyle[stylePaths[0]];
      }
    });
    return singleStyle;
  });
};

/**
 * [description]
 * @param  {[type]} options.props
 * @param  {[type]} options.styleInfo Style info for this component
 * @return {dict}
 */
const createStyledComponent = (props, theme, styleInfo) => {
  const { styleNames, addContainerClassNames, isEditing } = props;
  const { metadata, styleModes, styles, globalStyles } = styleInfo;

  if (!metadata || !styles) {
    // || !theme) {
    console.error(
      'Style or theme info is missing metadata or styles information!'
    );

    return { StyledComponent: styled('div')() };
  }

  const { htmlType, defaultStyles, ignoreWhenEditing } = styleInfo.metadata;

  // If there is a default styleNames then use that if needed
  let styleNamesSafe = styleNames;
  if (defaultStyles && styleNamesEmpty(styleNames)) {
    styleNamesSafe = defaultStyles;
    // console.log('style names set to default styles', styleNamesSafe);
  }

  // Get array of styles (in proper order) for this element
  const [applicableStyles, dependentKeys] = getApplicableStyles(
    styleNamesSafe,
    theme,
    styles,
    styleModes
  );

  // Convert styles to valid ones (scanning for our "special" keys)
  let [convertedStyles, classNames] = convertStylesToValid(
    applicableStyles,
    props
  );

  // Remove styles that shouldn't be there when in editing interface
  if (isEditing && ignoreWhenEditing) {
    convertedStyles = stripStylePaths(convertedStyles, ignoreWhenEditing);
  }

  //
  addContainerClassNames && addContainerClassNames(classNames);

  const htmlTyleSafe = htmlType || props.htmlType || 'div';

  const StyledComponent = styled(htmlTyleSafe)(convertedStyles);

  let convertedGlobalStyles = {};
  if (globalStyles) {
    const [applicableGlobalStyles, globalDependentKeys] = getApplicableStyles(
      styleNamesSafe,
      theme,
      globalStyles,
      styleModes
    );

    [convertedGlobalStyles] = convertStylesToValid(
      applicableGlobalStyles,
      props
    );
  }

  return {
    StyledComponent,
    classNames,
    styles: convertedStyles,
    globalStyles: convertedGlobalStyles,
  };
};

export const createStyledComponentFromName = (props, theme, styleName) => {
  let styleInfo = getStyleByName(styleName);
  if (!styleInfo) {
    console.error('Missing style: ', styleName);
    styleInfo = {};
  }
  return createStyledComponent(props, theme, styleInfo);
};
