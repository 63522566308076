/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import * as React from 'react';

import coreObj, {
  CoreType,
  ThemePropsType,
  SubComponentPropsInterface,
} from 'supportchef-sdk-core';
import * as textStyle from './TextStyles';

export interface BaseTextProps extends SubComponentPropsInterface {
  theme?: any;
  onChange: any;
  isLoading?: boolean;
  className?: string;
  themedProps: ThemePropsType;
  coreObj: CoreType;
}

export interface BaseTextState {}

const defaultValues = {
  text: 'Placeholder Text',
};

export class BaseTextComponent extends React.Component<
  BaseTextProps,
  BaseTextState
> {
  static componentName = 'BaseTextComponent';

  focus() {
    // Does nothing - signature only here for use in editor components
  }

  render() {
    const {
      element,
      error,
      isLoading,
      themedProps,
      coreObj,
      className,
    } = this.props;
    const { StyledComponent: StyledText, classNames } = themedProps;

    const customTextProps = { ...defaultValues, ...(element || {}) };

    const safeCustomText =
      customTextProps.text === null ? '' : customTextProps.text;
    const textToDisplay = error ? JSON.stringify(error) : safeCustomText;

    let classNameFinal = classNames.join(' ');
    if (className) {
      classNameFinal += className + ' ';
    }
    classNameFinal += coreObj.boolPropsToClassName(this.props, [
      'error',
      'disabled',
    ]);

    // This is "safe" to do because we assume that the people creating the content
    // are not malicious (since it's from the company itself - if they wanted to make
    // malicious injections, they have control over the repo already)
    // In the future we could do server side validation, or use dompurify
    // TODO, yank textToDisplay into a cached handler to not recreate obj everytime
    return (
      <StyledText
        dangerouslySetInnerHTML={{ __html: textToDisplay }}
        className={classNameFinal}
      />
    );
  }
}

const stylePropModifier = (props) => {
  const htmlType = 'div';
  return { ...props, htmlType };
};

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(
    textStyle,
    BaseTextComponent,
    stylePropModifier
  );
