import projectVerChanged from 'redux/sideEffects/projectVer';
import log from 'lib/logging';

// Based off of npm package redux-subscribe
// Many path solutions don't work with Map's so we have to roll our own

// const subscribers = {};
const projectListners = {};
const defaultProjectListener = { node: {}, link: {} };
const nodeAddKey = 'nodeAdd';
const linkAddKey = 'linkAdd';
const linkRemoveKey = 'linkRemove';

export function subscribeMeta(subscribers, key, cb) {
  if (subscribers.hasOwnProperty(key)) {
    subscribers[key].push(cb);
  } else {
    subscribers[key] = [cb];
  }

  // return "unsubscribe" function
  return function () {
    subscribers[key] = subscribers[key].filter((s) => s !== cb);
  };
}

function getProjectVersionId(projectId, version) {
  return `${projectId}$${version}`;
}

function splitProjectVersionId(projectVersionId) {
  return projectVersionId.split('$', 2);
}

function getProjectListener(projectId, version) {
  const projectVersionId = getProjectVersionId(projectId, version);
  if (!projectListners[projectVersionId]) {
    projectListners[projectVersionId] = { ...defaultProjectListener };
  }
  return projectListners[projectVersionId];
}

export function subscribeToProjectNode(projectId, version, nodeId, cb) {
  const projectListener = getProjectListener(projectId, version);
  return subscribeMeta(projectListener.node, nodeId, cb);
}

export function subscribeToProjectLink(projectId, version, linkId, cb) {
  const projectListener = getProjectListener(projectId, version);
  return subscribeMeta(projectListener.link, linkId, cb);
}

export function subscribeToProjectNodeAddition(projectId, version, cb) {
  const projectListener = getProjectListener(projectId, version);
  return subscribeMeta(projectListener, nodeAddKey, cb);
}

export function subscribeToProjectLinkAddition(projectId, version, cb) {
  const projectListener = getProjectListener(projectId, version);
  return subscribeMeta(projectListener, linkAddKey, cb);
}

export function subscribeToProjectLinkRemoval(projectId, version, cb) {
  const projectListener = getProjectListener(projectId, version);
  return subscribeMeta(projectListener, linkRemoveKey, cb);
}

function getNodeFromProject(project, nodeId) {
  return project && project.diagram.nodes[nodeId];
}

function getLinkFromProject(project, linkId) {
  return project && project.diagram.links[linkId];
}

export default function (store) {
  let prevState = store.getState();

  store.subscribe(() => {
    const newState = store.getState();

    // Object.keys(subscribers).forEach(key => {
    //   if (get(prevState, key) !== get(newState, key)) {
    //     subscribers[key].forEach(cb => cb(newState));
    //   }
    // });

    // WARNING: This needs to actually be implemented :|
    // Notify side effect listener that the projectVer has chagned
    projectVerChanged({}, {});

    Object.keys(projectListners).forEach((projectVersionId) => {
      try {
        const [projectId, version] = splitProjectVersionId(projectVersionId);

        const projContainObj = newState.projects.allProjects.get(projectId);
        const prevProjContainObj = prevState.projects.allProjects.get(
          projectId
        );

        let projectObj, prevProjectObj;
        if (projContainObj) {
          projectObj = projContainObj.present.versions[version];
          // log.info('projContainObj', projectObj);
        }
        if (prevProjContainObj) {
          prevProjectObj = prevProjContainObj.present.versions[version];
          // log.info('prevContainObj', prevProjectObj);
        }

        if (projectObj !== prevProjectObj && (projectObj || prevProjectObj)) {
          // First check if there are any nodes that were changed
          if (projectObj.diagram.nodes !== prevProjectObj.diagram.nodes) {
            Object.keys(projectListners[projectVersionId].node).forEach(
              (nodeId) => {
                const nodeObj = getNodeFromProject(projectObj, nodeId);
                const prevNodeObj = getNodeFromProject(prevProjectObj, nodeId);

                if (nodeObj !== prevNodeObj) {
                  projectListners[projectVersionId].node[nodeId].forEach((cb) =>
                    cb(nodeObj, newState)
                  );
                }
              }
            );

            // Next check if any new node has been added
            const nodeKeys = Object.keys(projectObj.diagram.nodes);
            nodeKeys.forEach((nodeKey) => {
              if (!getNodeFromProject(prevProjectObj, nodeKey)) {
                // This node key is new!
                const nodeObj = getNodeFromProject(projectObj, nodeKey);
                log.info('New node added!', nodeKey, nodeObj);
                projectListners[projectVersionId][nodeAddKey].forEach((cb) =>
                  cb([nodeKey, nodeObj], newState)
                );
              }
            });
          }

          if (projectObj.diagram.links !== prevProjectObj.diagram.links) {
            // Next check if there are any links that were changed
            Object.keys(projectListners[projectVersionId].link).forEach(
              (linkId) => {
                const linkObj = getLinkFromProject(projectObj, linkId);
                const prevLinkObj = getLinkFromProject(prevProjectObj, linkId);

                if (linkObj !== prevLinkObj) {
                  projectListners[projectVersionId].link[linkId].forEach((cb) =>
                    cb(linkObj, newState)
                  );
                }
              }
            );

            // Check if any new link has been added
            const linkKeys = Object.keys(projectObj.diagram.links);
            linkKeys.forEach((linkKey) => {
              if (!getLinkFromProject(prevProjectObj, linkKey)) {
                // This link key is new!
                const linkObj = getLinkFromProject(projectObj, linkKey);
                projectListners[projectVersionId][linkAddKey].forEach((cb) =>
                  cb([linkKey, linkObj], newState)
                );
              }
            });

            // Check if any link has been removed
            const oldLinkKeys = Object.keys(prevProjectObj.diagram.links);
            oldLinkKeys.forEach((linkKey) => {
              if (!getLinkFromProject(projectObj, linkKey)) {
                // This link key was removed!
                const linkObj = getLinkFromProject(prevProjectObj, linkKey);
                projectListners[projectVersionId][linkRemoveKey].forEach((cb) =>
                  cb([linkKey, linkObj], newState)
                );
              }
            });
          }
        }
      } catch (e) {
        log.error('Error in subscriber', e);
      }
    });

    prevState = newState;
  });
}
