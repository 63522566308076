import React from 'react';
import memoizeOne from 'memoize-one';
import actions from '/GraphNodes/Actions';

// Very important that options are defined in optionDefaults below
// (Similar for vars, or any top level dictionary)
const defaultProps = {
  loading: (
    <div>
      Loading Content
      <br />
    </div>
  ),
};

const optionDefaults = { actions };

// We make this a class that needs to be instantiated
// so that each merge "memoizeOne" is unique to the component
class ProjectPropsMerger {
  private mergeVars = memoizeOne((varProps, varContext) => {
    return { ...varContext, ...varProps };
  });

  private mergeOptions = memoizeOne((optProps, optContext) => {
    const mergedOptions = { ...optContext, ...optProps };
    if (optProps.actions && optContext.actions) {
      mergedOptions.actions = { ...optContext.actions, ...optProps.actions };
    }
    return mergedOptions;
  });

  private optionDefaults = memoizeOne((options) => {
    const mergedOptions = { ...optionDefaults, ...options };
    if (options?.actions) {
      mergedOptions.actions = { ...actions, ...options.actions };
    }
    return mergedOptions;
  });

  // In place modifies and returns - not great, but doesn't matter
  // since we keep it private (and slightly more eff)
  private transformProps = (allProps) => {
    if (allProps.path) {
      allProps.path = allProps.path;
    }
    if (allProps.entry) {
      allProps.options.entryNode = allProps.entry;
    }
    return allProps;
  };

  public merge(props, context) {
    let mergedProps = { ...defaultProps, ...props };

    // We don't cache this because by definition a prop
    // or context had to have changed
    if (context) {
      mergedProps = { ...context, ...mergedProps };

      if (context.vars && props.vars) {
        mergedProps.vars = this.mergeVars(props.vars, context.vars);
      }

      if (context.options && props.options) {
        mergedProps.options = this.mergeOptions(props.options, context.options);
      }
    }

    mergedProps.options = this.optionDefaults(mergedProps.options);

    mergedProps = this.transformProps(mergedProps);
    // console.log('merging context props', mergedProps, context, props);
    return mergedProps;
  }
}

export default ProjectPropsMerger;
