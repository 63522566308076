const defaultAction = (action) => {
  return new Promise((resolve, error) => setTimeout(resolve, 1000));
};

export default {
  link: (action) => {
    console.log(action.link);
    window.location.href = action.link;
  },
  custom: defaultAction,
  default: defaultAction,
};
