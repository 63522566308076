export function generateOutputVariableKey(nodeId, entryId) {
  return `out&${nodeId}&${entryId}`;
}

export function getVarStore(graphTraverser) {
  return graphTraverser?.props?.traverserState?.varStore;
}

export const componentUtils = {
  getVariableKey: (props) => {
    return generateOutputVariableKey(props.nodeId, props.elementKey);
  },
  getVariableAccessor: (props, multi = false) => {
    return (
      props.graphTraverser &&
      props.graphTraverser.props.traverserState.varStore.getAccessor(
        props.varname,
        multi
      )
    );
  },
};
