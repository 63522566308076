const initializeGlobals = () => {
  if (!window.SCExported) {
    window.SCExported = {};
  }
  if (!window.SCCallback) {
    window.SCCallback = {};
  }
  if (!window.SCModifier) {
    window.SCModifier = (src) => {
      return src.split('?')[0];
    };
  }
};

// Inspired by: https://stackoverflow.com/a/950146
// and: https://unixpapa.com/js/dyna.html
export const fetchContentUrl = async (url) => {
  // Because we are loading from inside a native SDK, we need to go back up
  // to the "root" of what we're allowed to read, which is up two directories
  // Warning! If we change the location of the SDK it must always be two deep, or we must redo this!
  const relToSdkUrl = `../../${url}`;
  return new Promise((accept, reject) => {
    initializeGlobals();

    const absUrl = new URL(relToSdkUrl, window.location.href).href;

    if (window.SCExported[absUrl]) {
      accept(window.SCExported[absUrl]);
    } else {
      const script = document.createElement('script');

      // We must add a random hash to the request because the mobile browser
      // can be extremely aggressive in it's caching - we control caching
      script.src = absUrl + `?hash=${Math.random()}`;

      script.onerror = () => reject(new Error('Script load error: ' + absUrl));

      // Add a callback for this URL
      window.SCCallback[absUrl] = () => {
        accept(window.SCExported[absUrl]);
      };

      // Add it to the end of the head section of the page
      // (could change 'head' to 'body' to add it to the end of the body section instead)
      document.head.appendChild(script);
    }
  });
};

export const clearInternalFetchingCache = () => {
  window.SCExported = {};
};
