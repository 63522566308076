/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router';
import { connect } from 'react-redux';

import PrivateRoute from './PrivateRoute';
import history from 'lib/history';
import { loggedInStatusChanged } from 'redux/actions/authActions';
import { fetchAccountsOnMe } from 'redux/actions/accountActions';
import track from 'lib/withTracker';
import aComp from './AsyncComponent';
// import AppliedRoute from "./AppliedRoute";
// import AuthRouter from 'components/Auth/AuthRouter';
//
// const Login = track(aComp(() => import('../Auth/Login')));
// const EmailValidation = track(aComp(() => import('../Auth/EmailValidation')));
// const TFAValidation = track(aComp(() => import('../Auth/TwoFactorValidation')));
// const Register = track(aComp(() => import('../Auth/Register')));
// const ForgotPassword = track(aComp(() => import('../Auth/ForgotPassword')));
// const PasswordReset = track(aComp(() => import('../Auth/ForgotPasswordReset')));
const PublicIntegrationInstructions = track(
  aComp(() =>
    import('components/Instructions/Integration/PublicIntegrationInstructions')
  )
);
const Landing = track(aComp(() => import('../LandingPage/Landing')));
const App = track(aComp(() => import('../App')));
const AuthRouter = track(aComp(() => import('components/Auth/AuthRouter')));

/**
 * Router component in charge of navigation when not signed in
 */
export class RootRouter extends Component {
  constructor(props) {
    super(props);
    this.loggedInGuess = sessionStorage.getItem('isLoggedIn') === 'true';
  }
  componentDidMount() {
    this.validateUserSession();
  }

  /**
   * Check browser sessionStorage to check logged in status
   */
  validateUserSession() {
    if (sessionStorage.getItem('isLoggedIn') === 'true') {
      this.props.loggedInStatusChanged(true);
    } else {
      this.props.loggedInStatusChanged(false);
    }
    this.loggedInGuess = false;
  }

  componentDidUpdate(prevProps) {
    const { loggedIn } = this.props;
    if (loggedIn === true && loggedIn !== prevProps.loggedIn) {
      this.props.fetchAccountsOnMe();
    }
  }

  render() {
    const { loggedIn } = this.props;
    const authPaths = [
      '/login',
      '/emailValidation',
      '/2FA',
      '/register',
      '/forgotPassword',
      '/forgotPasswordReset',
    ];
    const loggedInBestGuess = loggedIn ? loggedIn : this.loggedInGuess;
    return (
      <Router history={history}>
        <Switch>
          <Route
            path="/integrationInstructions/:projectPath"
            exact
            component={PublicIntegrationInstructions}
          />
          <Route path={authPaths} exact component={AuthRouter} />
          <Route path="/" exact component={Landing} />
          {/* This private route redirects everything to /login if not logged in */}
          <PrivateRoute
            authStatus={loggedInBestGuess}
            path="/"
            component={App}
          />
        </Switch>
      </Router>
    );
  }
}

RootRouter.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loggedInStatusChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ loggedIn: state.auth.loggedIn });

export default connect(mapStateToProps, {
  loggedInStatusChanged,
  fetchAccountsOnMe,
})(RootRouter);
