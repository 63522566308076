export function getThemeInfoFromProject(project) {
  if (!project) {
    return null;
  }
  return project.theme || {};
}

/**
 * Gets the project theme based on the current project.
 * If the previous project object is passed then the theme is only
 * set if the theme has changed between the two.
 * (This requires that the theme root object of the prevProject changed
 *  which must be the case if this is stored/modified in redux)
 *
 * @param {[type]} project     Project
 * @param {[type]} prevProject Optional previous project
 */
export function getProjectTheme(
  projectThemeInfo,
  prevProjectThemeInfo,
  oldTheme
) {
  // Specifically oldTheme doesn't equal null - meaning it was passed
  // in _and_ is null, then we need to provide _something_ back up
  if (projectThemeInfo === prevProjectThemeInfo && oldTheme !== null) {
    return null;
  }

  if (projectThemeInfo) {
    return getThemeFromThemeObj(projectThemeInfo);
  }
  // In this case the project is defined, but doesn't have an explicit theme
  // So we will just return a default
  return null;
}

function getThemeFromThemeObj(themeObj) {
  return themeObj;
}
