export const styleKey = (inTheme) => {
  const key = inTheme.key;
  return `var(${key})`;
};

const insertThemeKeys = (styleKeysBeforeKeys, prefix = '-') => {
  // let newTheme = { ...styleKeysBeforeKeys };
  let newTheme = styleKeysBeforeKeys;
  Object.entries(newTheme).forEach(([themeKey, value]) => {
    let themeVal = value as any;
    if (!themeVal.key && typeof themeVal === 'object') {
      // Are these copies needed?
      // newTheme[themeKey] = { ...themeVal };
      newTheme[themeKey].key = prefix + '-' + themeKey;
    }
    if (themeVal.insertKeys) {
      const newPrefix = themeVal.key;
      newTheme[themeKey] = insertThemeKeys(themeVal, newPrefix);
    }
  });
  return newTheme;
};

export const codeBasedVars = {
  safeInsetRight: {
    key: '--safe-area-inset-right',
    desc: 'The safe inset on right side',
    isTheme: false,
  },
  safeInsetLeft: {
    key: '--safe-area-inset-left',
    desc: 'The safe inset on left side',
    isTheme: false,
  },
  safeInsetTop: {
    key: '--safe-area-inset-top',
    desc: 'The safe inset on top side',
    isTheme: false,
  },
  safeInsetBottom: {
    key: '--safe-area-inset-bottom',
    desc: 'The safe inset on bottom side',
    isTheme: false,
  },
};

const styleKeysBeforeKeys = {
  colors: {
    // Tell the insertThemeKeys to insert keys for children
    insertKeys: true,
    isRoot: true,
    key: '--colors',
    defaultVar: '--colors-primary',
    primary: {
      isRoot: true,
      key: '--colors-primary',
      defaultVar: '--colors-primary-t400',
      t50: { key: '--colors-primary-t50' },
      t100: { key: '--colors-primary-t100' },
      t200: { key: '--colors-primary-t200' },
      t300: { key: '--colors-primary-t300' },
      t400: { key: '--colors-primary-t400' },
      t500: { key: '--colors-primary-t500' },
      t600: { key: '--colors-primary-t600' },
      t700: { key: '--colors-primary-t700' },
    },
    negative: {
      isRoot: true,
      key: '--colors-negative',
      defaultVar: '--colors-negative-t400',
      t50: { key: '--colors-negative-t50' },
      t100: { key: '--colors-negative-t100' },
      t200: { key: '--colors-negative-t200' },
      t300: { key: '--colors-negative-t300' },
      t400: { key: '--colors-negative-t400' },
      t500: { key: '--colors-negative-t500' },
      t600: { key: '--colors-negative-t600' },
      t700: { key: '--colors-negative-t700' },
    },
    warning: {
      isRoot: true,
      key: '--colors-warning',
      defaultVar: '--colors-warning-t400',
      t50: { key: '--colors-warning-t50' },
      t100: { key: '--colors-warning-t100' },
      t200: { key: '--colors-warning-t200' },
      t300: { key: '--colors-warning-t300' },
      t400: { key: '--colors-warning-t400' },
      t500: { key: '--colors-warning-t500' },
      t600: { key: '--colors-warning-t600' },
      t700: { key: '--colors-warning-t700' },
    },
    positive: {
      isRoot: true,
      key: '--colors-positive',
      defaultVar: '--colors-positive-t400',
      t50: { key: '--colors-positive-t50' },
      t100: { key: '--colors-positive-t100' },
      t200: { key: '--colors-positive-t200' },
      t300: { key: '--colors-positive-t300' },
      t400: { key: '--colors-positive-t400' },
      t500: { key: '--colors-positive-t500' },
      t600: { key: '--colors-positive-t600' },
      t700: { key: '--colors-positive-t700' },
    },
    white: {
      key: '--colors-white',
      defaultVar: '--colors-mono-t100',
    },
    black: {
      key: '--colors-white',
      defaultVar: '--colors-mono-t1000',
    },
    mono: {
      isRoot: true,
      defaultVar: '--colors-mono-t400',
      key: '--colors-mono',
      t100: { key: '--colors-mono-t100' },
      t200: { key: '--colors-mono-t200' },
      t300: { key: '--colors-mono-t300' },
      t400: { key: '--colors-mono-t400' },
      t500: { key: '--colors-mono-t500' },
      t600: { key: '--colors-mono-t600' },
      t700: { key: '--colors-mono-t700' },
      t800: { key: '--colors-mono-t800' },
      t900: { key: '--colors-mono-t900' },
      t1000: { key: '--colors-mono-t1000' },
    },
    rating: {
      isRoot: true,
      defaultVar: '--colors-rating-t400',
      key: '--colors-rating',
      t200: { key: '--colors-rating-t200' },
      t400: { key: '--colors-rating-t400' },
    },

    // Semantic colors

    // Font Color
    foreground: { defaultVar: '--colors-mono-t1000' },
    foregroundAlt: { defaultVar: '--colors-mono-t800' },

    // Background
    background: { defaultVar: '--colors-mono-t200' },
    backgroundMenu: { defaultVar: '--colors-mono-t300' },
    backgroundInv: { defaultVar: '--colors-mono-t1000' },

    // Borders
    border: { defaultVar: '--colors-mono-t500' },
    borderAlt: { defaultVar: '--colors-mono-t600' },
    borderFocus: { defaultVar: '--colors-primary-t400' },
    borderError: { defaultVar: '--colors-negative-t400' },

    // Menu Buttons
    buttonMenuFill: { defaultVar: '--colors-mono-t100' },
    buttonMenuText: { defaultVar: '--colors-foreground' },
    buttonMenuHover: { defaultVar: '--colors-mono-t200' },
    buttonMenuActive: { defaultVar: '--colors-mono-t300' },

    // Buttons
    buttonPrimaryFill: { defaultVar: '--colors-primary-t400' },
    buttonPrimaryText: { defaultVar: '--colors-mono-t100' },
    buttonPrimaryHover: { defaultVar: '--colors-primary-t500' },
    buttonPrimaryActive: { defaultVar: '--colors-primary-t600' },

    buttonDefaultFill: { defaultVar: '--colors-primary-t50' },
    buttonDefaultText: { defaultVar: '--colors-primary-t400' },
    buttonDefaultHover: { defaultVar: '--colors-primary-t100' },
    buttonDefaultActive: { defaultVar: '--colors-primary-t200' },

    buttonTertiaryFill: { defaultVar: '--colors-mono-t200' },
    buttonTertiaryText: { defaultVar: '--colors-primary-t400' },
    buttonTertiaryHover: { defaultVar: '--colors-mono-t400' },
    buttonTertiaryActive: { defaultVar: '--colors-mono-t500' },
    buttonTertiarySelectedFill: { defaultVar: '--colors-primary-t400' },
    buttonTertiarySelectedText: { defaultVar: '--colors-mono-t100' },

    buttonMinimalFill: { default: 'transparent' },
    buttonMinimalText: { defaultVar: '--colors-primary-t400' },
    buttonMinimalHover: { defaultVar: '--colors-mono-t200' },
    buttonMinimalActive: { defaultVar: '--colors-mono-t400' },

    buttonDisabledFill: { defaultVar: '--colors-mono-t300' },
    buttonDisabledText: { defaultVar: '--colors-mono-t600' },

    // Shadow
    shadowFocus: { default: 'rgba(39, 110, 241, 0.32)' },
    shadowError: { default: 'rgba(229, 73, 55, 0.32)' },

    // Inputs
    inputFill: { defaultVar: '--colors-mono-t100' },
    inputFillFocused: { defaultVar: '--colors-inputFill' },
    inputFillError: { defaultVar: '--colors-negative-t50' },
    inputFillDisabled: { defaultVar: '--colors-mono-t300' },
    inputTextDisabled: { defaultVar: '--colors-mono-t600' },
    inputBorder: { defaultVar: '--colors-mono-t300' },
    inputPlaceholder: { defaultVar: '--colors-mono-t500' },
  },

  sizing: {
    insertKeys: true,
    isRoot: true,
    key: '--sizing',
    defaultVar: '--sizing-t200',
    t0: { default: '2px' },
    t100: { default: '4px' },
    t200: { default: '6px' },
    t300: { default: '8px' },
    t400: { default: '10px' },
    t500: { default: '12px' },
    t550: { default: '14px' },
    t600: { default: '16px' },
    t700: { default: '20px' },
    t800: { default: '24px' },
    t900: { default: '32px' },
    t1000: { default: '40px' },
    t1200: { default: '48px' },
    t1400: { default: '56px' },
    t1600: { default: '64px' },
    t2400: { default: '96px' },
    t3200: { default: '128px' },
    t4800: { default: '192px' },
  },

  animation: {
    insertKeys: true,
    isRoot: true,
    key: '--animation',
    defaultVar: '--animation-easeInOutCurve',
    timing100: { default: '0.25s' },
    timing400: { default: '0.4s' },
    timing700: { default: '0.6s' },
    easeOutCurve: { default: 'cubic-bezier(.2, .8, .4, 1)' },
    easeInCurve: { default: 'cubic-bezier(.8, .2, .6, 1)' },
    easeInOutCurve: { default: 'cubic-bezier(0.4, 0, 0.2, 1)' },
  },

  fontFamily: { key: '--font-family' },

  font: {
    //font sizing
    insertKeys: true,
    isRoot: true,
    key: '--font',
    defaultVar: '--font-t400',
    t100: { default: `normal 11px/16px var(--font-family)` },
    t200: { default: `normal 12px/20px var(--font-family)` },
    t250: { default: `bold 12px/20px var(--font-family)` },
    t300: { default: `normal 14px/20px var(--font-family)` },
    t350: { default: `bold 14px/20px var(--font-family)` },
    t400: { default: `normal 16px/24px var(--font-family)` },
    t450: { default: `bold 16px/24px var(--font-family)` },
    t500: { default: `bold 20px/28px var(--font-family)` },
    t600: { default: `bold 24px/36px var(--font-family)` },
    t700: { default: `bold 32px/48px var(--font-family)` },
    t800: { default: `bold 40px/56px var(--font-family)` },
    t900: { default: `bold 52px/68px var(--font-family)` },
    t1000: { default: `normal 72px/96px var(--font-family)` },
    t1100: { default: `normal 96px/116px var(--font-family)` },
  },
};

export const styleKeys = insertThemeKeys(styleKeysBeforeKeys);
