import { ButtonComponent } from './ButtonComponent';
import { BaseButtonComponent } from './BaseButtonComponent';
import * as ButtonStyles from './ButtonStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  coreObj.registerStyledComponent(ButtonStyles, BaseButtonComponent);
  coreObj.registerComponent(ButtonComponent);
  coreObj.registerStyles(ButtonStyles);
});

export * from './ButtonComponent';
export * from './BaseButtonComponent';
export { ButtonStyles };
