import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'Image';

export const metadata = {
  htmlType: 'div',
  elements: {
    svg: {
      desc: 'The svg image',
    },
  },
  basicControl: {},
};

export const styleModes = {
  fillImage: {
    key: 'fillImage',
    prio: defaultStylePrio,
  },
  successImage: {
    key: 'successImage',
    prio: defaultStylePrio,
  },
  warnImage: {
    key: 'warnImage',
    prio: defaultStylePrio,
  },
};

export const styles = {
  default: {},

  [styleCategoryKey(styleModes.fillImage)]: {
    color: '#e5ebef',
    margin: '0 10px',
    maxWidth: '200px',
    '& svg': {
      width: '100%',
    },
  },
  [styleCategoryKey(styleModes.successImage)]: {
    color: '#9def9d',
  },
  [styleCategoryKey(styleModes.warnImage)]: {
    color: '#ff9797',
  },
};
