import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'MultipleChoice';

export const metadata = {
  htmlType: 'label',
  elements: {
    input: { desc: 'Actual clickable button' },
    '.spinner': {
      desc: 'Button spinning icon',
    },
  },
  basicControl: {},
  // Styles that are ignored when in editor interface
  ignoreWhenEditing: [[':active'], ['@media (hover:hover)']],
};

export const styleModes = {
  compact: {
    key: 'compact',
    prio: defaultStylePrio,
  },
};

export const styles = {
  [styleCategoryKey(styleModes.compact)]: {
    ['& .text']: {
      font: styleKey(styleKeys.font.t100),
    },
  },
  default: {
    paddingBottom: styleKey(styleKeys.sizing.t0),
    backgroundColor: styleKey(styleKeys.colors.inputFill),
    borderColor: styleKey(styleKeys.colors.inputBorder),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '45px',
    cursor: 'pointer',
    marginBottom: '5px',
    borderWidth: '1px',
    borderStyle: 'solid',
    transition: 'all .3s cubic-bezier(.645, .045, .355, 1)',

    boxShadow: '0 2px 6px transparent',
    borderRadius: styleKey(styleKeys.sizing.t100),

    '& input': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      opacity: 0,

      '::placeholder': {
        color: styleKey(styleKeys.colors.foregroundAlt),
      },
      // "&[type='radio'], &[type='checkbox']": {
      //   margin: '0px 8px',
      // },
      // "&[type='radio']": {
      //   marginBottom: '2px',
      // },
    },
    '& .multChoiceBox': {
      boxSizing: 'border-box',
      marginLeft: '12px',
      marginRight: '8px',
      padding: 0,
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: '14px',
      fontVariant: 'tabular-nums',
      lineHeight: 1.5,
      listStyle: 'none',
      fontFeatureSettings: 'tnum',
      position: 'relative',
      // top: '-0.07em',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      outline: 'none',
      cursor: 'pointer',
    },
    '& .multChoiceBox.checked.radio .multChoiceBoxInner': {
      '&::after': {
        borderColor: styleKey(styleKeys.colors.primary.t400), //'#1890ff',
        // Comment out below if you want to move to checkbox radio ;)
        // transform: 'scale(1)',
      },
    },
    '& .multChoiceBox.radio .multChoiceBoxInner': {
      borderRadius: '50%',
      '&::after': {
        // Comment out below if you want to move to checkbox radio ;)
        // top: '3px',
        // left: '3px',
        // width: '8px',
        // height: '8px',
        // borderTop: '0',
        // borderLeft: '0',
        // borderRadius: '8px',
        // transform: 'scale(0)',
        // backgroundColor: '#1890ff',
      },
    },
    '& .multChoiceBox.checked.checkbox .multChoiceBoxInner': {
      backgroundColor: styleKey(styleKeys.colors.primary.t400), //'#1890ff',
    },
    '& .multChoiceBox.checked .multChoiceBoxInner': {
      borderColor: styleKey(styleKeys.colors.primary.t400), //'#1890ff',
      '&::after': {
        position: 'absolute',
        display: 'table',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        transform: 'rotate(45deg) scale(1) translate(-50%, -50%)',
        opacity: 1,
        transition: 'all .2s cubic-bezier(.12, .4, .29, 1.46) .1s',
        content: "' '",
      },
    },
    '& .multChoiceBoxInner': {
      position: 'relative',
      top: 0,
      left: 0,
      display: 'block',
      width: '16px',
      height: '16px',
      backgroundColor: '#fff',
      border: '1px solid #d9d9d9',
      borderRadius: '2px',
      borderCollapse: 'separate',
      transition: 'all .3s',
      '&::after': {
        position: 'absolute',
        display: 'table',
        boxSizing: 'border-box',
        // top: '45%', // previously 50
        top: '50%', // previously 50
        left: '21%',
        width: '5.71428571px',
        height: '9.14285714px',
        border: '2px solid #fff',
        borderTop: 0,
        borderLeft: 0,
        opacity: 0,
        transform: 'rotate(45deg) scale(0) translate(-50%, -50%)',
        transition: 'all .1s cubic-bezier(.71, -0.46, .88, .6),opacity .1s',
        content: "' '",
      },
    },
    // '&::after': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   border: '1px solid #1890ff',
    //   borderRadius: '2px',
    //   visibility: 'hidden',
    //   animation: 'antCheckboxEffect .36s ease-in-out',
    //   animationFillMode: 'backwards',
    //   content: "''",
    // },
    [customStyleTypes.fixedActive.key]: {
      // color: '#fff',
      backgroundColor: '#D8D8D8',
      borderColor: '#D8D8D8',
      textDecoration: 'none',
      // Remove transitions when pressing "down" - makes mobile feel a lot better
      transition: 'none',
      // background: '#fff',
    },
    ['&.error']: {
      backgroundColor: styleKey(styleKeys.colors.inputFillError),
      borderColor: styleKey(styleKeys.colors.negative.t400),
      boxShadow: `0 2px 6px ${styleKey(styleKeys.colors.shadowError)}`,
    },
    ['&.focused']: {
      backgroundColor: styleKey(styleKeys.colors.background),
      borderColor: styleKey(styleKeys.colors.primary.t400),
      boxShadow: `0 2px 6px ${styleKey(styleKeys.colors.shadowFocus)}`,
    },
    ['&.disabled']: {
      color: styleKey(styleKeys.colors.inputTextDisabled),
      backgroundColor: styleKey(styleKeys.colors.inputFillDisabled),
      borderColor: styleKey(styleKeys.colors.inputFillDisabled),
      boxShadow: '0 2px 6px transparent',
      '& input': {
        '::placeholder': {
          color: styleKey(styleKeys.colors.inputTextDisabled),
        },
      },
    },
    '& .text': {
      font: styleKey(styleKeys.font.t200),
      color: styleKey(styleKeys.colors.foreground),
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      marginTop: styleKey(styleKeys.sizing.t300),
      marginRight: '0',
      marginBottom: styleKey(styleKeys.sizing.t300),
      marginLeft: '0',
      width: '100%',
    },
  },
};

// export const SIZE = {
//   default: 'default',
//   compact: 'compact',
// };

// function getInputPadding(size, sizing) {
//   return {
//     [SIZE.default]: {
//       paddingTop: sizing['400'],
//       paddingRight: sizing['500'],
//       paddingBottom: sizing['400'],
//       paddingLeft: sizing['500'],
//     },
//     [SIZE.compact]: {
//       paddingTop: sizing['200'],
//       paddingRight: sizing['500'],
//       paddingBottom: sizing['200'],
//       paddingLeft: sizing['500'],
//     },
//   }[size];
// }

// function getFont(size, typography) {
//   return {
//     [SIZE.default]: typography.font300,
//     [SIZE.compact]: typography.font200,
//   }[size];
// }

// export const defaultMultChoice = props => {
//   const {
//     isFocused,
//     adjoined,
//     error,
//     disabled,
//     size,
//     styleKeys: { colors, sizing, typography, animation, borders },
//   } = props;
//   return {
//     ...getFont(size, typography),
//     //borders.useRoundedCorners ? sizing['100'] : '0px',

//     // transitionProperty: 'border, boxShadow, backgroundColor',
//     // transitionDuration: animation.timing100,
//     // transitionTimingFunction: animation.easeOutCurve,
//   };
// };

// export const keys = {
//   default: 'default',
// };

// export default {
//   [keys.default]: defaultMultChoice,
// };
