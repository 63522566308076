export const styleCategory = 'RootContainer';

export const styleModes = {};

export const metadata = {
  htmlType: 'div',
};

export const styles = {
  default: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    overflow: 'visible',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  },
};
