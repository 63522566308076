/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import {
  CognitoUserPool,
  CognitoUser,
  // CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials } from 'aws-sdk/global';
import log from 'lib/logging';
import OfflineCognitoUserPool from './offline/cognito-offline';
import store from 'redux/store/configureStore';
import { AUTHED_EMAIL_CHANGED } from 'redux/actions/types';
import AwsConfig from 'lib/aws-config';

import Config from '../config';

const UserPoolClass = Config.isOffline
  ? OfflineCognitoUserPool
  : CognitoUserPool;

const userPool = new UserPoolClass({
  UserPoolId: Config.awsCognitoUserPoolId,
  ClientId: Config.awsCognitoUserPoolAppClientId,
});

export const getCurrentUser = () => userPool.getCurrentUser();

export const getUserFromUsername = (username) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  return cognitoUser;
};

/**
 * Fetch JWT token from current session
 *
 * @param {CognitoUser} currentUser - Cognito User from storage
 * @returns {Promise<string>} - Promise resolves with the JWT session ID token
 */
const getUserTokenAndEmail = (currentUser) =>
  new Promise((resolve, reject) => {
    currentUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      log.info(session);
      const userToken = session.getIdToken();
      const userJWTToken = userToken.getJwtToken();
      const email = userToken.payload.email;
      resolve([userJWTToken, email]);
    });
  });

export const getUserToken = async (currentUser) => {
  const [userToken] = await getUserTokenAndEmail(currentUser);
  return userToken;
};

/**
 * Fetch AWS credentials using AWS SDK
 *
 * @param {string} token - Cognito User Pool token or Third Party acceess token
 * @param {string} provider - Name of the authenticated provider
 * @returns {Promise<object>} - Object containing properties: accessKeyId, secretAccessKey,
 * sessionToken
 */
export const getAwsCredentials = (token, provider) =>
  new Promise((resolve, reject) => {
    let providerKey = '';

    switch (provider) {
      case 'user_pool':
        providerKey = `cognito-idp.${Config.awsRegion}.amazonaws.com/${Config.awsCognitoUserPoolId}`;
        break;
      case 'facebook':
        providerKey = 'graph.facebook.com';
        break;
      case 'google':
        providerKey = 'accounts.google.com';
        break;
      case 'amazon':
        providerKey = 'www.amazon.com';
        break;
      default:
        break;
    }

    AwsConfig.region = Config.awsRegion;
    AwsConfig.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: Config.awsCognitoIdentityPoolId,
      Logins: {
        [providerKey]: token,
      },
    });

    // console.log('AwsConfig', AwsConfig);

    AwsConfig.credentials.get((error) => {
      if (error) {
        reject(error);
      }

      const {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      } = AwsConfig.credentials;
      const credentialSubset = { accessKeyId, secretAccessKey, sessionToken };
      log.debug('credentialSubset', credentialSubset);
      if (accessKeyId === undefined) {
        AwsConfig.credentials.refresh(function () {
          log.debug(
            'USER-SERVICE:LOGIN:AwsConfig.credentials.accessKeyId -> ' +
              AwsConfig.credentials.accessKeyId
          );
          resolve(credentialSubset);
        });
      } else {
        resolve(credentialSubset);
      }
    });
  });

/**
 * Fetches user details from Cognito User Pool
 *
 * @param {string} username - Username of user to query
 * @returns {Promise<object>} - Promise object represents mapping of attribute name to attribute
 * value
 */
const buildCurrentUserObject = () =>
  new Promise((resolve, reject) => {
    const cognitoUser = getCurrentUser();

    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) {
        reject(sessionErr);
      }

      cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        }

        const user = {};
        for (let i = 0; i < result.length; i += 1) {
          user[result[i].getName()] = result[i].getValue();
        }
        // user.username = username;
        resolve(user);
      });
    });
  });

/**
 * Authenticate user using username and password
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<CognitoUserSession>} - User session of authenticated user
 */
const authenticateUser = (username, password) =>
  new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (error) => {
        // The user object here is required for the login codes
        if (cognitoUser && error) {
          error.user = cognitoUser;
        }
        reject(error);
      },
      totpRequired: (secretCode) => {
        // The user object here is required for the login codes
        if (cognitoUser) {
          secretCode.user = cognitoUser;
        }
        log.debug(this);
        reject(secretCode);
        // cognitoUser.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
      },
    });
  });

/**
 * Helper to check if a user is authenticated
 *
 * @returns {bool} - Whether the cached credentials are valid or not
 */
export const authUser = async () => {
  if (
    AwsConfig.credentials &&
    Date.now() < AwsConfig.credentials.expireTime - 60000
  ) {
    return true;
  }

  if (Config.isOffline) {
    return true;
  }

  const provider = sessionStorage.getItem('provider');
  let token = sessionStorage.getItem('providerToken');
  let email;
  switch (provider) {
    case 'facebook':
      break;
    case 'google':
      break;
    case 'user_pool': {
      const currentUser = getCurrentUser();
      if (!currentUser) {
        log.error(
          "User not actually logged in - check that the server hasn't changed due to an sls deploy"
        );
        return false;
      }
      [token, email] = await getUserTokenAndEmail(currentUser);
      break;
    }
    default:
      return false;
  }

  await getAwsCredentials(token, provider);

  // This was added by
  if (email) {
    store.dispatch({ type: AUTHED_EMAIL_CHANGED, email });
  }

  return true;
};

/**
 * Retrieve last Cognito Identity ID that was cached by the AWS SDK
 *
 * @return {string} - Cognito Identity Id
 */
export const getIdentityId = () => {
  const identityId = AwsConfig.credentials.identityId;
  // This below log statement causes an error.
  // log.debug('AWS config credentials', JSON.stringify(AwsConfig.credentials));
  log.debug('principal', identityId);
  return identityId;
};

/**
 * Clears the cached Cognito ID associated with the currently configured identity pool ID
 */
export const clearCachedId = () => {
  AwsConfig?.credentials && AwsConfig.credentials.clearCachedId();
};

/**
 * Login to Amazon Cognito using username and password
 * 1. Authenticates with username and password
 * 2. Fetches AWS credentials
 * 3. Fetches user attributes
 *
 * @param {string} username - username of user
 * @param {string} password - password of user
 * @returns {Promise} Promise object represents user object from Cognito and AWS Credentials
 */
export const loginUser = (username, password) => {
  if (Config.isOffline) {
    return Promise.resolve({
      awsCredentials: {},
      userObj: '',
    });
  }

  return new Promise((resolve, reject) => {
    authenticateUser(username, password)
      .then((cognitoUserSession) => {
        const token = cognitoUserSession.getIdToken().getJwtToken();
        const promise1 = getAwsCredentials(token, 'user_pool');
        const promise2 = buildCurrentUserObject();
        return Promise.all([promise1, promise2]);
      })
      .then((values) => {
        const awsCredentials = values[0];
        const user = values[1];
        const userData = Object.assign({ awsCredentials }, { userObj: user });
        resolve(userData);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

/**
 * Log out of Amazon Cognito
 *
 * @returns {Promise}
 */
export const logoutUser = () =>
  new Promise((resolve) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      log.debug('cognito user pool user signing out');
      cognitoUser.signOut();
    } else {
      log.debug('cognito federated user signing out');
    }
    resolve();
  });

/**
 * Register a user with Amazon Cognito
 *
 * @param {string} username - username of the user
 * @param {string} password - password of the user
 * @param {string} email - email of the user
 * @returns {Promise<string>} Promise object represents the username of the registered user
 */
export const register = (username, password, email) =>
  new Promise((resolve, reject) => {
    const attributeList = [];
    // const attributeEmail = new CognitoUserAttribute({
    //   Name: 'email',
    //   Value: email,
    // });
    // attributeList.push(attributeEmail);
    userPool.signUp(username, password, attributeList, null, (error, data) => {
      if (error) {
        reject(error);
      } else {
        const { user } = data;
        resolve({ user, username: user.getUsername() });
      }
    });
  });

export const confirmRegistration = (username, confirmationCode) => {
  const user = getUserFromUsername(username);
  return new Promise((resolve, reject) =>
    user.confirmRegistration(confirmationCode, true, function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    })
  );
};

export const resendConfirmationCode = (username, confirmationCode) => {
  const user = getUserFromUsername(username);
  return new Promise((resolve, reject) =>
    user.resendConfirmationCode((err, result) => {
      log.error(err);
      log.error(result);
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    })
  );
};

export const enableMFA = (user) => {
  return new Promise((resolve, reject) =>
    user.enableMFA(function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      log.debug('enableMFA call result: ' + result);
      resolve();
    })
  );
};

export const disableMFA = (user) => {
  return new Promise((resolve, reject) =>
    user.disableMFA(function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      log.debug('disableMFA call result: ' + result);
      resolve();
    })
  );
};

export const getMFAOptions = (user) => {
  return new Promise((resolve, reject) =>
    user.getMFAOptions(function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      log.debug('getMFAOptions call result: ' + result);
      resolve(result);
    })
  );
};

export const changePassword = (oldPassword, newPassword) => {
  const cognitoUser = userPool.getCurrentUser();
  return getUserToken(cognitoUser).then(() => {
    return new Promise((resolve, reject) => {
      log.debug('cognitoUser', cognitoUser);
      cognitoUser.changePassword(oldPassword, newPassword, function (
        err,
        result
      ) {
        if (err) {
          log.debug('changePassword failed: ' + err);
          reject(err);
          return;
        }
        log.debug('changePassword call result: ' + result);
        resolve();
      });
    });
  });
};

export const forgotPassword = (username) => {
  const user = getUserFromUsername(username);
  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        log.info('CodeDeliveryData from forgotPassword: ' + data);
      },
      onFailure: function (err) {
        log.error(err.message || JSON.stringify(err));
        reject(err);
      },
      //Optional automatic callback
      inputVerificationCode: function (data) {
        resolve();
        return true;
      },
    });
  });
};

export const forgotPasswordResetPassword = (username, code, newPassword) => {
  const user = getUserFromUsername(username);
  return new Promise((resolve, reject) => {
    user.confirmPassword(code, newPassword, {
      onSuccess() {
        log.info('Password confirmed!');
        resolve();
      },
      onFailure(err) {
        log.error('Password not confirmed!');
        reject(err);
      },
    });
  });
};
