import registerRootContainer from './RootContainer';
import * as RootContainerStyles from './RootContainerStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  registerRootContainer(coreObj);
  coreObj.registerStyles(RootContainerStyles);
});

export * from './RootContainer';
export { RootContainerStyles };
