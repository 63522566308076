/** @jsx jsx */
import React from 'react';
import { jsx, Global } from '@emotion/core';
import { ThemeContext } from './ThemeContext';
import { createStyledComponentFromName } from '../ComponentStyling/customStylesConverter';
import _isEqual from 'lodash.isequal';
import memoize from 'memoize-one';

/**
 * This is the HOC that consumes themes from the theme provider
 */

interface ThemeWrapperProps {
  styleNames?: string[];
  forwardedRef: any;
}

interface ThemeWrapperState {
  themedProps: any;
}

// Interface that components have access to inside the "theme" prop
export interface ThemePropsType {
  StyledComponent: any;
  classNames: any;
  styles: any;
  globalStyles: any;
}

export interface ThemedComponentClass<T>  // extends React.Component<T> {
  extends React.ForwardRefExoticComponent<T> {
  componentName: string;
  variableDefs: any;
}
// ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<T>>

function themePropsNeedUpdating(oldArgs, newArgs) {
  const [, oldStyleNames] = oldArgs;
  const [, newStyleNames] = newArgs;
  if (oldStyleNames === newStyleNames) {
    return true;
  }
  return _isEqual(oldStyleNames, newStyleNames);
}

export function withThemedComponent<
  T extends React.Component,
  OriginalProps extends {}
>(
  styleCategory: string, // After refactor this should be string
  propModifier?: any,
  onThemeUpdate?: any
) {
  const coreObj = this;

  return (WrappedComponent) => {
    type PrivateProps = { forwardedRef?: React.RefObject<T> };
    type Props = OriginalProps & PrivateProps;
    type ExposedProps = Omit<Omit<OriginalProps, 'themedProps'>, 'coreObj'>;

    // type TWithStatic = {contextType: }

    class ThemeWrapper extends React.Component<
      ThemeWrapperProps,
      ThemeWrapperState
    > {
      static contextType = ThemeContext;
      static displayName = `WithThemedComponent(${getDisplayName(
        WrappedComponent
      )})`;

      getThemePropsUnmemoized(thisObj, styleNames) {
        // Modify incoming props if passed a function to do so
        const theseProps = propModifier
          ? propModifier(thisObj.props)
          : thisObj.props;
        const theme = thisObj.context;
        console.log('Theme from ThemeContext', theme, ThemeWrapper.displayName);

        // This contains {StyledElement, classNames, styles}
        let newThemeProps = createStyledComponentFromName(
          theseProps,
          theme || {},
          styleCategory
        );

        // If there are additional props that should be generated on
        // a theme update, hook in here.
        if (onThemeUpdate) {
          newThemeProps = {
            ...newThemeProps,
            ...onThemeUpdate(newThemeProps, coreObj),
          };
        }

        // console.log('newThemeProps', newThemeProps);
        return newThemeProps;
      }

      // This memoizes it for this particular instance
      // _not_ for the class itself (and not just b/c it's HOC)
      // This is exactly what we want see this article for how it's transpiled
      // https://medium.com/@charpeni/arrow-functions-in-class-properties-might-not-be-as-great-as-we-think-3b3551c440b1
      getThemedProps = memoize(
        this.getThemePropsUnmemoized,
        themePropsNeedUpdating
      );

      render() {
        const { forwardedRef, ...restTmp } = this.props as PrivateProps;

        const themedProps = this.getThemedProps(this, this.props.styleNames);
        const rest = restTmp as OriginalProps;

        return (
          <WrappedComponent
            ref={forwardedRef}
            {...rest}
            themedProps={themedProps}
            coreObj={coreObj}
          />
        );
      }
    }

    const RefForwardingFactory = (props: Props, ref: T) => (
      <ThemeWrapper {...props} forwardedRef={ref} />
    );

    // See forwarding refs: https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
    // For why TS wildness: https://medium.com/@martin_hotell/react-refs-with-typescript-a32d56c4d315
    const ForwardedComponent = (React.forwardRef<T, ExposedProps>(
      RefForwardingFactory as any
    ) as unknown) as ThemedComponentClass<
      ExposedProps & React.RefAttributes<T>
    >;

    ForwardedComponent.componentName = WrappedComponent.componentName;
    ForwardedComponent.variableDefs = WrappedComponent.variableDefs;

    return ForwardedComponent;
  };
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
