import { TraverserState } from '../GraphTraverser';
import { callbackToMobileSdk, sdkCallbackTopics } from '/lib/mobileCallback';
import { getInitialNodePageTitle } from '/lib/shared/graphUtils';

export const save = (state: TraverserState, pageIndex: number) => {
  if (state.expired || !state.initialized) {
    console.info(
      `Will abort save due to expired:${state.expired} or not yet init:${state.initialized}`
    );
    return;
  }
  const key = state.projectState.path + state.entryname || '';
  console.info('Saving state with persistence for key ' + key);
  const pageStack = state.pageStack.map((node) => node.nodeId);
  const vars = state.varStore.stringify();
  const time = Date.now();
  const version = state.projectState.project.projectVer.version;
  const vals = { version, vars, pageStack, time, pageIndex };
  window.localStorage.setItem(key, JSON.stringify(vals));
};

export const load = (state: TraverserState) => {
  const key = state.projectState.path + state.entryname || '';
  const stored = window.localStorage.getItem(key);
  if (stored == null) {
    console.info('No saved value found for key ' + key);
    return;
  }

  const vals = JSON.parse(stored);
  const { time, vars, pageStack, version: savedVersion, pageIndex } = vals;
  if (
    state.projectState.project.projectVer.version != savedVersion ||
    Date.now() - time > 7200000 /*120 minutes*/
  ) {
    console.info('Expired saved state found for key ' + key);
    window.localStorage.removeItem(key);
    return;
  }

  console.info('Found recoverable session: ' + stored);

  // Now restore session
  // 3 Steps:
  //  1. Update varStore
  //  2. Get node obj from graph using nodeId and push to traverser state pageStack
  //  3. Send "jumpToPage" with appropriate pageIndex to OS
  state.varStore.initFromJson(vars);

  const objGraph = state.projectState.project.projectVer.diagram.nodeObjs;
  let pageReindexed = pageIndex;
  for (let i = 1; i < pageStack.length; i++) {
    const page = objGraph[pageStack[i]];
    if (page) {
      state.pageStack.push(page);
    } else {
      pageReindexed -= 1;
    }
  }

  callbackToMobileSdk(
    sdkCallbackTopics.jumpToPage,
    {
      title: getInitialNodePageTitle(state.pageStack[pageReindexed], state),
      pageIndex: pageReindexed,
      newPage: true,
    },
    state.projectState.callbackToSdk
  );
};

export const remove = (path: string, entryNode: string) => {
  const key = path + (entryNode ? entryNode : '');
  console.info(`Removing ${key} from localStorage`);
  window.localStorage.removeItem(key);
};
