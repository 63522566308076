import * as React from 'react';
import { BaseGraphNode } from './BaseGraphNode';

export class EndpointGraphNode extends BaseGraphNode {
  isEndNode = () => {
    return true;
  };

  // getContent = (graphTraverser: GraphTraversal) => {
  //   graphTraverser.goToBeginning();
  //   return null;
  // };
}
