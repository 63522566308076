/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';

export interface DebugInterfaceProps {
  reason: string;
  versions: any;
  projectVer: any;
  setVersion: (string) => void;
}

export interface DebugInterfaceState {}

const defaultValues = {
  text: 'Placeholder Text',
};

export class DebugInterface extends React.Component<
  DebugInterfaceProps,
  DebugInterfaceState
> {
  static componentName = 'DebugInterface';

  constructor(props: DebugInterfaceProps) {
    super(props);
  }

  onVersionChange = (evt) => {
    const { setVersion, projectVer } = this.props;
    // @ts-ignore
    const selectedVersion = event.target?.value;

    if (selectedVersion !== projectVer.version) {
      setVersion(selectedVersion);
    }
  };

  refreshProject = () => {
    const { setVersion, projectVer } = this.props;
    setVersion(projectVer?.version);
  };

  render() {
    const { reason, versions, projectVer } = this.props;
    console.log('projectVer', projectVer);
    return (
      <div
        css={css`
          position: fixed;
          background: #eee;
          box-sizing: border-box;
          color: black;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 20px;
          overflow: wrap;
          z-index: 1000;
          @supports (padding-top: env(safe-area-inset-top)) {
            padding-top: var(--safe-area-inset-top);
          }
        `}
      >
        <br />
        <div
          css={css`
            text-align: center;
            margin-top: 8px;
            font-size: 11px;
            font-weight: bold;
          `}
        >
          Debug on because:
        </div>
        <div
          css={css`
            text-align: center;
            margin-top: 3px;
            border-radius: 4px;
            border: dashed #2ac1ea;
            border-width: 2px;
            padding: 8px;
          `}
        >
          {reason}
        </div>
        <br />
        <b>Version: </b>
        {versions && (
          <select
            css={css`
              width: 100%;
              height: 40px;
              font-size: 16px;
              padding-left: 8px;
              background: white;
              background-color: white;
              box-shadow: none;
              -webkit-appearance: none;
            `}
            onChange={this.onVersionChange}
          >
            {versions.map((version) => (
              <option
                value={version.version}
                key={version.version}
                selected={version.version === projectVer.version}
              >
                v{version.version} ({version.name}
                {version.isPublished ? '' : '- Unpublished'})
              </option>
            ))}
          </select>
        )}
        {!projectVer?.isPublished && (
          <button
            css={css`
              width: 100%;
              background-color: #1890ff;
              margin-top: 5px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              line-height: 30px;
              color: #fff;
              cursor: pointer;
              &:hover {
                background-color: #40a9ff;
              }
              &:active,
              &.active {
                background-color: #096dd9;
              }
            `}
            onClick={this.refreshProject}
          >
            Refresh unpublished project
          </button>
        )}
      </div>
    );
  }
}
