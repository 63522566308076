/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import {
  SubComponentPropsInterface,
  StyledComponentType,
} from 'supportchef-sdk-core';
import { BaseImageComponent, BaseImageProps } from './BaseImageComponent';

export interface ImageComponentProps extends SubComponentPropsInterface {}

export interface ImageComponentState {}

const defaultValues = {
  text: 'Placeholder Text',
};

export class ImageComponent extends React.Component<
  ImageComponentProps,
  ImageComponentState
> {
  static componentName = 'ImageComponent';

  Image: StyledComponentType<BaseImageComponent, BaseImageProps>;

  constructor(props: ImageComponentProps) {
    super(props);

    this.Image = props.coreObj.StyledComponentFactory<
      BaseImageComponent,
      BaseImageProps
    >(BaseImageComponent.componentName, props);
  }

  onChange = () => {};

  render() {
    return <this.Image onChange={this.onChange} {...this.props} />;
  }
}
