/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import chevronLeft from '/Symbols/chevronLeft';
import { CoreType } from 'supportchef-sdk-core';
import { styleCategory as headerStyle } from './HeaderStyles';

// export const IPhoneXHeaderSpace = styled.div`
//   min-height: 31px;
//   background-color: #fbfbfb;
// `;

// export const DeviceContent = styled.div`
//   position: relative;
//   flex: 1 1 auto;

//   background: white;
//   overflow-y: auto;
//   z-index: 3;
//   transform: translate(0px, 0px);

//   & > div {
//     // This breaks iPhone X landscape rendering
//     // overflow-y: auto;
//   }

//   &.isWeb {
//     overflow-y: hidden;
//   }
// `;

// export const DeviceFooter = styled.div``;

interface ModifiedThemeProps {
  StyledHeader: any;
  StyledSpacer: any;
}

interface HeaderProps {
  title: string;
  // isLandscape: boolean;
  pageIndex: number;
  hideFirstPageBack: boolean;
  goBack: any;
  isWeb: boolean;
  themedProps: ModifiedThemeProps;
}

class BaseHeader extends React.Component<HeaderProps> {
  static componentName = 'Header';
  render() {
    const {
      children,
      // showFooter,
      isWeb,
      goBack,
      // device,
      // isLandscape,
      // hiddenFields,
      // hideBack,
      title,
      themedProps,
      hideFirstPageBack,
      pageIndex,
    } = this.props;

    const { StyledHeader, StyledSpacer } = themedProps;

    const isIphoneXPortrait = false; //'iphone-x' === device && !isLandscape;

    const hideBack = hideFirstPageBack ? pageIndex === 0 : false;

    const headerContents = (
      <React.Fragment>
        {!hideBack && (
          <div className="backButton" onClick={goBack}>
            {chevronLeft}
            <span>Back</span>
          </div>
        )}
        <div className="header">{title}</div>
      </React.Fragment>
    );

    const isLandscape = false;
    const headerClassName = isLandscape ? 'isLandscape' : '';

    return (
      <React.Fragment>
        <StyledHeader className={headerClassName}>
          {headerContents}
        </StyledHeader>
        {StyledSpacer && (
          <StyledSpacer className={headerClassName}>
            {headerContents}
          </StyledSpacer>
        )}
      </React.Fragment>
    );
  }
}

const styleForSpacer = () => {
  return {
    position: 'unset',
    opacity: 0,
    backdropFilter: 'initial',
    // We remove top padding because it is added in PageContainer
    // It's added in page container, because it's needed whether or not header is included
    paddingTop: 'initial',
    zIndex: 0,
  };
};

const onThemeUpdate = (themedProps, coreObj) => {
  const { StyledComponent: StyledHeader, styles } = themedProps;

  // Create a version of the Header that is the same, except it is not
  // fixed, and is hidden
  let StyledSpacer = null;
  if (coreObj.stylesContains(styles, 'position', 'fixed')) {
    const styleWithoutFixed = styles.concat([styleForSpacer()]);
    StyledSpacer = styled('div')(styleWithoutFixed);
  }
  return { StyledHeader, StyledSpacer };
};

export const Header = (coreObj: CoreType) =>
  coreObj.withThemedComponent<BaseHeader, HeaderProps>(
    headerStyle,
    undefined,
    onThemeUpdate
  )(BaseHeader);

Header.componentName = 'Header';
