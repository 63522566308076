// https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';

GoogleAnalytics.initialize('UA-147647998-1');

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    let safePage = page;
    if (options.specifyPage) {
      safePage = options.specifyPage;
    }
    GoogleAnalytics.set({
      safePage,
      ...options,
    });
    GoogleAnalytics.pageview(safePage);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
