export const stylesContains = (stylesArray, prop, value) => {
  if (!stylesArray) {
    return false;
  }
  const elementWithProp = stylesArray.reverse().find((singleStyle) => {
    return singleStyle[prop] !== undefined;
  });
  if (!elementWithProp) {
    return false;
  }
  return elementWithProp[prop] === value;
};

export const boolPropsToClassName = (props, propNames) => {
  const classNames = propNames.filter((singleName) => !!props[singleName]);
  return classNames.join(' ');
};
