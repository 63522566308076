import {
  DIAGRAM_CONTAINER_RESIZE,
  DIAGRAM_CONTAINER_SIZE_UPDATE,
  ADD_NODE,
  REMOVE_NODE,
  SET_SOURCE_PORT_DRAG,
  TEST_DEVICE_VISIBLE_NODES,
  ADD_LINK,
  REMOVE_LINK,
  CREATE_VARIABLE,
  UPDATE_VARIABLE,
  REMOVE_VARIABLE,
} from '../actions/types';
import { generateVariable } from './variableHelpers';

import {
  isNodeActionType,
  nodeReducer,
  initialContents,
  serializeNode,
  deserializeNode,
  // initialState as projectNodeInitialState,
} from './projectNodeReducer';

export function isDiagramActionType(actionType) {
  return (
    // actionType === DIAGRAM_CONTAINER_RESIZE ||
    // actionType === DIAGRAM_CONTAINER_SIZE_UPDATE ||
    actionType === ADD_NODE ||
    actionType === REMOVE_NODE ||
    actionType === ADD_LINK ||
    actionType === REMOVE_LINK ||
    actionType === CREATE_VARIABLE ||
    actionType === UPDATE_VARIABLE ||
    actionType === REMOVE_VARIABLE ||
    isNodeActionType(actionType)
  );
}

export const initialDiagram = {
  nodes: {},
  links: {},
  variables: {},
};

export const initialView = {
  diagramWidth: 0,
  diagramHeight: 0,
  diagramOffsetX: 0,
  diagramOffsetY: 0,
  projectIdVer: null,
  zoom: 1,
  sourcePortId: null,
  sourceNodeId: null,
  testDeviceVisibleNodes: [],
};

export const initialNode = {
  x: 100,
  y: 100,
  contents: initialContents,
  type: 'none',
};

export const initialLink = {};

export function serializeSafeDiagramObject(diagramObject) {
  // if formObject.allFormEntries
  const newNodes = {};
  Object.keys(diagramObject.nodes).forEach((key) => {
    newNodes[key] = serializeNode(diagramObject.nodes[key]);
  });
  return { ...diagramObject, nodes: newNodes };
}
export function deserializeDiagramObject(diagramObject) {
  if (diagramObject && diagramObject.nodes) {
    const newNodes = {};
    Object.keys(diagramObject.nodes).forEach((key) => {
      newNodes[key] = deserializeNode(diagramObject.nodes[key]);
    });
    diagramObject.nodes = newNodes;
  }
  return diagramObject;
}

export const diagramViewReducer = (view = initialView, action) => {
  switch (action.type) {
    case DIAGRAM_CONTAINER_RESIZE:
      return {
        ...view,
        diagramWidth: action.diagramWidth,
        diagramHeight: action.diagramHeight,
      };
    case DIAGRAM_CONTAINER_SIZE_UPDATE:
      return {
        ...view,
        diagramOffsetX:
          action.diagramOffsetX !== undefined
            ? action.diagramOffsetX
            : view.diagramOffsetX,
        diagramOffsetY:
          action.diagramOffsetY !== undefined
            ? action.diagramOffsetY
            : view.diagramOffsetY,
        zoom: action.zoom !== undefined ? action.zoom : view.zoom,
        projectIdVer:
          action.projectIdVer !== undefined
            ? action.projectIdVer
            : view.projectIdVer,
      };
    case SET_SOURCE_PORT_DRAG:
      return {
        ...view,
        sourcePortId: action.sourcePortId,
        sourceNodeId: action.sourceNodeId,
      };
    case TEST_DEVICE_VISIBLE_NODES:
      return {
        ...view,
        testDeviceVisibleNodes: action.testDeviceVisibleNodes,
      };
    default:
      return view;
  }
};

export const diagramReducer = (diagram = initialDiagram, action) => {
  let newD = {};
  switch (action.type) {
    case ADD_NODE:
      const providedInitial = {};
      if (action.contents) {
        providedInitial.contents = new Map(action.contents);
      }
      return {
        ...diagram,
        nodes: {
          ...diagram.nodes,
          [action.nodeId]: {
            ...initialNode,
            ...providedInitial,
            x: action.x,
            y: action.y,
            nodeType: action.nodeType,
            name: action.name,
          },
        },
      };
    // newNodes = {...diagram.nodes, }
    // newD.nodes[action.nodeId] = {...initialNode, x: action.x, y: action.y, type: action.nodeType,};
    // return newD;
    case REMOVE_NODE:
      newD = { ...diagram, nodes: { ...diagram.nodes } };
      delete newD.nodes[action.nodeId];
      return newD;
    case ADD_LINK:
      return {
        ...diagram,
        links: {
          ...diagram.links,
          [action.linkId]: {
            ...initialLink,
            ...action.link,
          },
        },
      };
    // newNodes = {...diagram.nodes, }
    // newD.nodes[action.nodeId] = {...initialNode, x: action.x, y: action.y, type: action.nodeType,};
    // return newD;
    case REMOVE_LINK:
      const { [action.linkId]: toDelete, ...links } = diagram.links;
      newD = { ...diagram, links };
      // delete newD.links[action.linkId];
      return newD;
    case CREATE_VARIABLE:
      return {
        ...diagram,
        variables: {
          ...diagram.variables,
          [action.variableId]: generateVariable(action.variable, {}),
        },
      };
    case UPDATE_VARIABLE:
      const currentVar = diagram.variables[action.variableId];
      return {
        ...diagram,
        variables: {
          ...diagram.variables,
          [action.variableId]: generateVariable(
            action.variable,
            currentVar || {}
          ),
        },
      };
    case REMOVE_VARIABLE:
      const {
        [action.variableId]: varToDelete,
        ...variables
      } = diagram.variables;
      newD = { ...diagram, variables };
      // delete newD.variables[action.variableId];
      return newD;
    default:
      if (isNodeActionType(action.type)) {
        return {
          ...diagram,
          nodes: {
            ...diagram.nodes,
            [action.nodeId]: nodeReducer(diagram.nodes[action.nodeId], action),
          },
        };
      }
      return diagram;
  }
};
