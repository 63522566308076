import {
  FETCHING_ACCOUNTS_ON_ME,
  RECIEVE_ACCOUNTS_ON_ME,
  ACCOUNT_ERROR,
} from '../actions/types';

export const initialState = {
  allUserAccountsPermissions: new Map(),
  allAccountsDetails: new Map(),
  loadingAccountsOnMe: false,
  loadedAccountsOnMe: false,
  activeAccountId: null,
  error: '',
};

function accountDetailsToMapTuple(acct) {
  return [acct.account.accountId, acct.account];
}

function userAccountsPermissionsToMapTuple(acct) {
  return [acct.permissions.accountId, acct.permissions];
}

function accountsDetailsArrayToMap(accounts) {
  return accounts.filter((acct) => acct.account).map(accountDetailsToMapTuple);
}

function userAccountsPermissionsArrayToMap(accounts) {
  return accounts
    .filter((acct) => acct.permissions)
    .map(userAccountsPermissionsToMapTuple);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ACCOUNTS_ON_ME:
      return {
        ...state,
        loadingAccountsOnMe: true,
      };
    case RECIEVE_ACCOUNTS_ON_ME:
      return {
        ...state,
        allUserAccountsPermissions: new Map([
          ...state.allUserAccountsPermissions,
          ...userAccountsPermissionsArrayToMap(action.accounts),
        ]),
        allAccountsDetails: new Map([
          ...state.allAccountsDetails,
          ...accountsDetailsArrayToMap(action.accounts),
        ]),
        // If we don't have any active accountId, and only one value was returned
        // set the only option as the active account
        activeAccountId:
          state.activeAccountId === null && action.accounts.length === 1
            ? userAccountsPermissionsToMapTuple(action.accounts[0])[0]
            : state.activeAccountId,
        loadingAccountsOnMe: false,
        loadedAccountsOnMe: true,
      };
    case ACCOUNT_ERROR:
      return {
        ...state,
        error: action.error,
        loadingAccountsOnMe: false,
      };
    default:
      return state;
  }
};
