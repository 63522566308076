/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { combineReducers } from 'redux';
import { LOGOUT } from 'redux/actions/types';
import accessTokens from './accessTokensReducer';
import account from './accountReducer';
import auth from './authReducer';
import { diagramViewReducer } from './diagramReducer';
import users from './usersReducer';
import help from './helpReducer';
import iot from './iotReducer';
import nav from './navReducer';
import projects from './projectReducer';

const appReducer = combineReducers({
  accessTokens,
  account,
  auth,
  diagramView: diagramViewReducer,
  users,
  help,
  iot,
  nav,
  projects,
});

const rootReducer = (state, action) => {
  // In the case of logout - clear all data
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
