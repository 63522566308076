import * as React from 'react';
import { BaseGraphNode } from './BaseGraphNode';
import { GraphTraverser } from '/GraphTraverser';
import { componentUtils } from '/lib/componentUtils';

export class FormGraphNode extends BaseGraphNode {
  isContentNode = () => {
    return true;
  };

  getContent = (graphTraverser: GraphTraverser) => {
    const core = graphTraverser.core;
    const projectElements = this.node.contents;

    return (
      projectElements &&
      projectElements.map(([elementKey, element]) => {
        const Component = core.ComponentFactory(element);
        return (
          <Component
            key={elementKey + this.nodeId}
            element={element}
            elementKey={elementKey}
            isEditing={false}
            graphTraverser={graphTraverser}
            nodeId={this.nodeId}
            utils={componentUtils}
          />
        );
      })
    );
  };
}
