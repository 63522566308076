import GraphNodeTypes from './GraphNodeTypes';
import { BaseGraphNode } from './BaseGraphNode';
import { ConditionalGraphNode } from './ConditionalGraphNode';
import { FormGraphNode } from './FormGraphNode';
import { ActionGraphNode } from './ActionGraphNode';
import { EntryGraphNode } from './EntryGraphNode';
import { EndpointGraphNode } from './EndpointGraphNode';

const graphNodeTypeMap = {
  [GraphNodeTypes.entryNode]: EntryGraphNode,
  [GraphNodeTypes.subFormNode]: FormGraphNode,
  [GraphNodeTypes.conditionalNode]: ConditionalGraphNode,
  [GraphNodeTypes.actionNode]: ActionGraphNode,
  [GraphNodeTypes.endpointNode]: EndpointGraphNode,
};

export function graphNodeFactory(nodeId, graph) {
  const node = graph.nodes[nodeId];
  if (node) {
    const NodeClass = graphNodeTypeMap[node.nodeType] || BaseGraphNode;
    return new NodeClass(nodeId, graph);
  }
  return null;
}
