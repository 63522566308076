import {
  ADD_WALKTHROUGH_RUN,
  REMOVE_WALKTHROUGH_RUN,
  HELP_OVERLAY_STATUS,
} from '../actions/types';

export const initialState = {
  walkthroughRuns: {},
  helpActivated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_WALKTHROUGH_RUN:
      return {
        ...state,
        walkthroughRuns: {
          ...state.walkthroughRuns,
          [action.name]: action.runCallback,
        },
      };
    case REMOVE_WALKTHROUGH_RUN:
      const {
        [action.name]: deletedWalkthrough,
        ...walkthroughs
      } = state.walkthroughRuns;
      return {
        ...state,
        walkthroughRuns: walkthroughs,
      };
    case HELP_OVERLAY_STATUS:
      return {
        ...state,
        helpActivated: action.status,
      };
    default:
      return state;
  }
};
