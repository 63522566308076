import { Header } from './Header';
import * as HeaderStyles from './HeaderStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  coreObj.registerComponent(Header(coreObj));
  coreObj.registerStyles(HeaderStyles);
});

export * from './Header';
export { HeaderStyles };
