import { graphNodeFactory } from '/GraphNodes/GraphNodeFactory';
import { entryNode, conditionalNode } from '/GraphNodes/GraphNodeTypes';
import { EntryGraphNode } from '/GraphNodes/EntryGraphNode';

// This parses the graph and gets all of the entry points
// (from entry points you can find all other traversable nodes)
export const parseProjectGraph = (graph) => {
  const parsedGraph = { entryPoints: {}, defaultEntryPoint: null };

  Object.keys(graph.nodes).forEach((nodeId) => {
    const node = graphNodeFactory(nodeId, graph);
    if (node.nodeType === entryNode) {
      const entryNode = node as EntryGraphNode;
      parsedGraph.entryPoints[entryNode.getName()] = node;
      if (entryNode.getIsDefault() || !parsedGraph.defaultEntryPoint) {
        parsedGraph.defaultEntryPoint = node;
      }
    }
  });

  return parsedGraph;
};

const elementPageOuts = (element, allElementsArePageOut) => {
  if (!element) {
    return null;
  }
  if (!allElementsArePageOut && !element.nextPage) {
    return null;
  }
  // console.log('next page!!', element);
  return [
    { text: element.text, id: element.parentKey, nextPage: element.nextPage },
  ];
};

const getAllElements = (node) => {
  if (!node || !node.contents) {
    return [];
  }

  let contents;
  if (node.contents instanceof Map) {
    contents = Array.from(node.contents.entries());
  } else {
    console.log('contents!!', contents);
    contents = node.contents;
  }

  if (node.nodeType === conditionalNode) {
    return contents.map(([elementKey, element]) => ({
      ...element,
      parentKey: elementKey,
    }));
  }

  const elementsArray =
    contents.map(([elementKey, element]) => {
      if (element && element.config && element.config.elements) {
        return element.config.elements.map((childElem) => ({
          ...childElem,
          parent: element,
          parentKey: elementKey,
        }));
      }
      return undefined;
    }) || [];

  // Flatten the array
  return [].concat.apply([], elementsArray);
};

export const getNodePageOuts = (node) => {
  if (!node) {
    return;
  }

  const elements = getAllElements(node);

  const allElementsArePageOut = node.nodeType === conditionalNode;

  // console.log('elements', elements);

  const pageOuts = elements.reduce((output, element) => {
    const elementsPageOuts = elementPageOuts(element, allElementsArePageOut);
    if (elementsPageOuts) {
      return output.concat(elementsPageOuts);
    }
    return output;
  }, []);

  return pageOuts;
};

// Gets the theme for this "tree" given a starting initial node
export const getInitialNodePropertyType = (getPropertyType) => (
  initialNode,
  traverserState
) => {
  let nextNode = initialNode;
  while (nextNode) {
    const propertyType = getPropertyType(nextNode);
    if (propertyType) {
      console.log('Found property type (eg: theme): ', propertyType);
      return propertyType;
    }
    nextNode = nextNode.getNextPrimaryNode(traverserState);
  }

  console.log('No property of specified type found');
  return undefined;
};

// Internal get page theme for a node (with no traversing)
export const getPageStyleSimple = (node) => {
  return node?.pageStyle;
};

// Gets the theme for this "tree" given a starting initial node
export const getInitialNodePageStyle = getInitialNodePropertyType(
  getPageStyleSimple
);

// Internal get page theme for a node (with no traversing)
export const getPageTitleSimple = (node) => {
  return node.nameIsTitle ? node.name : undefined;
};

// Gets the theme for this "tree" given a starting initial node
export const getInitialNodePageTitle = getInitialNodePropertyType(
  getPageTitleSimple
);

// Internal get if a node is an end node
export const getIsEndnode = (node) => {
  return node?.isEndNode();
};

// Gets if there is an "end node" in the chain
export const hasEndNodeInChain = getInitialNodePropertyType(getIsEndnode);

// Get all nodes _on the same page_ that link to this node from "above" it
// Guaranteed nodes are ones that will be encountered
// Other nodes are ones that may / may not be encountered
export const getAllSamePageUpstreamNodes = (node) => {
  return {
    guaranteed: [],
    other: [],
  };
};

// Get all nodes _on the same page_ that link to this node from "below" it
// Guaranteed nodes are ones that will be encountered
// Other nodes are ones that may / may not be encountered
export const getAllSamePageDownstreamNodes = (node) => {
  return {
    guaranteed: [],
    other: [],
  };
};

export const getAllSamePageNodes = (node) => {
  const upstreamNodes = getAllSamePageUpstreamNodes(node);
  const downstreamNodes = getAllSamePageDownstreamNodes(node);

  const guaranteed = upstreamNodes.guaranteed.concat(
    downstreamNodes.guaranteed
  );
  const other = upstreamNodes.other.concat(downstreamNodes.other);
  return {
    guaranteed: guaranteed,
    other: other,
  };
};

// Gets the node's page style even if not the "initial node" on the page.
// (This is really only useful for the editor UI -> probably should move to client code)
export const getNodePageStyle = (node) => {
  const { guaranteed, other } = getAllSamePageNodes(node);

  // This needs a major amt of refactoring, but was intended to get the point across...
  // NO WAY IT WORKS - for one, it is making an array of dicts, should just be a dict or Map
  const getNodesWithStyles = (nodeArray) => {
    return nodeArray
      .map((node) => {
        const pageTheme = getPageStyleSimple(node);
        if (pageTheme) {
          return { [node.nodeId]: pageTheme };
        }
        return null;
      })
      .filter((nodeTheme) => nodeTheme);
  };

  return {
    guaranteed: getNodesWithStyles(guaranteed),
    other: getNodesWithStyles(other),
  };
};
