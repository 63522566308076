/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core';
import * as React from 'react';
import {
  CoreType,
  ThemePropsType,
  styleKey,
  codeBasedVars,
} from 'supportchef-sdk-core';
import { styleCategory as pageStyle } from './PageStyles';

export interface PageContainerProps {
  theme?: any;
  styleNames: any;
  containerRef: any;
  themedProps: ThemePropsType;
  isEditing?: boolean;
}

export class PageContainer extends React.Component<PageContainerProps> {
  static componentName = 'PageContainer';

  render() {
    const { children, containerRef, themedProps, isEditing } = this.props;
    const { StyledComponent: StyledContainer, globalStyles } = themedProps;

    return (
      // We need both overflow auto and padding to be in the same component.
      // This is required because overflow-y: auto and overflow-x: visisble are not valid:
      // https://css-tricks.com/popping-hidden-overflow/
      // We need overflow auto specifically for the test UI, where the header's
      // "Fixed" attribute doensn't work.
      // We separate out this padding from the padding of the styled container
      // because we want the user to be able to specify padding seperately
      // from the safe-inset keys. If they want to "ignore" safe inset, then
      // they can set the inset to a negative value.
      //
      // Background is needed so that children can inherit the global color
      // Specificically for the editor interface
      <div
        css={css`
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          overflow: ${isEditing ? 'visible' : 'auto'};
          background: inherit;
          padding-left: ${styleKey(codeBasedVars.safeInsetLeft)};
          padding-right: ${styleKey(codeBasedVars.safeInsetRight)};
          padding-bottom: ${styleKey(codeBasedVars.safeInsetBottom)};
          padding-top: ${styleKey(codeBasedVars.safeInsetTop)};
        `}
      >
        <StyledContainer ref={containerRef}>
          <Global styles={globalStyles} />
          {children}
        </StyledContainer>
      </div>
    );
  }
}
