/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import initSubscriber from './subscriber';

import rootReducer from '../reducers/rootReducer';

const composeEnhancers = composeWithDevTools({
  shouldHotReload: false,
  features: {
    persist: false, // Do not allow persisting states on page reloading
    // This flag was more of a headache than something that was useful
    // to me - as some views on enter/exit set things/ect
  },
});

const store = createStore(
  rootReducer,
  // Redux dev-tools chrome extension
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancers(applyMiddleware(ReduxThunk))
);

// Subscribe to the store
initSubscriber(store);

export default store;
