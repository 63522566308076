import * as React from 'react';
import { BaseGraphNode } from './BaseGraphNode';
import { GraphTraverser, TraverserState } from 'GraphTraverser';

export class ConditionalGraphNode extends BaseGraphNode {
  protected next: any;
  linkIsPrimary(link, firstLoad = false) {
    return link.sourceName === 'cond-default';
  }

  triggerNextPage = (graphTraverser: GraphTraverser) => {
    const nextNode = this.getNextPrimaryNode(
      graphTraverser.props.traverserState
    );
    if (nextNode) {
      return nextNode.triggerNextPage(graphTraverser);
    }
    return false;
  };

  getNextPrimaryNode = (traverserState: TraverserState) => {
    const firstLoad = traverserState.firstPageLoad;
    if (true || firstLoad || !this.next) {
      this.next = undefined;
      const conditionals = this.node.contents;
      const varstore = traverserState.varStore;

      for (let i = 0; i < conditionals.length; i++) {
        const [linkSource, conditional] = conditionals[i];
        if (evaluate(varstore, conditional)) {
          for (let j = 0; j < this.links.length; j++) {
            if (this.links[j].sourceName == linkSource) {
              this.next = this.graph.nodeObjs[this.links[j].target];
              return this.next;
            }
          }
          // If no matching node was found for a matching condition
          // then we don't show anything from that node!
          return null;
        }
      }

      if (!this.next) {
        for (let j = 0; j < this.links.length; j++) {
          if (this.links[j].sourceName === 'cond-default') {
            this.next = this.graph.nodeObjs[this.links[j].target];
            break;
          }
        }
      }
    }

    return this.next ?? null;
  };
}

const evaluate = (varstore, conditional) => {
  // let bool = true;
  const rhs = val(varstore, conditional.rhs);
  const lhs = val(varstore, conditional.lhs);
  switch (conditional.compare) {
    case 'notEquals':
      return rhs !== lhs;
    case 'equals':
      return rhs === lhs;
    case 'contains':
      return lhs && rhs && lhs.toString().includes(rhs);
    case 'regex':
      const regex = new RegExp(rhs);
      return regex.test(lhs);
    default:
      return false;
  }
};

const val = (varstore, hs) => {
  return hs?.type == 'var' ? varstore.get(hs.value) : hs?.value;
};
