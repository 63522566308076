import * as React from 'react';
import { BaseGraphNode } from './BaseGraphNode';
import { GraphTraverser } from '/GraphTraverser';
import { postAction } from '/lib/api';
import { promiseOsCustomCode } from '/lib/mobileCallback';

export class ActionGraphNode extends BaseGraphNode {
  private processingAction = false;

  getContent = () => {};

  actionTask = async (graphTraverser: GraphTraverser) => {
    const nodeMetadata = this.node.metadata || {};
    const actionType = nodeMetadata.actionType;

    switch (actionType) {
      case 'zapier':
        return this.zapierAction(graphTraverser);
      case 'customCode':
        return this.customCodeAction(graphTraverser);
      default:
        return graphTraverser.executeAction(actionType, nodeMetadata);
    }
  };

  actionTaskWrapper = async (graphTraverser: GraphTraverser) => {
    await this.actionTask(graphTraverser);
    graphTraverser.followLink('right', this);
  };

  triggerNextPage = (graphTraverser: GraphTraverser) => {
    console.log('this.processingAction', this.processingAction);
    if (!this.processingAction) {
      this.processingAction = true;
      this.actionTaskWrapper(graphTraverser).finally(() => {
        this.processingAction = false;
      });
    }

    // We will change to the next page in the background
    return false;
  };

  showLoading = () => {
    console.log('this.node.metadata', this.node.metadata);
    return this.node.metadata?.showLoading ?? true;
  };

  // Generate body for code action from the metadata
  customCodeAction = (gt: GraphTraverser) => {
    const name = this.node.metadata.functionName;
    if (!name) {
      return new Promise((resolve, error) => resolve());
    }
    const ts = gt.props.traverserState;
    const parameters = {};
    const retvals = {};
    if (this.node.metadata?.references?.output) {
      this.node.metadata.references.output.forEach((o) => {
        parameters[o.target] = ts.varStore.get(o.source); // || '';
      });
    }
    if (this.node.metadata?.references?.input) {
      this.node.metadata.references.input.forEach((o) => {
        retvals[o.source] = o.target;
      });
    }
    const { hiddenFields } = gt.props;
    const callback = hiddenFields?.sdkCallback;
    return promiseOsCustomCode(
      name,
      parameters,
      callback,
      retvals,
      ts.varStore
    );
    // const body = { key: name, parameters, actionType: 'customCode' };
    // return gt.executeAction('action', body);
  };

  zapierAction = (gt: GraphTraverser) => {
    const ts = gt.props.traverserState;
    const outputs = {};
    if (!this.node.metadata.references?.output) {
      return new Promise((resolve, error) => resolve());
    }
    this.node.metadata.references.output.forEach((o) => {
      outputs[o.target] = ts.varStore.get(o.source);
    });
    const node = { ...this.node };
    const analytics = {
      generated: Date.now().toString(),
    };

    const body = { nodeId: this.nodeId, node, outputs, analytics };
    // console.log('Zapier Action: ', body);

    // Returns a promise
    return postAction(
      ts.projectState.path,
      ts.projectState.project.projectVer.version,
      body
    );
  };
}
