import React from 'react';
import ProjectPropsMerger from './ProjectPropsMerger';

export const ProjectContext = React.createContext({});

export const withProjectContext = (Wrapped) => {
  return class extends React.Component {
    static contextType = ProjectContext;
    merger = new ProjectPropsMerger();

    render() {
      return <Wrapped {...this.merger.merge(this.props, this.context)} />;
    }
  };
};
