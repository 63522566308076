import {
  SAVING_PROJECT,
  SAVED_PROJECT,
  NEXT_SAVE_TS,
  PROJECT_SAVING_ERROR,
  PROJECT_ERROR,
  NAME_CHANGE_PROJECT,
  DESCRIPTION_CHANGE_PROJECT,
  // Node actions
  MOVE_NODE,
  MOVE_NODE_END,
  UNDO_PROJECT,
  REDO_PROJECT,
  CLEAR_UNDO_HISTORY_PROJECT,
  REMOVE_NODE,
  REMOVE_LINK,
  // Diagram Actions
  DIAGRAM_CONTAINER_RESIZE,
  DIAGRAM_CONTAINER_SIZE_UPDATE,
  SET_SOURCE_PORT_DRAG,

  // Project Node actions
  MODIFY_FORM_ENTRY_AT_PATH,
  MODIFY_CONDITIONAL_ENTRY_AT_PATH,
  SET_NODE_NAME,
  UPDATE_ACTION_NODE_METADATA,
  MODIFY_CONDITIONAL_ENTRY,
  // Project Version Actions
  SAVING_PROJECT_VERSION,
  SAVED_PROJECT_VERSION,
  PROJECT_VERSION_SAVING_ERROR,
  NAME_CHANGE_PROJECT_VERSION,
  RECEIVED_PROJECT_VERSION,
} from '../actions/types';

import { excludeAction } from 'redux-undo';

export const ignoreUndoActions = [
  // FETCHING_PROJECTS,
  // RECEIVE_PROJECTS,
  SAVING_PROJECT,
  SAVED_PROJECT,
  NEXT_SAVE_TS,
  PROJECT_ERROR,
  PROJECT_SAVING_ERROR,
  DIAGRAM_CONTAINER_RESIZE,
  DIAGRAM_CONTAINER_SIZE_UPDATE,
  SET_SOURCE_PORT_DRAG,
  SAVING_PROJECT_VERSION,
  SAVED_PROJECT_VERSION,
  PROJECT_VERSION_SAVING_ERROR,
  RECEIVED_PROJECT_VERSION,
];

export const undoOptions = {
  filter: excludeAction(ignoreUndoActions),
  groupBy: (action) => {
    const groupActionsByType = [
      SET_NODE_NAME,
      NAME_CHANGE_PROJECT_VERSION,
      NAME_CHANGE_PROJECT,
      DESCRIPTION_CHANGE_PROJECT,
      UPDATE_ACTION_NODE_METADATA,
      MODIFY_CONDITIONAL_ENTRY,
    ];
    // Group all actions of this type by actions of the same name.
    // Doesn't matter if nodeId is undefined
    if (groupActionsByType.indexOf(action.type) >= 0) {
      return action.type + action.nodeId + action.uniqueUndoable;
    }

    // Move node and move node end should be grouped together
    if ([MOVE_NODE, MOVE_NODE_END].indexOf(action.type) >= 0) {
      return MOVE_NODE;
    }

    // We group these together so that "undoing a node delection"
    // also brings back the links.
    // TODO: We should change this logic to only undo if REMOVE_LINK
    //       was a part of a remove node operation
    if ([REMOVE_NODE, REMOVE_LINK].indexOf(action.type) >= 0) {
      return REMOVE_NODE;
    }

    if (
      MODIFY_FORM_ENTRY_AT_PATH === action.type ||
      MODIFY_CONDITIONAL_ENTRY_AT_PATH === action.type
    ) {
      return (
        MODIFY_FORM_ENTRY_AT_PATH +
        action.contentEntryId +
        action.modifyPath +
        action.uniqueUndoable
      );
    }

    // In some cases we add an extra ID to group multiple disparate actions together
    if (action.undoableGroupingId) {
      return action.undoableGroupingId;
    }
  },
  limit: 25, // set a limit for the history
  syncFilter: true,
  undoType: UNDO_PROJECT,
  redoType: REDO_PROJECT,
  clearHistoryType: CLEAR_UNDO_HISTORY_PROJECT,
  debug: false,
};
