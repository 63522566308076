import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'Button';

export const metadata = {
  htmlType: 'button',
  elements: {
    '.textContainer': { desc: 'Header text' },
    '.spinner': {
      desc: 'Button spinning icon',
    },
  },
  basicControl: {},
  // Styles that are ignored when in editor interface
  ignoreWhenEditing: [[':active'], ['@media (hover:hover)']],
};

export const styleModes = {
  defaultButton: {
    key: 'defaultButton',
    prio: defaultStylePrio,
  },
  primaryButton: {
    key: 'primaryButton',
    prio: defaultStylePrio * 2,
    sub: ['defaultButton'], // Does not automatically include defaultButton if selected
  },
  menuItem: {
    key: 'menuItem',
    prio: defaultStylePrio,
  },
};

export const styles = {
  default: {
    userSelect: 'none',
    outline: '0',
    cursor: 'pointer',
    '& .textContainer': {
      position: 'relative',
      // width: '100%',
    },
    '& .spinner': {
      position: 'absolute',
      left: '-25px',
      top: 'calc(50% - 8px)',
    },
  },
  /**
   * Default button style
   */
  [styleCategoryKey(styleModes.defaultButton)]: {
    font: styleKey(styleKeys.font.t300),
    // lineHeight: '1.499',
    // fontWeight: '400',
    width: '100%',
    color: styleKey(styleKeys.colors.buttonDefaultText), //'rgba(0,0,0,0.65)',
    backgroundColor: '#ffffff',
    textShadow: '0 -1px 0 rgba(0,0,0,0.12)',
    boxShadow: '0 2px 0 rgba(0,0,0,0.015)',
    position: 'relative',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    backgroundImage: 'none',
    borderColor: styleKey(styleKeys.colors.border), //'#d9d9d9',
    borderWidth: `1px`, //'1px solid transparent',
    borderStyle: `solid`, //'1px solid transparent',
    transition: 'color,background-color .2s cubic-bezier(.645, .045, .355, 1)',
    touchAction: 'manipulation',
    minHeight: '45px',
    padding: '0 15px',
    margin: '8px 0',
    fontSize: '16px',
    borderRadius: '4px',
    // '::before': {
    //   position: 'absolute',
    //   top: '-1px',
    //   right: '-1px',
    //   bottom: '-1px',
    //   left: '-1px',
    //   zIndex: '1',
    //   display: 'none',
    //   background: '#fff',
    //   borderRadius: 'inherit',
    //   opacity: '.35',
    //   transition: 'opacity .2s',
    //   content: '',
    //   pointerEvents: 'none',
    // },
    '@media (hover:hover)': {
      ':hover': {
        color: styleKey(styleKeys.colors.buttonDefaultText), //'#fff',
        backgroundColor: styleKey(styleKeys.colors.buttonDefaultHover), //'#40a9ff',
        borderColor: '#40a9ff',
      },
    },
    [customStyleTypes.fixedActive.key]: {
      color: styleKey(styleKeys.colors.buttonDefaultText), //'#fff',
      backgroundColor: styleKey(styleKeys.colors.buttonDefaultActive), //'#096dd9',
      borderColor: '#096dd9',
      textDecoration: 'none',
      // Remove transitions when pressing "down" - makes mobile feel a lot better
      transition: 'none',
      // background: '#fff',
    },
    '&.loading': {
      color: '#fff',
      backgroundColor: '#096dd9',
      borderColor: '#096dd9',
      textDecoration: 'none',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  /**
   * Primary Button style
   */
  [styleCategoryKey(styleModes.primaryButton)]: {
    color: styleKey(styleKeys.colors.buttonPrimaryText), //'#fff',
    backgroundColor: styleKey(styleKeys.colors.buttonPrimaryFill), //'#1890ff',
    borderColor: styleKey(styleKeys.colors.border), //'#1890ff',
    boxShadow: '0 2px 0 rgba(0,0,0,0.045)',
    '@media (hover:hover)': {
      ':hover': {
        color: styleKey(styleKeys.colors.buttonPrimaryText), //'#fff',
        backgroundColor: styleKey(styleKeys.colors.buttonPrimaryHover), //'#40a9ff',
        borderColor: '#40a9ff',
      },
    },
    [customStyleTypes.fixedActive.key]: {
      color: styleKey(styleKeys.colors.buttonPrimaryText), //'#fff',
      backgroundColor: styleKey(styleKeys.colors.buttonPrimaryActive), //'#096dd9',
      borderColor: '#096dd9',
      textDecoration: 'none',
      // Remove transitions when pressing "down" - makes mobile feel a lot better
      transition: 'none',
      // background: '#fff',
    },
  },
  /**
   * Menu item style
   */
  [styleCategoryKey(styleModes.menuItem)]: {
    minHeight: '54px',
    width: customStyleTypes.fullWidth.key,
    padding: '0',
    paddingLeft: '25px',
    paddingRight: '25px',
    margin: '0px',
    border: 'none',
    transition: 'all .3s cubic-bezier(.645, .045, .355, 1)',
    // color: colors.foreground,
    color: styleKey(styleKeys.colors.buttonMenuText), //'#636363',
    backgroundColor: styleKey(styleKeys.colors.buttonMenuFill), //'#fff',
    textAlign: 'left',
    textShadow: '',
    fontSize: '16px',
    borderBottom: `solid ${styleKey(styleKeys.colors.mono.t500)}`,
    borderWidth: '0.5px',
    // boxShadow: '0 0.55px 0 #C8C7CC, 0 -0.55px 0 #C8C7CC',
    // Extra check for hover existing makes it so that
    // mobile doesn't remain highlighted
    '@media (hover:hover)': {
      ':hover': {
        color: styleKey(styleKeys.colors.buttonMenuText), // color: '#fff',
        backgroundColor: styleKey(styleKeys.colors.buttonMenuHover), //'#E8E8E8',
        borderColor: '#E8E8E8',
      },
    },
    [customStyleTypes.fixedActive.key]: {
      color: styleKey(styleKeys.colors.buttonMenuText), // color: '#fff',
      backgroundColor: styleKey(styleKeys.colors.buttonMenuActive), //'#D8D8D8',
      borderColor: '#D8D8D8',
      textDecoration: 'none',
      // Remove transitions when pressing "down" - makes mobile feel a lot better
      transition: 'none',
      // background: '#fff',
    },
    '& .text': {
      font: styleKey(styleKeys.font.t300),
      width: '100%',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      color: '#C6C6CB',
    },
    '& .textContainer': {
      flexGrow: 1,
    },
    '& .spinner': {
      left: '-20px',
    },
  },
};
