import {
  FETCHING_PROJECTS,
  RECEIVE_PROJECTS,
  FETCHING_PROJECT,
  RECEIVED_PROJECT,
  PROJECT_LOADING_ERROR,
  DELETING_PROJECT,
  DELETED_PROJECT,
  OPEN_CREATING_PROJECT_SCREEN,
  CREATING_PROJECT,
  ADD_PROJECT,
  NAME_CHANGE_PROJECT,
  DESCRIPTION_CHANGE_PROJECT,
  SAVING_PROJECT,
  SAVED_PROJECT,
  NEXT_SAVE_TS,
  PROJECT_SAVING_ERROR,
  CHANGING_PUBLISH_SETTINGS_PROJECT,
  CHANGED_PUBLISH_SETTINGS_PROJECT,
  DEFAULT_UI_VER_CHANGE_PROJECT,
  ERROR_PUBLISH_SETTINGS_PROJECT,
  PROJECT_ERROR,
  UNDO_PROJECT,
  REDO_PROJECT,
  CLEAR_UNDO_HISTORY_PROJECT,
  // Project Version / Project level actions
  FETCHING_PROJECT_VERSION,
  RECEIVED_PROJECT_VERSION,
  PROJECT_VERSION_LOADING_ERROR,
  // Project Versions
  NAME_CHANGE_PROJECT_VERSION,
  VERSION_CHANGE_PROJECT_VERSION,
  SAVING_PROJECT_VERSION,
  SAVED_PROJECT_VERSION,
  PROJECT_VERSION_SAVING_ERROR,
  DELETING_PROJECT_VERSION,
  DELETED_PROJECT_VERSION,
  CREATING_PROJECT_VERSION,
  ADD_PROJECT_VERSION,
  PROJECT_VERSION_ERROR,
  UPDATING_PROJECT_VERSION,
  UPDATED_PROJECT_VERSION,
  PUBLISHING_PROJECT_VERSION,
  PUBLISHED_PROJECT_VERSION,
  UPDATE_PROJECT_VERSION_THEME,
  IMPORTED_PROJECT_VERSION,
  // Actions that shouldn't trigger a rerendering of Project
  MOVE_NODE_END,
  MOVE_NODE,
  SET_SOURCE_PORT_DRAG,
} from '../actions/types';
import undoable, { newHistory } from 'redux-undo';
import { displayError } from 'lib/errorHelpers';
import log from 'lib/logging';

import {
  isDiagramActionType,
  diagramReducer,
  initialDiagram,
  serializeSafeDiagramObject,
  deserializeDiagramObject,
} from './diagramReducer';
import { undoOptions, ignoreUndoActions } from './undoable';
import memoizeOne from 'memoize-one';

// This is only included so that Map's are properly
// rendered in Redux Dev Tools - we could remove this for prod.
require('map.prototype.tojson');

export const projectVersionInitialState = {
  diagram: initialDiagram,
  // projectObjects: projectNodeInitialState,
};

export const projectInitialState = {
  versions: {},
  published: [],
  // projectObjects: projectNodeInitialState,
};

export const initialState = {
  allProjects: new Map(),
  loadingProjects: false,
  loaded: false,
  loadingProjectId: null,
  loadedProjectId: null,
  deletingProject: false,
  creatingProject: false,
  createdProjectId: null,
  error: '',
};

/**
 * Transforms project into an object that can be safely
 * serialized (removing versions dictionary)
 * @param  {[type]} project [description]
 * @return {[type]}         [description]
 */
export function serializeSafeProject(project) {
  return {
    ...project,
    versions: {},
  };
}

/**
 * Transforms project into an object that can be safely
 * serialized (removing maps mostly)
 * @param  {[type]} project [description]
 * @return {[type]}         [description]
 */
export function serializeSafeProjectVersion(project) {
  return {
    ...project,
    diagram: serializeSafeDiagramObject(project.diagram),
  };
}

function deserializeProjectVersion(projectVersion, projectId) {
  if (projectVersion.diagram) {
    projectVersion.diagram = deserializeDiagramObject(projectVersion.diagram);
  }
  return { ...projectVersionInitialState, projectId, ...projectVersion };
}

function deserializeProject(project) {
  if (project.versions) {
    Object.keys(project.versions).forEach((versionKey) => {
      project.versions[versionKey] = deserializeProjectVersion(
        project.versions[versionKey],
        project.projectId
      );
    });
  }
  return { ...projectInitialState, ...project };
}

function projectToMapTuple(project) {
  return [project.present.projectId, project];
}

function createHistory(state, ignoreInitialState) {
  // ignoreInitialState essentially prevents the user from undoing to the
  // beginning, in the case that the undoable reducer handles initialization
  // in a way that can't be redone simply
  const history = newHistory([], state, []);
  history._latestUnfiltered = null;
  return history;
}

// This merges an unserialized project, and the project from all of the projects
//
function mergeProjects(serializedProject, oldProject) {
  const newProj = deserializeProject(serializedProject);
  if (newProj.versions) {
    Object.keys(newProj.versions).forEach((versionKey) => {
      if (
        newProj.versions[versionKey].partial &&
        oldProject.present.versions[versionKey]
      )
        newProj.versions[versionKey] = oldProject.present.versions[versionKey];
    });
  }
  oldProject.present = newProj;
  return oldProject;
}

function deserializeProjectToMap(project, oldProjects = null) {
  // If there is a previous project + this new new project does not contain all versions
  // then we want to ignore this update - perhaps we can merge it better in the future
  if (oldProjects && !project.allVersions) {
    const oldProject = oldProjects.get(project.projectId);
    if (oldProject?.present?.versions && oldProject?.present?.versions !== {}) {
      log.info(
        'Merging together old project state due to new versions, old projects:',
        oldProjects
      );
      return mergeProjects(project, oldProject);
    }
  }
  const deserializedProject = deserializeProject(project);
  const undoableProject = createHistory(deserializedProject);
  return projectToMapTuple(undoableProject);
}

function deserializeProjectsArrayToMap(projects, action, oldProjects = null) {
  return projects.map((project) =>
    deserializeProjectToMap(project, oldProjects)
  );
}

function isProjectUndoRedoActionType(actionType) {
  return actionType === UNDO_PROJECT || actionType === REDO_PROJECT;
}

function isProjectVersionActionType(actionType) {
  return (
    actionType === NAME_CHANGE_PROJECT_VERSION ||
    actionType === VERSION_CHANGE_PROJECT_VERSION ||
    actionType === SAVING_PROJECT_VERSION ||
    actionType === SAVED_PROJECT_VERSION ||
    actionType === PROJECT_VERSION_SAVING_ERROR ||
    actionType === UPDATING_PROJECT_VERSION ||
    actionType === UPDATED_PROJECT_VERSION ||
    actionType === UPDATE_PROJECT_VERSION_THEME ||
    actionType === IMPORTED_PROJECT_VERSION ||
    actionType === RECEIVED_PROJECT_VERSION ||
    isDiagramActionType(actionType)
  );
}

function isProjectObjectActionType(actionType) {
  return (
    actionType === NAME_CHANGE_PROJECT ||
    actionType === DESCRIPTION_CHANGE_PROJECT ||
    actionType === SAVING_PROJECT ||
    actionType === SAVED_PROJECT ||
    actionType === NEXT_SAVE_TS ||
    actionType === CHANGING_PUBLISH_SETTINGS_PROJECT ||
    actionType === CHANGED_PUBLISH_SETTINGS_PROJECT ||
    actionType === DEFAULT_UI_VER_CHANGE_PROJECT ||
    actionType === ERROR_PUBLISH_SETTINGS_PROJECT ||
    actionType === PROJECT_SAVING_ERROR ||
    // Even though the below operate on project versions,
    // they must occur at the project object level
    actionType === DELETING_PROJECT_VERSION ||
    actionType === DELETED_PROJECT_VERSION ||
    actionType === CREATING_PROJECT_VERSION ||
    actionType === ADD_PROJECT_VERSION ||
    actionType === PUBLISHING_PROJECT_VERSION ||
    actionType === PUBLISHED_PROJECT_VERSION ||
    actionType === PROJECT_VERSION_ERROR ||
    actionType === CLEAR_UNDO_HISTORY_PROJECT ||
    actionType === FETCHING_PROJECT_VERSION ||
    actionType === PROJECT_VERSION_LOADING_ERROR ||
    isProjectUndoRedoActionType(actionType) ||
    isProjectVersionActionType(actionType)
  );
}

// Certain actions should not trigger a re-rendering
// of the test-device
function isNewContentAction(actionType) {
  return !(
    actionType === MOVE_NODE_END ||
    actionType === MOVE_NODE ||
    actionType === SET_SOURCE_PORT_DRAG
  );
}

// Get a project object from the projects state
// This is safe to memoize because projects has to be a redux value!
// Slightly helpful to memoize since this function is called in many places
export const getProject = memoizeOne(function (projects, projectId) {
  const undoableProject = projects.allProjects.get(projectId);
  if (!undoableProject || !undoableProject.present) {
    return undefined;
  }
  return undoableProject.present;
});

const projectVersionObjectReducer = (state, action) => {
  const modifiedTs = action.timestamp || state.modifiedTs;
  const contentModifiedTs = isNewContentAction(action.type)
    ? modifiedTs
    : state.contentModifiedTs;
  switch (action.type) {
    case NAME_CHANGE_PROJECT_VERSION:
      return {
        ...state,
        name: action.name,
        modifiedTs,
      };
    case VERSION_CHANGE_PROJECT_VERSION:
      // Needs to also change key used as well as URL user is on
      return {
        ...state,
        version: action.newVersion,
        modifiedTs,
      };
    case SAVING_PROJECT_VERSION:
      return {
        ...state,
        saving: true,
      };
    case SAVED_PROJECT_VERSION:
      return {
        ...state,
        saving: false,
        savedTs: action.timestamp,
        nextSaveTsMs: action.nextSaveTsMs
          ? action.nextSaveTsMs
          : state.nextSaveTsMs,
      };
    case PROJECT_VERSION_SAVING_ERROR:
      const error = action.error || 'Error Saving';
      displayError(error);
      return {
        ...state,
        error,
        saving: false,
        nextSaveTsMs: action.nextSaveTsMs
          ? action.nextSaveTsMs
          : state.nextSaveTsMs,
      };
    case UPDATING_PROJECT_VERSION:
      return { ...state, updatingProject: true, updatedProject: false };
    case UPDATED_PROJECT_VERSION:
      return {
        ...state,
        updatingProject: false,
        updatedProject: true,
        name: action.name ? action.name : state.name,
      };
    case UPDATE_PROJECT_VERSION_THEME:
      return {
        ...state,
        theme: action.theme,
        modifiedTs,
        contentModifiedTs,
      };
    case IMPORTED_PROJECT_VERSION:
      return {
        ...state,
        diagram: deserializeDiagramObject(action.diagram),
        theme: action.theme,
        modifiedTs,
        contentModifiedTs,
      };
    default:
      if (isDiagramActionType(action.type)) {
        return {
          ...state,
          diagram: diagramReducer(state.diagram, action),
          // projectObjects: nodeReducer(state.projectObjects, action),
          modifiedTs,
          contentModifiedTs,
        };
      }
      return state;
  }
};

const projectObjectReducer = (state, action) => {
  switch (action.type) {
    case NAME_CHANGE_PROJECT:
      return {
        ...state,
        name: action.name,
        modifiedTs: action.timestamp,
      };
    case DESCRIPTION_CHANGE_PROJECT:
      return {
        ...state,
        description: action.description,
        modifiedTs: action.timestamp,
      };
    case DEFAULT_UI_VER_CHANGE_PROJECT:
      return {
        ...state,
        defaultUiVer: action.defaultUiVer,
        modifiedTs: action.timestamp,
      };
    case SAVING_PROJECT:
      return {
        ...state,
        saving: true,
      };
    case SAVED_PROJECT:
      return {
        ...state,
        saving: false,
        savedTs: action.timestamp,
        nextSaveTsMs: action.nextSaveTsMs
          ? action.nextSaveTsMs
          : state.nextSaveTsMs,
      };
    case NEXT_SAVE_TS:
      return {
        ...state,
        nextSaveTsMs: action.nextSaveTsMs,
      };
    case PROJECT_SAVING_ERROR:
      return {
        ...state,
        saving: false,
        error: action.error || 'Error Saving',
        nextSaveTsMs: action.nextSaveTsMs
          ? action.nextSaveTsMs
          : state.nextSaveTsMs,
      };
    case CHANGING_PUBLISH_SETTINGS_PROJECT:
      return { ...state, isChangingPublish: true };
    case CHANGED_PUBLISH_SETTINGS_PROJECT:
      const retObj = {
        ...state,
        isChangingPublish: false,
      };
      if (action.project.toLoad !== undefined) {
        retObj.toLoad = action.project.toLoad;
      }
      if (action.project.debug !== undefined) {
        retObj.debug = action.project.debug;
      }
      if (action.project.default !== undefined) {
        retObj.default = action.project.default;
      }
      return retObj;
    case ERROR_PUBLISH_SETTINGS_PROJECT:
      return { ...state, error: action.error, isChangingPublish: false };
    case DELETING_PROJECT_VERSION:
      return { ...state, deletingProjectVer: action.version };
    case DELETED_PROJECT_VERSION:
      const { [action.version]: deletedVer, ...versions } = state.versions;
      return { ...state, deletedProject: action.version, versions };
    case CREATING_PROJECT_VERSION:
      return { ...state, addingProjectVer: action.version };
    case ADD_PROJECT_VERSION:
      return {
        ...state,
        projectCreationErrors: null,
        addingProjectVer: null,
        addedProjectVer: action.projectVer.version,
        versions: {
          ...state.versions,
          [action.projectVer.version]: deserializeProjectVersion(
            action.projectVer,
            action.projectId
          ),
        },
      };
    case RECEIVED_PROJECT_VERSION:
      return {
        ...state,
        versions: {
          ...state.versions,
          [action.projectVer.version]: deserializeProjectVersion(
            action.projectVer,
            action.projectId
          ),
        },
      };
    case PUBLISHING_PROJECT_VERSION:
      return { ...state, publishingVersion: action.version };
    case PUBLISHED_PROJECT_VERSION:
      // log.info(state.published);
      // log.info(JSON.stringify(state.published));
      // log.info(action.version);
      // log.info([...state.published, action.version]);
      return {
        ...state,
        publishingVersion: null,
        publishedVersion: action.version,
        published: [...state.published, action.version],
        versions: {
          ...state.versions,
          [action.projectVer.version]: deserializeProjectVersion(
            { ...action.projectVer, isPublished: true },
            action.projectId
          ),
        },
      };
    case PROJECT_VERSION_ERROR:
      return {
        ...state,
        projectVersionErrors: action.errors,
        projectVersionErrorVersion: action.version,
        deletingProjectVer: null,
        publishingVersion: null,
        addingProjectVer: null,
      };
    default:
      if (isProjectVersionActionType(action.type)) {
        // In case we are changing the version
        const version = action.newVersion || action.version;
        if (!action.version) {
          log.error("Action that doesn't specify version", action);
          return state;
        }
        if (state.versions[version].isPublished) {
          log.info("Project is published and can't be edited", action);
          const error = "Can't edit published project";
          displayError(error);
          return { ...state, error };
        }
        return {
          ...state,
          versions: {
            ...state.versions,
            [version]: projectVersionObjectReducer(
              state.versions[action.version],
              action
            ),
          },
        };
      }
      return state;
  }
};

const undoableProjectObjectReducer = undoable(
  projectObjectReducer,
  undoOptions
);

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PROJECTS:
      return {
        ...state,
        loadingProjects: true,
      };
    case RECEIVE_PROJECTS:
      return {
        ...state,
        allProjects: new Map([
          ...state.allProjects,
          ...deserializeProjectsArrayToMap(
            action.projects,
            action,
            state.allProjects
          ),
        ]),
        loadingProjects: false,
        loaded: true,
        // TODO: this isn't great - causes reload of the project
        // but it also fixes a nasty bug where the project won't reload
        // due to this overriding the project
        loadedProjectId: null,
      };

    case FETCHING_PROJECT_VERSION:
    case FETCHING_PROJECT:
      return {
        ...state,
        loadingProjectId: action.projectId,
        loadingProjectVersion: action.version,
      };
    case RECEIVED_PROJECT:
      return {
        ...state,
        allProjects: new Map([
          ...state.allProjects,
          ...deserializeProjectsArrayToMap(
            [action.project],
            action,
            state.allProjects
          ),
        ]),
        loadingProjectId: null,
        loadingProjectVersion: null,
        loadedProjectId: action.projectId,
        projectLoadError: null,
      };
    case PROJECT_VERSION_LOADING_ERROR:
    case PROJECT_LOADING_ERROR:
      return {
        ...state,
        loadingProjects: false,
        loadingProjectId: null,
        loadingProjectVersion: null,
        projectLoadError: action.error,
      };

    case DELETING_PROJECT:
      return {
        ...state,
        deletingProject: true,
      };
    case DELETED_PROJECT:
      return {
        ...state,
        allProjects: new Map(
          Array.from(state.allProjects).filter(
            ([key, item]) => key !== action.projectId
          )
        ),
        deletingProject: false,
      };
    case OPEN_CREATING_PROJECT_SCREEN:
      return {
        ...state,
        createdProjectId: null,
      };
    case CREATING_PROJECT:
      return {
        ...state,
        creatingProject: true,
      };
    case ADD_PROJECT:
      return {
        ...state,
        allProjects: new Map([
          ...state.allProjects,
          deserializeProjectToMap(action.project),
        ]),
        createdProjectId: action.project.projectId,
        creatingProject: false,
        error: '',
      };
    case PROJECT_ERROR:
      displayError(action.error);
      return {
        ...state,
        error: action.error,
        creatingProject: false,
        deletingProject: false,
      };
    default:
      // This can be used for any of the functions
      // that modify an existing project
      if (isProjectObjectActionType(action.type)) {
        if (!action.projectId) {
          // This leads to REALLY hard to track down bugs
          // (because the undo library tries to make due even when passed undefined)
          log.error(
            'CRITICAL ERROR: ProjectID is not specified on project action!'
          );
        }

        const currentProject = state.allProjects.get(action.projectId);
        // We put the redux undo here
        const updatedProject = undoableProjectObjectReducer(
          currentProject,
          action
        );

        // Always ignore changes to the last time we saved as this never changes
        // when we undo/redo (The server doesn't care we undid the save operation!)
        if (isProjectUndoRedoActionType(action.type)) {
          updatedProject.present.saving = false;
          updatedProject.present.savedTs = currentProject.present.savedTs;
          Object.keys(updatedProject.present.versions).forEach((versionKey) => {
            updatedProject.present.versions[versionKey].saving = false;
            updatedProject.present.versions[versionKey].savedTs =
              currentProject.present.versions[versionKey].savedTs;
          });
        } else if (!ignoreUndoActions.includes(action.type)) {
          const location = window.location.pathname;
          const presentProj = updatedProject.present;
          if (!presentProj.undoableInfo) {
            presentProj.undoableInfo = { location };
          } else if (presentProj.undoableInfo.location !== location) {
            presentProj.undoableInfo = {
              ...presentProj.undoableInfo,
              location,
            };
          }
        }

        let extraChanges = {};
        if (action.RECEIVED_PROJECT_VERSION) {
          extraChanges = {
            loadingProjectId: null,
            loadingProjectVersion: null,
            projectLoadError: null,
          };
        }

        return {
          ...state,
          ...extraChanges,
          allProjects: new Map([
            ...state.allProjects,
            projectToMapTuple(updatedProject),
          ]),
        };
      }
      return state;
  }
};
