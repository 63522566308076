import { unwrapVal } from 'lib/utils';

export function generateVariable(newVariable, currentVar) {
  return {
    name: unwrapVal(newVariable.name, currentVar.name),
    example: unwrapVal(newVariable.example, currentVar.example),
    schema: unwrapVal(newVariable.schema, currentVar.schema),
    manualSchema: unwrapVal(newVariable.manualSchema, currentVar.manualSchema),
  };
}
