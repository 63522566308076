import {
  customStyleTypes,
  styleCategoryKey,
  defaultStylePrio,
  styleKeys,
  styleKey,
} from 'supportchef-sdk-core';

export const styleCategory = 'Input';

export const metadata = {
  htmlType: 'div',
  elements: {
    '.textContainer': { desc: 'Header text' },
    '.spinner': {
      desc: 'Button spinning icon',
    },
  },
  basicControl: {},
};

export const styleModes = {
  compact: {
    key: 'compact',
    prio: defaultStylePrio,
  },
};

export const styles = {
  [styleCategoryKey(styleModes.compact)]: {
    ['& input']: {
      font: styleKey(styleKeys.font.t200),
      paddingTop: styleKey(styleKeys.sizing.t200),
      paddingRight: styleKey(styleKeys.sizing.t500),
      paddingBottom: styleKey(styleKeys.sizing.t200),
      paddingLeft: styleKey(styleKeys.sizing.t500),
    },
  },
  default: {
    color: styleKey(styleKeys.colors.foreground),
    boxSizing: 'border-box',
    display: 'flex',
    // width: '100%',
    backgroundColor: styleKey(styleKeys.colors.inputFill),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: styleKey(styleKeys.colors.inputBorder),
    borderRadius: styleKey(styleKeys.sizing.t100), // borders.useRoundedCorners ? : '0px',
    boxShadow: `0 2px 6px transparent`,
    transitionProperty: 'border, boxShadow, backgroundColor',
    transitionDuration: styleKey(styleKeys.animation.timing100),
    transitionTimingFunction: styleKey(styleKeys.animation.easeOutCurve),
    ['&.disabled']: {
      color: styleKey(styleKeys.colors.inputTextDisabled),
      backgroundColor: styleKey(styleKeys.colors.inputFillDisabled),
      borderColor: styleKey(styleKeys.colors.inputFillDisabled),
    },
    ['&.focused:not(.disabled)']: {
      boxShadow: `0 2px 6px ${styleKey(styleKeys.colors.shadowFocus)}`,
    },
    ['&.focused.error:not(.disabled)']: {
      boxShadow: `0 2px 6px ${styleKey(styleKeys.colors.shadowError)}`,
    },
    ['&.focused']: {
      backgroundColor: styleKey(styleKeys.colors.inputFillFocused),
      borderColor: styleKey(styleKeys.colors.primary.t400),
    },
    ['&.error']: {
      backgroundColor: styleKey(styleKeys.colors.inputFillError),
      borderColor: styleKey(styleKeys.colors.negative.t400),
    },
    ['& input']: {
      font: styleKey(styleKeys.font.t300),
      paddingTop: styleKey(styleKeys.sizing.t400),
      paddingRight: styleKey(styleKeys.sizing.t500),
      paddingBottom: styleKey(styleKeys.sizing.t400),
      paddingLeft: styleKey(styleKeys.sizing.t500),
      color: styleKey(styleKeys.colors.foreground),
      caretColor: styleKey(styleKeys.colors.primary),
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      borderWidth: 0,
      borderStyle: 'none',
      outline: 'none',
      // ...getInputPadding(size, sizing),
      width: '100%',
      maxWidth: '100%',
      cursor: 'text',
      '::placeholder': {
        color: styleKey(styleKeys.colors.inputPlaceholder),
      },
      ['&.disabled']: {
        cursor: 'not-allowed',
        color: styleKey(styleKeys.colors.foregroundAlt),
        '::placeholder': {
          color: styleKey(styleKeys.colors.inputTextDisabled),
        },
      },
      ['&.error']: {
        caretColor: styleKey(styleKeys.colors.negative.t400),
      },
    },
  },
};
