import {
  customStyleTypes,
  styleCategoryKey,
  globalStyleCategories,
  styleKey,
  codeBasedVars,
} from 'supportchef-sdk-core';

export const styleCategory = 'Header';

export const metadata = {
  elements: {
    '.header': { desc: 'Header text' },
    '.backButton': {
      desc: 'Back button',
      elements: {
        svg: { desc: 'Image' },
        span: { desc: 'Text' },
      },
    },
  },
  basicControl: {},
};

export const styles = {
  default: {
    position: 'fixed',
    width: `calc(100% - ${styleKey(codeBasedVars.safeInsetLeft)} - ${styleKey(
      codeBasedVars.safeInsetRight
    )})`,
    paddingLeft: styleKey(codeBasedVars.safeInsetLeft),
    // marginLeft: `calc(-1 * ${styleKey(codeBasedVars.safeInsetLeft)})`,
    paddingRight: styleKey(codeBasedVars.safeInsetRight),
    // marginRight: `calc(-1 * ${styleKey(codeBasedVars.safeInsetRight)})`,
    paddingTop: styleKey(codeBasedVars.safeInsetTop),
    // marginTop: `calc(-1 * ${styleKey(codeBasedVars.safeInsetTop)})`,
    minHeight: '45px',
    // minHeight: '50px',
    // marginTop: '4px',
    backgroundColor: '#fbfbfb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
    borderBottom: '1px solid #d5d5d5',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Nunito Sans', sans-serif",
    boxSizing: 'content-box',
    zIndex: 100,

    '& .header': {
      textAlign: 'center',
      color: '#000',
      fontWeight: '600',
    },
    '& .backButton': {
      color: '#037aff',
      position: 'absolute',
      left: `calc(10px + ${styleKey(codeBasedVars.safeInsetLeft)})`,
      cursor: 'pointer',
      display: 'flex',

      '& svg': { height: '21px' },

      '& span': {
        paddingTop: '1px',
        paddingLeft: '4px',
      },
    },
    [customStyleTypes.landscape.key]: {
      minHeight: '34px',
      marginTop: '0px',
    },
  },
  [styleCategoryKey(globalStyleCategories.dark)]: {
    backgroundColor: 'rgb(56,56,56,0.85)',
    backdropFilter: 'blur(8px)',
    borderBottom: 'none',
    '& .header': {
      color: '#FFF',
    },
  },
};
