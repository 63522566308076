import { getPublicProject } from '/lib/api';
import { uuidv4, isObj } from './utils';

/**
 * This is all of the cached versions / project versions
 * The cache works like this:
 * {
 *   [path]: {
 *     // This key only exists if there is a currently fetching request
 *     callbacks: [
 *       {capturedOptions, callback}
 *     ],
 *     // User provided variables that if changed should trigger a refresh
 *     fetchIfChanged: {options, hiddenFields},
 *     // We can only cache one response at a time for a given project
 *     response: {}
 *   }
 * }
 */
const cachedVersions = {};

// Checks each of the fetchIfChanged fields if the allProvidedFields
// has that field, and if it does, it it's the same
function hasChanged(fetchIfChanged, allProvidedFields) {
  return Object.entries(fetchIfChanged).some(([sourceKey, sourceVal]) => {
    const targetVal = allProvidedFields[sourceKey];
    if (isObj(sourceVal) && targetVal) {
      return hasChanged(sourceVal, targetVal);
    }
    return sourceVal !== targetVal;
  });
}

/**
 *
 * @param cachedVer The object
 * @param allProvidedFields
 */
function waitForFetch(cachedVer, allProvidedFields) {
  return new Promise((accept) => {
    cachedVer.callbacks.push({ allProvidedFields, callback: accept });
  });
}

function callAllWaiting(path, response, fetchIfChanged) {
  const cbs = cachedVersions[path].callbacks;
  if (cbs) {
    cbs.forEach(({ allProvidedFields, callback }) => {
      if (!hasChanged(fetchIfChanged, allProvidedFields)) {
        callback(response);
      } else {
        fetchProject(path, allProvidedFields).then(callback);
      }
    });
    delete cachedVersions[path].callbacks;
  }
}

async function fetchProject(
  path,
  allProvidedFields,
  updateInBackground = null
) {
  const cachedVer = cachedVersions[path];
  cachedVer.callbacks = [];

  const response = await getPublicProject(
    path,
    allProvidedFields.options,
    allProvidedFields.hiddenFields,
    allProvidedFields.vars,
    updateInBackground
  );

  const fetchIfChanged = {
    options: {
      userId: allProvidedFields.options?.userId,
      forceDebug: allProvidedFields.options?.forceDebug,
      forceVersion: allProvidedFields.options?.forceVersion,
    },
  };
  cachedVer.response = response;
  cachedVer.fetchIfChanged = fetchIfChanged;

  callAllWaiting(path, response, fetchIfChanged);

  return response;
}

export async function getPublicProjectIfNeeded(
  path,
  fetchOptions = null,
  userOptions = {},
  hiddenFields = {},
  vars = {},
  updateInBackground = null,
  forceRefresh = false
) {
  let options = userOptions;
  if (fetchOptions) {
    options = { ...fetchOptions, ...userOptions };
  }

  const allProvidedFields = { options, hiddenFields, vars };

  let cachedVer = cachedVersions[path];
  if (cachedVer) {
    if (cachedVer.callbacks && !forceRefresh) {
      return waitForFetch(cachedVer, allProvidedFields);
    }
    if (
      !forceRefresh &&
      !hasChanged(cachedVer.fetchIfChanged, allProvidedFields)
    ) {
      return cachedVer.response;
    }
  } else {
    cachedVer = {};
    cachedVersions[path] = cachedVer;
  }

  return fetchProject(path, allProvidedFields, updateInBackground);
}
