import { styleKeys as themeDefinition, CoreType } from 'supportchef-sdk-core';
import _get from 'lodash.get';

const parseCssVarValueFromObj = (themeValue) => {
  if (themeValue.defaultVar) {
    return `var(${themeValue.defaultVar})`;
  }
  if (themeValue.default) {
    return themeValue.default;
  }
  return undefined;
};

const getCssVarValue = (combinedTheme, themeKey, themeDefValue) => {
  if (combinedTheme[themeKey]) {
    // If the "defined theme" outside themeDef is an object, use this to get
    // the CSS var value if it exists.
    if (typeof combinedTheme[themeKey] === 'object') {
      const extractableValue = parseCssVarValueFromObj(combinedTheme[themeKey]);
      if (extractableValue) {
        return extractableValue;
      }
    } else {
      // If it's a spefic value (eg: '#000') then use that value directly
      return combinedTheme[themeKey];
    }
  }
  // Otherwise, use the themeDefinition
  return parseCssVarValueFromObj(themeDefValue);
};

const cssVarsInternal = (
  combinedTheme,
  cssVars,
  themeStructure: { [key: string]: { key: any; isRoot: boolean } }
) => {
  Object.entries(themeStructure).forEach(([themeDefKey, themeDefValue]) => {
    const cssVarValue = getCssVarValue(
      combinedTheme,
      themeDefKey,
      themeDefValue
    );
    if (cssVarValue && themeDefValue.key) {
      cssVars[themeDefValue.key] = cssVarValue;
    } else if (typeof themeDefValue === 'object') {
      console.error(
        'No value found for key: ',
        themeDefKey,
        'themeDefValue',
        themeDefValue
      );
    }

    if (themeDefValue.isRoot) {
      const combinedThemeAtKey = combinedTheme[themeDefKey] || {};
      cssVarsInternal(combinedThemeAtKey, cssVars, themeDefValue as any);
    }
  });
};

const existsAndIsObj = (testObj) =>
  testObj !== undefined && typeof testObj === 'object';
const isObj = (testObj) => testObj !== undefined && typeof testObj === 'object';

const combineTwoThemes = (
  combinedTheme,
  newTheme,
  themeDef,
  firstLayer = false
) => {
  const overlappingKeys = Object.keys(newTheme).filter((themeKey) =>
    combinedTheme.hasOwnProperty(themeKey)
  );

  // console.log(
  //   'combinedTheme',
  //   combinedTheme,
  //   'newTheme',
  //   newTheme,
  // );
  const newCombinedTheme = {
    ...combinedTheme,
    ...newTheme,
  };

  // If the key exists in source, and destination, and either:
  //  - This is the first layer of theme combining (eg: all have default, darkMode, etc)
  //  - The theme key isRoot
  overlappingKeys.forEach((themeKey) => {
    // Optional line
    const isThemeDefRoot = themeDef[themeKey]?.isRoot || firstLayer;
    const combinedIsObj = isObj(combinedTheme[themeKey]);
    const newIsObj = isObj(newTheme[themeKey]);

    if (isThemeDefRoot && combinedIsObj && newIsObj) {
      const subThemeDef = firstLayer ? themeDef : themeDef[themeKey];
      newCombinedTheme[themeKey] = combineTwoThemes(
        combinedTheme[themeKey],
        newTheme[themeKey],
        subThemeDef
      );
    }
    // else if (combinedIsObj) {
    //   // newCombinedTheme[themeKey] = { ...combinedTheme[themeKey] };
    // } else if (newIsObj) {
    //   // newCombinedTheme[themeKey] = { ...newTheme[themeKey] };
    // }
  });

  return newCombinedTheme;
};

// Do a deep merge of themes assuming, but only deep when isRoot is defined
export const combineThemes = (themes, firstLayer = true) => {
  let combinedThemes = themes[0];

  if (themes.length > 0) {
    for (var i = 1; i < themes.length; ++i) {
      if (themes[i]) {
        combinedThemes = combineTwoThemes(
          combinedThemes,
          themes[i],
          themeDefinition,
          true
        );
      }
    }
  }
  return combinedThemes;
};

export const getCssVars = ({
  core,
  combinedVarDefs,
  theme,
  styleNames,
}: {
  core: CoreType;
  combinedVarDefs;
  theme;
  styleNames: string[];
}) => {
  // Filter out userVarTheme with the array of applicable style names
  // These are the themes that should be applied given the current
  // applied styleNames (eg: darkMode)
  const [applicableVarThemes, _] = core.getApplicableStyles(
    styleNames, // TODO: Add Style names here - is this working?
    theme || {},
    combinedVarDefs,
    null
  );

  // console.log('applicableVarThemes', applicableVarThemes);

  // TODO: Need to check priorities on this, eg:
  //  - default has .default value, and darkMode has .defaultVar
  //  - (or vice versa)
  //  - Pretty sure this is broken.
  const combinedApplicableVarTheme = combineThemes(
    applicableVarThemes.reverse(),
    false
  );
  // console.log('combinedApplicableVarTheme', combinedApplicableVarTheme);

  const cssVars = {};
  cssVarsInternal(combinedApplicableVarTheme, cssVars, themeDefinition);

  console.log('cssVars', cssVars);
  console.log('applicableVarThemes', applicableVarThemes);
  return cssVars;
};

const combineThemeVar = (themeVar, themeVarDef) => {
  let combinedThemeDef;
  if (typeof themeVar === 'object') {
    combinedThemeDef = { ...themeVarDef, ...themeVar };

    // Recursively merge if a key points to an object in both
    Object.keys(themeVar).forEach((key) => {
      if (existsAndIsObj(themeVarDef[key])) {
        combinedThemeDef[key] = combineThemeVar(
          themeVar[key],
          themeVarDef[key]
        );
      }
    });
  } else {
    combinedThemeDef = { ...themeVarDef, default: themeVar };
  }
  return combinedThemeDef;
};

/**
 * Gets the full var definition (including the theme structure definition file)
 * from the theme overrides / definitions and the path to it. It only merges at depth one
 * @param combinedVarDefs The combined var definitions, everything except the pure definition file
 * @param path The path to through combinedVarDefs that we want to combine with pure def file
 */
export const getFullVarDefinition = (combinedVarDefs, path) => {
  const [styleNames, ...restOfPath] = path;

  const themeVar = _get(combinedVarDefs, path);
  const themeVarDef = _get(themeDefinition, restOfPath);

  return combineThemeVar(themeVar, themeVarDef);
};
