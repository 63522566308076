// WARNING - THIS IS HORRENDOUSLY BROKEN - but should work for now
// If you ever want to use this as an actual side effect callback
// you MUST fix it in subscriber.
// Currently this is just a placeholder for the "right" place
// this code should live, even though the project doesn't actually need it
import { core } from 'supportchef-sdk';
import log from 'lib/logging';
let componentLibrariesLoaded = false;
//
// Side effects when a projectVersion changes
// The main thing this performs is it loads any component libraries
// specified by the projectVersion
// Warning - this should also take a context, so it can publish it's own
//           redux update that the libraries are all loaded, or failed
//           to load. (when they are)
//           The API also needs a way to "unload" a library.
//
export default (projectVer, oldProjectVer) => {
  if (!componentLibrariesLoaded && projectVer) {
    log.debug('component library loading');
    import('supportchef-sdk-core-components-editor').then(
      (coreEditorComponents) => {
        coreEditorComponents.importComponents(core);
        log.debug('component library loaded');
      }
    );
    componentLibrariesLoaded = true;
  }
};
