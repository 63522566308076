import { ImageComponent } from './ImageComponent';
import registerBaseImageComponent from './BaseImageComponent';
import * as ImageStyles from './ImageStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  registerBaseImageComponent(coreObj);
  coreObj.registerComponent(ImageComponent);
  coreObj.registerStyles(ImageStyles);
});

export * from './ImageComponent';
export * from './BaseImageComponent';
export { ImageStyles };
