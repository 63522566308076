import {
  // Questions Node
  ADD_FORM_ENTRY,
  REORDER_FORM_ENTRY,
  DELETE_FORM_ENTRY,
  MODIFY_FORM_ENTRY,
  MOVE_NODE_END,
  MOVE_NODE,
  SET_NODE_NAME,
  SET_NODE_PAGE_STYLE,
  SET_NODE_NAME_IS_TITLE,
  MODIFY_FORM_ENTRY_AT_PATH,
  MODIFY_NODE,
  // Entry Node
  SET_DEFAULT_ENTRY_NODE,
  SET_NAME_ENTRY_NODE,
  UNSET_OUTPUT,
  SET_NAMED_OUTPUT_FOR_NODE_ENTRY,
  SET_IF_OUTPUT_DEFINED_NODE_ENTRY,
  SET_LOCAL_VARIABLE_ENTRY,
  ADD_ENTRY_NODE_INPUT,
  UPDATE_ENTRY_NODE_INPUT,
  DELETE_ENTRY_NODE_INPUT,
  // Action Node
  SET_ACTION_NODE_ACTION_TYPE,
  ADD_ACTION_NODE_OUTPUT,
  UPDATE_ACTION_NODE_OUTPUT,
  DELETE_ACTION_NODE_OUTPUT,
  UPDATE_ACTION_NODE_METADATA,
  // Conditional Node
  SET_CONDITIONAL_MODE,
  ADD_CONDITIONAL_ENTRY,
  REORDER_CONDITIONAL_ENTRY,
  DELETE_CONDITIONAL_ENTRY,
  MODIFY_CONDITIONAL_ENTRY,
  MODIFY_CONDITIONAL_ENTRY_AT_PATH,
  // Node/Var References
  ADD_REFERENCE,
  UPDATE_REFERENCE,
  DELETE_REFERENCE,
} from '../actions/types';
import _set from 'lodash/set';
import _get from 'lodash/get';
// import log from 'lib/logging';
import { generateVariable } from './variableHelpers';
import { cloneDeepToPath } from '../../lib/utils';

// This is only included so that Map's are properly
// rendered in Redux Dev Tools - we could remove this for prod.
require('map.prototype.tojson');

export function isNodeContentDetailsType(actionType) {
  return (
    // Questions Node
    actionType === MODIFY_FORM_ENTRY ||
    actionType === MODIFY_FORM_ENTRY_AT_PATH ||
    actionType === UNSET_OUTPUT ||
    actionType === SET_NAMED_OUTPUT_FOR_NODE_ENTRY ||
    actionType === SET_IF_OUTPUT_DEFINED_NODE_ENTRY ||
    actionType === SET_LOCAL_VARIABLE_ENTRY ||
    // Conditional Node
    actionType === MODIFY_CONDITIONAL_ENTRY ||
    actionType === MODIFY_CONDITIONAL_ENTRY_AT_PATH
  );
}

export function isNodeContentActionType(actionType) {
  return (
    // Questions Node
    actionType === ADD_FORM_ENTRY ||
    actionType === REORDER_FORM_ENTRY ||
    actionType === DELETE_FORM_ENTRY ||
    // Conditional Node
    actionType === ADD_CONDITIONAL_ENTRY ||
    actionType === REORDER_CONDITIONAL_ENTRY ||
    actionType === DELETE_CONDITIONAL_ENTRY ||
    isNodeContentDetailsType(actionType)
  );
}

export function isNodeMetadataActionType(actionType) {
  return (
    actionType === SET_DEFAULT_ENTRY_NODE ||
    actionType === SET_NAME_ENTRY_NODE ||
    actionType === ADD_ENTRY_NODE_INPUT ||
    actionType === UPDATE_ENTRY_NODE_INPUT ||
    actionType === DELETE_ENTRY_NODE_INPUT ||
    actionType === SET_ACTION_NODE_ACTION_TYPE ||
    actionType === ADD_ACTION_NODE_OUTPUT ||
    actionType === UPDATE_ACTION_NODE_OUTPUT ||
    actionType === DELETE_ACTION_NODE_OUTPUT ||
    actionType === UPDATE_ACTION_NODE_METADATA ||
    actionType === SET_CONDITIONAL_MODE ||
    isReferenceActionType(actionType)
  );
}

export function isNodeActionType(actionType) {
  return (
    actionType === MOVE_NODE ||
    actionType === MOVE_NODE_END ||
    actionType === SET_NODE_NAME ||
    actionType === SET_NODE_PAGE_STYLE ||
    actionType === SET_NODE_NAME_IS_TITLE ||
    actionType === MODIFY_NODE ||
    isNodeContentActionType(actionType) ||
    isNodeMetadataActionType(actionType)
  );
}

export function isReferenceActionType(actionType) {
  return (
    actionType === ADD_REFERENCE ||
    actionType === UPDATE_REFERENCE ||
    actionType === DELETE_REFERENCE
  );
}

export const initialContents = new Map();

const contentEntryInitialState = {
  config: {},
};

export function contentEntryToMapTuple(contentEntry) {
  return [contentEntry.id, { ...contentEntryInitialState, ...contentEntry }];
}

function getContentEntryKey(action) {
  return (
    action.contentEntryId || (action.contentEntry && action.contentEntry.id)
  );
}

// function formEntriesArrayToMap(formEntries) {
//   return formEntries.map(contentEntryToMapTuple);
// }

export function serializeNode(node) {
  // if node.allFormEntries
  return { ...node, contents: [...node.contents] };
}

export function deserializeNode(node) {
  if (node && node.contents && Array.isArray(node.contents)) {
    node.contents = new Map(node.contents);
  }
  return node;
}

// export function serializeSafeFormObject(formObject) {
//   // if formObject.allFormEntries
//   return { ...formObject, allFormEntries: [...formObject.allFormEntries] };
// }
// export function deserializeFormObject(formObject) {
//   if (
//     formObject &&
//     formObject.allFormEntries &&
//     Array.isArray(formObject.allFormEntries)
//   ) {
//     formObject.allFormEntries = new Map(formObject.allFormEntries);
//   }
//   return formObject;
// }

const contentEntryReducer = (contentEntry, action) => {
  switch (action.type) {
    case MODIFY_FORM_ENTRY:
    case MODIFY_CONDITIONAL_ENTRY:
      return {
        ...contentEntry,
        ...action.entry,
      };
    case MODIFY_FORM_ENTRY_AT_PATH:
    case MODIFY_CONDITIONAL_ENTRY_AT_PATH:
      const toModify = _get(contentEntry, action.modifyPath);
      let modifiedEntry;
      if (
        typeof action.entry === 'object' &&
        action.entry !== null &&
        !Array.isArray(action.entry)
      ) {
        modifiedEntry = { ...toModify, ...action.entry };
      } else {
        modifiedEntry = action.entry;
      }

      const clonedEntry = cloneDeepToPath(contentEntry, action.modifyPath);

      _set(clonedEntry, action.modifyPath, modifiedEntry);

      return clonedEntry;
    case UNSET_OUTPUT:
      return {
        ...contentEntry,
        config: {
          ...contentEntry.config,
          output: {},
        },
      };
    case SET_NAMED_OUTPUT_FOR_NODE_ENTRY:
      return {
        ...contentEntry,
        config: {
          ...contentEntry.config,
          output: {
            ...contentEntry.config.output,
            alias: action.variableId,
            variable: undefined,
          },
        },
      };
    case SET_IF_OUTPUT_DEFINED_NODE_ENTRY:
      return {
        ...contentEntry,
        config: {
          ...contentEntry.config,
          output: {
            ...contentEntry.config.output,
            ifDefined: action.ifDefined,
          },
        },
      };
    case SET_LOCAL_VARIABLE_ENTRY:
      const currentVar = contentEntry.config.output.variable || {};
      return {
        ...contentEntry,
        config: {
          ...contentEntry.config,
          output: {
            ...contentEntry.config.output,
            variable: generateVariable(action.variable, currentVar),
            alias: undefined,
          },
        },
      };
    default:
      return contentEntry;
  }
};

export const nodeContentReducer = (contents = initialContents, action) => {
  const contentId = getContentEntryKey(action);
  switch (action.type) {
    //
    // Question/Conditional Node Content Actions
    //
    case ADD_FORM_ENTRY:
    case ADD_CONDITIONAL_ENTRY:
      const newContents = Array.from(contents);
      newContents.splice(
        action.index,
        0,
        contentEntryToMapTuple(action.contentEntry)
      );
      return new Map(newContents);
    case REORDER_FORM_ENTRY:
    case REORDER_CONDITIONAL_ENTRY:
      const reorderedContents = Array.from(contents);
      const itemToAdd = reorderedContents.splice(action.removedIndex, 1)[0];
      reorderedContents.splice(action.index, 0, itemToAdd);
      return new Map(reorderedContents);
    case DELETE_FORM_ENTRY:
    case DELETE_CONDITIONAL_ENTRY:
      return new Map(
        Array.from(contents).filter(([key, item]) => key !== contentId)
      );
    default:
      if (isNodeContentDetailsType(action.type)) {
        let updatedForm = contentEntryReducer(contents.get(contentId), action);
        return new Map([...contents, contentEntryToMapTuple(updatedForm)]);
      }
      return contents;
  }
};

export const nodeMetadataReducer = (metadata = {}, action) => {
  switch (action.type) {
    //
    // Entry Node Actions
    //
    case SET_DEFAULT_ENTRY_NODE:
      return { ...metadata, isDefault: action.isDefault };
    case SET_NAME_ENTRY_NODE:
      return { ...metadata, name: action.name };
    case ADD_ENTRY_NODE_INPUT:
      return {
        ...metadata,
        inputs: [
          ...(metadata.inputs || []),
          {
            id: action.input.id,
            output: action.input.output,
            name: action.input.name,
          },
        ],
      };
    case UPDATE_ENTRY_NODE_INPUT:
      const newInputs = metadata.inputs.map((input) => {
        if (input.id !== action.input.id) {
          return input;
        }
        const name = action.input.name === '' ? null : action.input.name;
        return {
          id: action.input.id,
          name: name === undefined ? input.name : name,
          output: action.input.output || input.output,
        };
      });
      return {
        ...metadata,
        inputs: newInputs,
      };
    case DELETE_ENTRY_NODE_INPUT:
      return {
        ...metadata,
        inputs: metadata.inputs.filter((input) => input.id !== action.inputId),
      };
    //
    // Action Node Actions
    //
    case SET_ACTION_NODE_ACTION_TYPE:
      return {
        ...metadata,
        actionType: action.actionType,
        showLoading: action.showLoading,
        local: action.local,
      };
    case ADD_ACTION_NODE_OUTPUT:
      return {
        ...metadata,
        outputs: [
          ...(metadata.outputs || []),
          {
            id: action.output.id,
            source: action.output.source,
            name: action.output.name,
          },
        ],
      };
    case UPDATE_ACTION_NODE_OUTPUT:
      const newOutputs = metadata.outputs.map((output) => {
        if (output.id !== action.output.id) {
          return output;
        }
        const name = action.output.name === '' ? null : action.output.name;
        return {
          id: action.output.id,
          name: name === undefined ? output.name : name,
          source: action.output.source || output.source,
        };
      });
      return {
        ...metadata,
        outputs: newOutputs,
      };
    case DELETE_ACTION_NODE_OUTPUT:
      return {
        ...metadata,
        outputs: metadata.outputs.filter(
          (output) => output.id !== action.outputId
        ),
      };

    case UPDATE_ACTION_NODE_METADATA:
      return {
        ...metadata,
        ...action.metadata,
      };
    //
    // Conditional Node Actions
    //
    case SET_CONDITIONAL_MODE:
      return {
        ...metadata,
        mode: action.mode,
      };
    default:
      if (isReferenceActionType(action.type)) {
        return {
          ...metadata,
          references: referenceReducer(metadata.references || {}, action),
        };
      }
      return metadata;
  }
};

/*
 * References are a node's references to variables
 * Often used for input/output to the graph, e.g. entry/action nodes
 * Found under metadata.references.<context> as an array of objects
 * Entry/Action node references will have values for id, source, and target
 */
const referenceReducer = (references = {}, action) => {
  // Let's us assume that context is already in references
  if (!(action.context in references)) {
    references[action.context] = [];
  }

  switch (action.type) {
    case ADD_REFERENCE:
      return {
        ...references,
        [action.context]: [
          ...references[action.context],
          {
            ...action.entry,
          },
        ],
      };
    case UPDATE_REFERENCE:
      return {
        ...references,
        // Find the entry with matching id
        [action.context]: references[action.context].map((entry) => {
          if (entry.id !== action.entry.id) {
            return entry;
          }
          // Replace all '' with null because of dynamoDB
          const e = { ...action.entry };
          Object.keys(e).forEach(
            (key) => (e[key] = e[key] === '' ? null : e[key])
          );
          // Update the passed values
          return {
            ...entry,
            ...e,
          };
        }),
      };
    case DELETE_REFERENCE:
      return {
        ...references,
        [action.context]: references[action.context].filter(
          (entry) => entry.id !== action.entry.id
        ),
      };
    default:
      return references;
  }
};

export const nodeReducer = (node, action) => {
  switch (action.type) {
    case MOVE_NODE_END:
    case MOVE_NODE:
      return {
        ...node,
        x: action.x,
        y: action.y,
      };
    case MODIFY_NODE:
      // log.info(node, action);
      return {
        ...node,
        ...action.nodeChanges,
      };
    case SET_NODE_NAME:
      return {
        ...node,
        name: action.name,
      };
    case SET_NODE_PAGE_STYLE:
      return {
        ...node,
        pageStyle: action.pageStyle,
      };
    case SET_NODE_NAME_IS_TITLE:
      return {
        ...node,
        nameIsTitle: action.nameIsTitle,
      };
    default:
      if (isNodeContentActionType(action.type)) {
        return {
          ...node,
          contents: nodeContentReducer(node.contents, action),
        };
      } else if (isNodeMetadataActionType(action.type)) {
        return {
          ...node,
          metadata: nodeMetadataReducer(node.metadata, action),
        };
      }
      return node;
  }
};
