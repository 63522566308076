import * as themes from './themes/index';
import * as editor from './editor/index';
import * as consts from './consts';
import * as componentFactory from './ComponentFactory';

const coreObj = {
  ...themes,
  ...editor,
  ...consts,
  ...componentFactory,
};

coreObj.withThemedComponent = themes.withThemedComponent.bind(coreObj);
coreObj.registerStyledComponent = componentFactory.registerStyledComponent.bind(
  coreObj
);

export type CoreType = typeof coreObj;
export default coreObj;
