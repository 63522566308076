/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import {
  PUBLIC_IOT_POLICY_ATTACHED,
  PUBLIC_IOT_POLICY_ATTACHING,
  PUBLIC_IOT_POLICY_ERROR,
  LOGOUT,
  DEVICE_CONNECTED_STATUS_CHANGED,
  MESSAGE_HANDLER_ATTACHED,
} from '../actions/types';

export const initialState = {
  publicIotPolicyAttaching: false,
  publicIotPolicyAttached: false,
  attachedAccounts: {},
  deviceConnected: false,
  messageHandlerAttached: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_IOT_POLICY_ATTACHED:
      const attachedAccount = state.attachedAccounts[action.accountId] || {};
      return {
        ...state,
        publicIotPolicyAttaching: false,
        publicIotPolicyAttached: true,
        attachedAccounts: {
          ...state.attachedAccounts,
          [action.accountId]: {
            ...attachedAccount,
            stages: [...action.stages, ...(attachedAccount.stages || [])],
            fetchedAll:
              action.fetchedAll === undefined ? false : action.fetchedAll,
          },
        },
      };
    case PUBLIC_IOT_POLICY_ATTACHING:
      return {
        ...state,
        publicIotPolicyAttaching: true,
      };
    case PUBLIC_IOT_POLICY_ERROR:
      return {
        ...state,
        publicIotPolicyAttaching: false,
        error: action.error,
      };
    case DEVICE_CONNECTED_STATUS_CHANGED:
      return {
        ...state,
        deviceConnected: action.deviceConnected,
      };
    case MESSAGE_HANDLER_ATTACHED:
      return {
        ...state,
        messageHandlerAttached: action.attached,
      };
    case LOGOUT:
      return {
        ...initialState,
        messageHandlerAttached: state.messageHandlerAttached,
        deviceConnected: state.deviceConnected, // Leave this as connected to use same mqtt client
      };
    default:
      return state;
  }
};
