/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import {
  SubComponentPropsInterface,
  VariableAccessor,
  StyledComponentType,
} from 'supportchef-sdk-core';
import {
  BaseMultipleChoiceComponent,
  BaseMultipleChoiceProps,
} from './BaseMultipleChoiceComponent';
import { BaseTextComponent, BaseTextProps } from '../Text/BaseTextComponent';

export interface MultipleChoiceProps extends SubComponentPropsInterface {
  isFocused: any;
  adjoined: any;
  error: any;
  disabled: any;
  size: any;
  theme: any;
  children: any;
  placeholder?: string;
  elements?: any;
  inputType?: string;
  varname: string;
}

export interface MultipleChoiceState {
  value: string;
  newEntry: any;
  multipleChoiceKey: string;
}

const defaultValues = {
  text: 'Placeholder Text',
};

export class MultipleChoiceComponent extends React.Component<
  MultipleChoiceProps,
  MultipleChoiceState
> {
  static componentName = 'MultipleChoiceComponent';
  static variableDefs = [{ types: ['string'] }];

  MultipleChoice: StyledComponentType<
    BaseMultipleChoiceComponent,
    BaseMultipleChoiceProps
  >;
  Text: StyledComponentType<BaseTextComponent, BaseTextProps>;

  private varstore: VariableAccessor;
  constructor(props: MultipleChoiceProps) {
    super(props);
    const isMulti = ['checkbox'].includes(this.props.inputType);
    this.varstore = props.utils.getVariableAccessor(props, isMulti);

    // This must be unique for this multiple choice
    this.state = {
      value: '',
      newEntry: { text: '' },
      multipleChoiceKey: Math.random().toString(36),
    };

    this.MultipleChoice = props.coreObj.StyledComponentFactory<
      BaseMultipleChoiceComponent,
      BaseMultipleChoiceProps
    >(BaseMultipleChoiceComponent.componentName, props);

    this.Text = props.coreObj.StyledComponentFactory<
      BaseTextComponent,
      BaseTextProps
    >(BaseTextComponent.componentName, props);
  }

  componentDidMount() {
    this.varstore.listen((value) => {
      this.setState({ value: value || this.varstore.get() });
    });

    this.setState({ value: this.varstore.get() });
  }
  componentWillUnmount() {
    this.varstore.unmount();
  }

  onChangeElement = (elementIdx) => (evt) => {
    const { modifyFormEntryAtPath, path, isEditing } = this.props;
    const description = evt.target.value;

    if (isEditing) {
      console.log(path, description);
      const elementsPath = `${path}.elements[${elementIdx}]`;
      modifyFormEntryAtPath(elementsPath, { text: description || null });
    }

    // modifyCustom({ text });
  };

  onChangeSelection = (element, elementIdx) => (evt) => {
    switch (evt.target.type) {
      case 'checkbox':
        if (evt.target.checked) {
          this.varstore.add(element.text);
        } else {
          this.varstore.removeValue(element.text);
        }
        break;
      case 'radio':
      default:
        this.varstore.set(element.text);
        break;
    }
  };

  render() {
    const { isEditing, placeholder, elements, inputType } = this.props;
    const { value, newEntry, multipleChoiceKey } = this.state;
    const elementsAndNew = [...(elements || [])];
    if (isEditing) {
      elementsAndNew.push(newEntry);
    }
    // console.log('elementsAndNew', elementsAndNew);

    if (inputType === 'select' && !isEditing) {
      return (
        <select>
          {elementsAndNew.map((element, elementIdx) => (
            <option value={element.text} key={element.text}>
              {element.text}
            </option>
          ))}
        </select>
      );
    }

    return (
      <React.Fragment>
        {elementsAndNew.map((element, elementIdx) => (
          <this.MultipleChoice
            key={elementIdx}
            name={multipleChoiceKey}
            type={inputType || 'radio'}
            checked={
              !!(Array.isArray(value)
                ? value.includes(element.text)
                : value === element.text)
            }
            onChange={this.onChangeSelection(element, elementIdx)}
            {...this.props}
          >
            <this.Text
              onChange={this.onChangeElement(elementIdx)}
              element={element}
              className="text"
              isEditing={isEditing}
            />
          </this.MultipleChoice>
        ))}
      </React.Fragment>
    );
  }
}
