// "Compile" time debug level
const debugLogging = process.env.NODE_ENV !== 'production';
const infoLogging = process.env.NODE_ENV !== 'production';
const prefix = 'SC: ';

// Only create empty function once / readability
const noop = () => {};

// This is a clever use of binding taken from here
// https://stackoverflow.com/a/32928812
// This allows debugLogging to get the correct line number
// (otherwise it's hard to figure out where the log statement is in the console)
export default {
  debug: debugLogging
    ? window.console.log.bind(window.console, `%c${prefix}%O`, 'color:#b7b7b7;')
    : noop,
  info: infoLogging
    ? window.console.log.bind(window.console, `%c${prefix}%O`, 'color:#2dccff;')
    : noop,
  error: window.console.error.bind(
    window.console,
    `%c${prefix}%O`,
    'color:red;'
  ),
  debugGroup: debugLogging
    ? window.console.groupCollapsed.bind(
        window.console,
        `%c${prefix}%s`,
        'color:#b7b7b7;'
      )
    : noop,
  infoGroup: infoLogging
    ? window.console.groupCollapsed.bind(
        window.console,
        `%c${prefix}%s`,
        'color:#2dccff;'
      )
    : noop,
  debugGroupEnd: debugLogging ? window.console.groupEnd : noop,
  infoGroupEnd: infoLogging ? window.console.groupEnd : noop,
};
