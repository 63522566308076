/**
 * @class Project
 * SDK Main entrance point
 */

import React, { Component, ReactNode } from 'react';
import * as Persistence from '/lib/Persistence';
import { parseProjectGraph } from '/lib/shared/graphUtils';
import { TraverserState, GraphTraverser } from '/GraphTraverser';
import VariableStore from '/Variables/VariableStore';
import { DebugButton } from '/BuiltInComponents/DebugComponents/DebugButton';
import { withProjectContext } from '/lib/ProjectContext';
import { ThemeProvider, getThemeInfoFromProject } from './themes/index';
import {
  callbackToMobileSdk,
  sdkCallbackTopics,
  promiseFilesystemAccess,
  filesystemTopics,
} from '/lib/mobileCallback';
import {
  downloadProjectVerIfNeeded,
  downloadNewSdkIfNeeded,
} from '/lib/filesystemManager';
import { clearInternalFetchingCache } from '/lib/payloadViaJs';
import core, {
  globalStyleCategories,
  styleCategoryKey,
} from 'supportchef-sdk-core';
import * as coreComponents from 'supportchef-sdk-core-components';
import { getPublicProjectIfNeeded } from '/lib/fetchProject';
import memoizeOne from 'memoize-one';
coreComponents.importComponents(core);
import { PrivateProjectProps } from '/ProjectProps';

interface State {
  projectState?: ProjectState;
  // lastUpdate: number;
}

class ProjectUnwrapped extends Component<PrivateProjectProps, State> {
  constructor(props: PrivateProjectProps) {
    super(props);
    this.state = {
      projectState: null,
      // lastUpdate: Date.now(),
    };
  }

  /**
   * React lifecycle that can be added as optimization later
   */
  shouldComponentUpdate(nextProps, nextState): boolean {
    return true;
  }

  componentDidMount() {
    this.updateProjectStateIfNeeded();
  }

  componentDidUpdate() {
    this.updateProjectStateIfNeeded();
  }

  styleCategoriesChanged = (args, oldArgs) => {
    const props = args[0] as PrivateProjectProps;
    const prevProps = oldArgs[0] as PrivateProjectProps;
    return props.hiddenFields?.darkMode !== prevProps.hiddenFields?.darkMode;
  };

  generateStyleCategories = memoizeOne((props: PrivateProjectProps) => {
    const cats = [];

    if (props.hiddenFields?.darkMode) {
      cats.push(styleCategoryKey(globalStyleCategories.dark));
    }
    return cats;
  }, this.styleCategoriesChanged);

  getStyleCategories = () => {
    return this.generateStyleCategories(this.props);
  };

  /**
   * Generates a ProjectState based on the current props
   * We memoize it so that it doesn't have to be recreated
   * unless one of the passed props changes.
   * @return {ProjectState}
   */
  generateProjectState = memoizeOne((path, forceDebug, projectObj) => {
    const { hiddenFields, options, vars } = this.props;
    const projState = ProjectState.createProjectState(
      path,
      this.update,
      hiddenFields,
      projectObj,
      options,
      vars
    );
    console.log('Project state on init:', projState);
    return projState;
  });

  updateProjectStateIfNeeded = () => {
    const { options, projectObject } = this.props;
    const projectState = this.generateProjectState(
      this.path(),
      options?.forceDebug,
      projectObject
    );

    if (this.state.projectState !== projectState) {
      this.setState({ projectState });
    }
  };

  /**
   * Returns the actual path given props (assumes current props if none specified)
   * @return {String} Path to project
   */
  path() {
    return this.props.path;
  }

  /**
   * Callback to force an update on this project - managed by ProjectState
   */
  update = () => {
    // this.setState({ lastUpdate: Date.now() });
    this.forceUpdate();
  };

  render = () => {
    const { options, vars, hiddenFields, path, onEnd } = this.props;
    const { projectState } = this.state;

    const styleCategories = this.getStyleCategories();

    if (!projectState || !projectState.project) {
      return this.props.loading;
    }
    const project = projectState.project;
    const debugObject = projectState.debug;
    // This is forcing an update within render - whole operational model needs to be fixed
    const session = projectState.getSession(options?.entryNode, vars, options);
    const theme = getThemeInfoFromProject(project.projectVer);
    // console.info('project state on render:', projectState);
    // console.log('Theme being rendered inside project: ', project, theme);

    return (
      <React.Fragment>
        {session && (
          <ThemeProvider
            theme={theme}
            projectProps={this.props}
            styleCategories={styleCategories}
          >
            <GraphTraverser
              traverserState={session}
              hiddenFields={hiddenFields}
              options={options}
              vars={vars}
              onEnd={onEnd}
            />
          </ThemeProvider>
        )}
        {!session && (
          <React.Fragment>
            {!projectState.error && <div> Loading project...</div>}
            {projectState.error && (
              <div>
                Incomplete project path: {path}
                <br />
              </div>
            )}
          </React.Fragment>
        )}
        {debugObject && (
          <DebugButton
            reason={debugObject.reason}
            versions={debugObject.versions}
            projectVer={projectState.project.projectVer}
            setVersion={(version) =>
              projectState.setVersion(version, options, hiddenFields, vars)
            }
          />
        )}
      </React.Fragment>
    );
  };
}

export const Project = withProjectContext(ProjectUnwrapped);

interface LoadJson {
  sdkLoad?: String;
  versionLoad?: String;
}

export class ProjectState {
  static projectMap: {};

  static createProjectState(
    path: string,
    update: Function,
    hiddenFields = {},
    projectObj = undefined,
    options,
    vars
  ): ProjectState {
    // We must have a project path in order to load the project
    if (!path) {
      console.error('Project path not defined');
      return undefined;
    }
    const pm = ProjectState.projectMap;

    pm[path] = new ProjectState(
      path,
      update,
      hiddenFields,
      projectObj,
      options,
      vars
    );
    return pm[path];
  }

  static getProjectState(path: string): ProjectState {
    return ProjectState.projectMap[path];
  }

  project: any;
  debug: any;
  entryNodes: any;
  defaultEntry: any;
  error: any;
  path: string;
  protected fetchOptions: any;
  protected modified: number;
  update: Function;
  protected projectObj;
  callbackToSdk: Function;
  protected loadJson?: LoadJson;

  activeSessions: { [key: string]: TraverserState }; // {string: TraverserState}

  constructor(
    path: string,
    update: Function,
    hiddenFields,
    projectObj,
    options,
    vars
  ) {
    this.path = path;
    this.update = update;
    this.activeSessions = {};
    this.fetchOptions = null;
    this.ts();
    this.projectObj = projectObj;
    this.callbackToSdk = hiddenFields?.sdkCallback;
    this.loadJson =
      hiddenFields?.loadJson && (JSON.parse(hiddenFields.loadJson) as LoadJson);
    this.getProjectFromServer(options, hiddenFields, vars);
  }

  isClearToRefresh = () => {
    console.log('activeSessions', this.activeSessions);
    return true;
    // return Object.keys(this.activeSessions.length).length === 0;
  };

  /**
   * This is called when we are expecting a response for a new version to be given
   * as a tarball download.
   * The expected format looks like:
   * {
   *     "debug": {
   *         "reason": "Your code specifies that debug mode is always on",
   *         "versions": [{"name": "Initial Version","version": "0.0.1"}]
   *     },
   *     "projectVerInfo": {
   *         "targz": "https://seandev-api.dev.supportchef.com/api/v1/public/projects/a-26ff5065-d984-4abc-893e-31c4013040de-SibBq$prod$p-lEwy6/versions/0.0.1/bundle",
   *         "version": "0.0.1",
   *         "engineVerMatch": "^0.0.1",
   *         "isPublished": true/false/undefined,
   *         "sdk": {
   *             "version": "0.0.1",
   *             "targz": "https://seandev-api.dev.supportchef.com/public/engine/version/0.0.1/platform/native"
   * }   }   }
   */
  updateInBackground = async (response) => {
    // console.log('New version from background update: ', response);
    const { debug, projectVerInfo, varsUsed } = response;
    if (debug) {
      this.updateDebug(debug);
    }

    // These are variables that were used to generate this project/payload
    // if they change, then we need to refetch the project info
    if (varsUsed) {
    }

    const cb = this.callbackToSdk;
    if (projectVerInfo && cb) {
      const downloads = [];

      try {
        // Download Project Ver
        downloads.push(
          downloadProjectVerIfNeeded(
            this.path as any,
            projectVerInfo,
            this.loadJson,
            cb as any
          )
        );
        // Download SDK
        if (projectVerInfo.sdk) {
          downloads.push(
            downloadNewSdkIfNeeded(projectVerInfo.sdk, this.loadJson, cb as any)
          );
        }
        const [projVerRelPath, sdkRelPath] = await Promise.all(downloads);

        // If we have new projectVer or SDK, change it
        if (projVerRelPath || sdkRelPath) {
          const loadJson: LoadJson = {};
          if (sdkRelPath) {
            loadJson.sdkLoad = sdkRelPath;
          }
          if (projVerRelPath) {
            loadJson.versionLoad = projVerRelPath;
          }
          await promiseFilesystemAccess(
            filesystemTopics.updateLoadJson as any,
            { loadJson } as any,
            cb as any
          );

          // Refresh the project with the new sdk + project version
          if (this.isClearToRefresh()) {
            console.log('Relaunching project');

            // If we are refreshing, make sure we don't cache any
            // projects that were fetched
            clearInternalFetchingCache();

            callbackToMobileSdk(
              sdkCallbackTopics.reloadProject,
              { force: false },
              cb
            );
          }
        }
      } catch (exception) {
        console.log(
          'Failed to download new sdk and/or project version',
          exception
        );
      }
    }
  };

  updateDebug = (debug) => {
    this.debug = debug;
    this.update();
  };

  getProjectFromServer = async (
    options = {},
    hiddenFields = {},
    vars = {},
    forceRefresh = false
  ) => {
    let project, error;

    // If we have the full project graph specified, just use that
    if (this.projectObj) {
      project = { projectVer: this.projectObj };
    } else {
      const results = await getPublicProjectIfNeeded(
        this.path,
        this.fetchOptions,
        options,
        hiddenFields,
        vars,
        this.updateInBackground,
        forceRefresh
      );
      project = results.results;
      error = results.error;
    }
    this.handleProjectResponse(project, error);
  };

  handleProjectResponse(project, error) {
    if (project?.projectVer) {
      this.project = project;
      const { entryPoints, defaultEntryPoint } = parseProjectGraph(
        project.projectVer.diagram
        // This ALSO makes it so project.projectVer.diagram.nodeObjs[<nodeId>] -> js node object
      );
      this.defaultEntry = defaultEntryPoint;
      this.entryNodes = entryPoints;
      if (project.debug) {
        this.debug = project.debug;
      }
      this.ts();
      this.update();
    }
    if (error) {
      this.error = error;
      console.log(error);
    } else {
      this.error = undefined;
    }
  }

  getSession = (entryNodeName: string, vars, options): TraverserState => {
    if (!this.entryNodes) {
      return;
    }
    let entry = entryNodeName;
    // NOTE: Current SDK may send empty string in place of undefined for default
    if (!entry || !(entry in this.entryNodes)) {
      if (!this.defaultEntry) {
        return;
      }
      entry = this.defaultEntry.getName();
    }

    if (entry in this.activeSessions) {
      if (this.activeSessions[entry].expired) {
        delete this.activeSessions[entry];
      } else {
        return this.activeSessions[entry];
      }
    }

    const varStore = new VariableStore();
    // TODO: Import from cache.
    this.activeSessions[entry] = new TraverserState(
      this.entryNodes[entry],
      varStore,
      vars,
      options,
      this,
      entry
    );

    try {
      console.log(`Will attempt to load ${this.path}:${entry}`);
      // Commenting out persistence
      // TODO: come back to this
      Persistence.load(this.activeSessions[entry]);
    } catch (error) {
      console.error(error);
    }
    this.activeSessions[entry].initialized = true;

    return this.activeSessions[entry];
  };

  setFetchOptions = (key, value) => {
    if (!this.fetchOptions) {
      this.fetchOptions = {};
    }
    this.fetchOptions[key] = value;
  };

  // TODO: Change behaviors
  // get if version mismatch rather than forceversion mismatch
  // reset activesessions if version fetched correctly
  // then call update again
  setVersion = async (version: string, options, hiddenFields, vars) => {
    this.setFetchOptions('forceVersion', version);
    await this.getProjectFromServer(options, hiddenFields, vars, true);
    const callback = hiddenFields && hiddenFields.sdkCallback;
    callbackToMobileSdk(sdkCallbackTopics.goToBeginning, {}, callback);
    this.activeSessions = {};
    this.update();
  };

  matches = (other: ProjectState) => {
    this.path === other.path && this.modified === other.modified;
  };

  ts = () => (this.modified = Date.now());
}

ProjectState.projectMap = {};
