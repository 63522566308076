import { MultipleChoiceComponent } from './MultipleChoiceComponent';
import registerBaseMultipleChoiceComponent from './BaseMultipleChoiceComponent';
import * as MultipleChoiceStyles from './MultipleChoiceStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  registerBaseMultipleChoiceComponent(coreObj);
  coreObj.registerComponent(MultipleChoiceComponent);
  coreObj.registerStyles(MultipleChoiceStyles);
});

export * from './MultipleChoiceComponent';
export * from './BaseMultipleChoiceComponent';
export { MultipleChoiceStyles };
