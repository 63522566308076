import { SuccessSymbol } from './SuccessSymbol';
import { WarnSymbol } from './WarnSymbol';

export const keys = {
  successSymbol: 'successSymbol',
  warnSymbol: 'warnSymbol',
};

export default {
  [keys.successSymbol]: SuccessSymbol,
  [keys.warnSymbol]: WarnSymbol,
};
