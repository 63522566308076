import { promiseFilesystemAccess, filesystemTopics } from './mobileCallback';
import { unpackProjectPath } from './shared/projectUtils';
import { fetchNativeInfo, downloadMetaFile } from './staticFilesystemFiles';

async function partialPathToProjVer(
  { accountId, stage, projectId },
  isPublished = true
) {
  const pubString = isPublished ? 'published' : 'editing';
  const relPathToProject = `user/${accountId}/${stage}/${projectId}/${pubString}/versions/`;
  // console.log('Relative path to project', relPathToProject);
  return relPathToProject;
}

export async function getNamesOfFoldersAtPath(path, callbackToSdk) {
  const getSdksPayload = { path, depth: 0 };

  const response = await promiseFilesystemAccess(
    filesystemTopics.listFiles,
    getSdksPayload,
    callbackToSdk
  );
  const relPaths = response && response.relPaths;
  if (!relPaths) {
    return [];
  }

  // Get the last part of the path
  const lastPartOfPaths = relPaths.map((path) => {
    return path.split('/').pop();
  });

  console.log('last part of paths', lastPartOfPaths);
  return lastPartOfPaths;
}

export async function getAvailableEngines(callbackToSdk) {
  // Also include native version - need someway of denoting
  // native vs non=native
  const enginePathDict = {};
  const enginePath = 'engines/';

  // Include the included available engine
  const nativeInfo = await fetchNativeInfo();
  enginePathDict[nativeInfo.engineVer] = 'native/native-sdk';

  const downloadedSdks = await getNamesOfFoldersAtPath(
    enginePath,
    callbackToSdk
  );
  downloadedSdks.forEach((sdk) => {
    enginePathDict[sdk] = `${enginePath}${sdk}`;
  });

  return enginePathDict;
}

export async function getAvailableVersions(projPath, callbackToSdk) {
  const projectVersionsDict = {};

  const { details } = await unpackProjectPath(projPath);
  const { accountId, stage, projectId } = details;

  // Include native project version if it matches
  const nativeInfo = await fetchNativeInfo();
  if (
    nativeInfo.accountId === accountId &&
    nativeInfo.stage === stage &&
    nativeInfo.projectId === projectId
  ) {
    projectVersionsDict[nativeInfo.projectVersion] = 'native/projectVer';
  }

  const addProjectPathsToDict = async (isPublished) => {
    const relPathToProject = await partialPathToProjVer(
      {
        accountId,
        stage,
        projectId,
      },
      isPublished
    );

    const projectVersions = await getNamesOfFoldersAtPath(
      relPathToProject,
      callbackToSdk
    );

    projectVersions.forEach((projectVersion) => {
      projectVersionsDict[
        projectVersion
      ] = `${relPathToProject}${projectVersion}`;
    });
  };

  // First add the unpublished project versions
  // then add the published ones, that way published entries
  // always override unpublished entries
  await addProjectPathsToDict(false);
  await addProjectPathsToDict(true);

  return projectVersionsDict;
}

/**
 * [nativeHasProjectVer description]
 * @param  {[type]} projPath       [description]
 * @param  {[type]} projectVersion [description]
 * @param  {[type]} callbackToSdk  [description]
 * @return {[type]}                [description]
 */
export async function nativeHasProjectVer(
  projPath,
  projectVersion,
  callbackToSdk
) {
  const projectVers = await getAvailableVersions(projPath, callbackToSdk);
  return projectVers.includes(projectVersion);
}

// If we already have a version of the thing being requested
// (the local path exists), then:
//   - If the server says it needs a version newer than X:
//      - If downloaded version is older, refresh needed
//      - If downloaded has no timestamp, refresh needed
//   - If server doesn't specify timestamp, no refresh needed
async function isRedownloadNeeded(infoBundle, path) {
  const { oldestAllowedTs } = infoBundle;
  if (!oldestAllowedTs) {
    return false;
  }
  try {
    const { ts } = await downloadMetaFile(path);
    const redownloadNeeded = parseInt(ts) < oldestAllowedTs;
    console.info('Redownload Needed: ', redownloadNeeded);
    console.info(
      'ts:',
      ts,
      'oldestAllowedTs:',
      oldestAllowedTs,
      'dif',
      parseInt(ts) - oldestAllowedTs
    );
    return redownloadNeeded;
  } catch (error) {
    // console.info('error loading meta file: ', meta);
    // If the meta file isn't found, require redownload
    return true;
  }
}

/**
 * Returns the path of the engine to run, or throws an error if
 * the download failed.
 * @param  {[type]} sdkInfo       [description]
 * @param  {[type]} callbackToSdk [description]
 * @return {[type]}               [description]
 */
export async function downloadNewSdkIfNeeded(sdkInfo, loadJson, callbackToSdk) {
  const engineVersion = sdkInfo.version;
  const availableSdks = await getAvailableEngines(callbackToSdk);
  // If we already have the engine, don't download it again
  const foundPath = availableSdks[engineVersion];
  if (foundPath) {
    const redownload = await isRedownloadNeeded(sdkInfo, foundPath);
    if (!redownload) {
      // If this is the same version that's currently loaded
      // then don't reload it.
      if (loadJson.sdkLoad == foundPath) {
        return undefined;
      }
      return foundPath;
    }
  }

  const relativeLocation = `engines/${engineVersion}`;

  const downloadParams = {
    url: sdkInfo.targz,
    relativeLocation,
    isTarGz: true,
  };
  await promiseFilesystemAccess(
    filesystemTopics.download,
    downloadParams,
    callbackToSdk
  );
  return relativeLocation;
}

/**
 * [downloadProjectVerIfNeeded description]
 * @param  {[type]} projPath       [description]
 * @param  {[type]} projectVerInfo [description]
 * @param  {[type]} callbackToSdk  [description]
 * @return {[type]}                [description]
 */
export async function downloadProjectVerIfNeeded(
  projPath,
  projectVerInfo,
  loadJson,
  callbackToSdk
) {
  const projectVersion = projectVerInfo.version;
  const availableVersions = await getAvailableVersions(projPath, callbackToSdk);
  // If we already have the projectVersion, don't download it again
  const verFolder = availableVersions[projectVersion];
  if (verFolder) {
    const redownload = await isRedownloadNeeded(projectVerInfo, verFolder);
    console.log('Redownload needed: ', redownload);
    if (!redownload) {
      const fullPath = `${verFolder}/index.js`;
      // If this is the same version that's currently loaded
      // then don't reload it.
      if (loadJson.versionLoad == fullPath) {
        return undefined;
      }
      return fullPath;
    }
  }

  const { details } = await unpackProjectPath(projPath);
  const projVerPartialPath = await partialPathToProjVer(
    details,
    projectVerInfo.isPublished
  );
  const relativeLocation = `${projVerPartialPath}${projectVersion}`;

  console.log('download projectVer relative location: ', relativeLocation);
  const downloadParams = {
    url: projectVerInfo.targz,
    relativeLocation,
    isTarGz: true,
  };
  await promiseFilesystemAccess(
    filesystemTopics.download,
    downloadParams,
    callbackToSdk
  );

  const fullLocation = `${relativeLocation}/index.js`;
  return fullLocation;
}
