const getContainerRef = (props) => {
  const containerRef =
    props.graphTraverser && props.graphTraverser.containerRef;
  return (containerRef && containerRef.current) || { style: {} };
};

const getContainerPaddingLeft = (props) => {
  return getContainerRef(props).style.marginLeft;
};

const getContainerPaddingRight = (props) => {
  return getContainerRef(props).style.marginRight;
};

//***************************
// Property based transforms
//***************************

/**
 * Call syntax : (partStyle, attr, {props, fullStyle, selectors})
 * @param  {dict} partStyle - inside the given selector
 * @param  {dict} attr - the style attribute we're replacing (or selector)
 * @param  {dict} props - props from the calling component / theming system
 * @param  {dict} fullStyle - style at the root of this component (instead of selector)
 * @param  {array[string]} selectors - array of selectors into fullStyle
 *
 * @return {dict} - {style, classNames}
 *      style - style that will be merged inside the passed selector's style
 *      classNames - global class names that should be applied up the stack
 * /

/**
 * Sets the width to "full width". Primarily working around device safe-area-offsets.
 * Parameters: See above for descriptions
 */
export const fullWidth = (partStyle, attr, { props, fullStyle, selectors }) => {
  const classNames = ['fullWidth'];

  const paddingLeft = getContainerPaddingLeft(props) || '0px';
  const paddingRight = getContainerPaddingRight(props) || '0px';

  let insetLeft = `var(--safe-area-inset-left)`;
  let insetRight = `var(--safe-area-inset-right)`;
  if (props.graphTraverser?.props?.hiddenFields?.insetStyles) {
    const { insetStyles } = props.graphTraverser.props.hiddenFields;
    insetLeft = insetStyles.left + 'px';
    insetRight = insetStyles.right + 'px';
  }

  const partStyleLeft = partStyle.paddingLeft || '0px';
  const partStyleRight = partStyle.paddingRight || '0px';

  const style = {
    width: `calc(100% + ${paddingLeft} + ${paddingRight})`,
    '@supports (padding-left: env(safe-area-inset-left))': {
      // '--safe-area-inset-left': 'env(safe-area-inset-left)',
      // '--safe-area-inset-right': 'env(safe-area-inset-right)',
      width: `calc(100% + (${insetLeft} + ${insetRight}))`,
      marginLeft: `calc(${paddingLeft} - ${insetLeft})`,
      paddingLeft: `calc(${partStyleLeft} + ${insetLeft})`,
      paddingRight: `calc(${partStyleRight} + ${insetRight})`,
    },
  };

  return { style, classNames };
};

export const fixedBody = (partStyle, attr, { props }) => {
  const activeStyle = partStyle[attr];
  // supportchef-body-mock is used by the simulator
  let bodySelector = '.supportchef-body-mock, .supportChefRootStyling';

  // console.log(
  //   'hiddenFields inside fixed body - props',
  //   props.graphTraverser?.props?.hiddenFields,
  //   props
  // );

  // Only color the body if full screen specified
  if (props.graphTraverser?.props?.options?.isFullscreen) {
    bodySelector += ', body';
  }

  return {
    style: {
      [bodySelector]: activeStyle,
    },
    classNames: [],
  };
};

export const fixedActive = (partStyle, attr) => {
  const activeStyle = partStyle[attr];
  // We have an .active class because of a bug on iOS where if a view isn't
  // visible on load (going back on the screen) the :active pseudoselector
  // doesn't work. Fix is to manage .active selector with touch events.
  // We still want :active to work though, so we add it for desktop/android
  // TODO: Only add .active / do .active logic on iOS
  return {
    style: {
      ['&.active']: activeStyle,
      [':active']: activeStyle,
    },
    classNames: [],
  };
};

export const fixedLandscape = (partStyle, attr, { props }) => {
  const style = partStyle[attr];
  const classNames = [];

  if (props.graphTraverser?.props?.hiddenFields?.isLandscape) {
    return { style, classNames };
  }

  return {
    style: {
      ['@media only screen and (max-height: 400px)']: style,
    },
    classNames,
  };
};
