/** @jsx jsx */
import React from 'react';
import { jsx, css, Global } from '@emotion/core';
import memoize from 'memoize-one';
import { combineThemes, getCssVars } from '../ProcessTheme/themeUtils';
import { globalStyleFeatures } from '../ProcessTheme/globalStyleFeatures';
import sdkCore, {
  StyledComponentType,
  ComponentRegisterProps,
} from 'supportchef-sdk-core';
import {
  RootContainer,
  RootContainerProps,
} from 'supportchef-sdk-core-components';

export const rootClassName = 'supportChefRootStyling';

// Define outside of the ThemeProvider, so it's the same object (and key into WeakMap)
const emptyThemeObj = {};

export interface ThemeProviderProps extends ComponentRegisterProps {
  theme?: any;
  styleCategories?: any;
  noContainer?: boolean;
  projectProps?: any;
}
export interface ThemeProviderState {
  globalStyles: any;
  currentTheme: any;
}

const createCssVarWithStyleNamesFunc = (cssVars, getCssVarParams) => {
  const { styleNames } = getCssVarParams;
  const cssVarStyleNameMap = { [styleNames]: cssVars };

  const getCssVarWithStyleNames = (cssVar, styleNames) => {
    if (!cssVarStyleNameMap[styleNames]) {
      cssVarStyleNameMap[styleNames] = getCssVars({
        ...getCssVarParams,
        styleNames,
      });
    }
    return cssVarStyleNameMap[styleNames][cssVar];
  };

  return getCssVarWithStyleNames;
};

// In case some preprocessing needs to be done
// we sepatate this out into something that's weakly memoized
const getTheme = (theme, styleCategories) => {
  const safeTheme = theme || emptyThemeObj;
  const { varDefs, components } = safeTheme;

  // console.log('Theme from project:', safeTheme);
  // console.log('requiredVarThemes', requiredVarThemes);
  // Combine vars and components separately so we can weak map them if we want
  // This allows faster editing perf improvements when changing themes if we want
  const combinedVarDefs = combineThemes([sdkCore.requiredVarThemes, varDefs]);
  console.log('combinedVarDefs', combinedVarDefs);

  const getCssVarParams = {
    core: sdkCore,
    combinedVarDefs,
    theme: safeTheme,
    styleNames: styleCategories, //['default'], You shouldn't specify 'default', or else it'll duplicate it
  };
  const cssVars = getCssVars(getCssVarParams);
  const getCssVarWithStyleNames = createCssVarWithStyleNamesFunc(
    cssVars,
    getCssVarParams
  );

  return {
    theme: safeTheme,
    combinedVarDefs,
    cssVars,
    getCssVarWithStyleNames,
    styleCategories,
  };
};

export class ThemeProvider extends React.Component<
  ThemeProviderProps,
  ThemeProviderState
> {
  Container: any; //StyledComponentType<RootContainer, RootContainerProps>;
  memoizedGetTheme: any;

  constructor(props: ThemeProviderProps) {
    super(props);

    // Since we rely on equality checks of memoize to determine if we
    // need to re-render, it is _essential_ that if using memoize-one
    // we scope it to this class, otherwise we can subject ourselves to
    // infinite loops if there are multiple ThemeProviders.
    // Perhaps the correct solution is to use a better memoize function,
    // the emotion-weak-memoize doesn't work since it only takes a single
    // argument and we require two, therefore would require some reworking.
    this.memoizedGetTheme = memoize(getTheme);

    const currentTheme = this.getCurrentTheme();
    const globalStyles = this.createGlobalStyles(currentTheme);
    this.state = {
      globalStyles,
      currentTheme,
    };

    this.Container = sdkCore.StyledComponentFactory<
      RootContainer,
      RootContainerProps
    >(RootContainer.componentName, props);
  }

  componentDidUpdate(prevProps) {
    const projProps = this.props.projectProps || {};
    const prevProjProps = prevProps.projectProps || {};
    let { currentTheme } = this.state;
    const prevTheme = currentTheme;

    // First check if we need to update the theme.
    currentTheme = this.getCurrentTheme();

    // Next see if any of the globalStyleFeatures dictate
    // that we must update
    const needsUpdating = globalStyleFeatures.some((elem) =>
      elem.needsUpdate(projProps, prevProjProps, currentTheme, prevTheme)
    );

    if (needsUpdating || currentTheme !== prevTheme) {
      const globalStyles = this.createGlobalStyles(currentTheme);
      this.setState({ globalStyles, currentTheme });
    }
  }

  getCurrentTheme = () => {
    const safeInputTheme = this.props.theme || emptyThemeObj;

    const { styleCategories } = this.props;

    const theme = this.memoizedGetTheme(safeInputTheme, styleCategories);
    return theme;
  };

  createGlobalStyles = (theme) => {
    const { projectProps, noContainer } = this.props;

    let globalStyles = theme.cssVars;

    globalStyleFeatures.forEach((elem) => {
      const newStyles = elem.getGlobalStyles(projectProps, theme);
      globalStyles = { ...globalStyles, ...newStyles };
    });

    console.log('globalStyles', globalStyles);

    if (noContainer) {
      return { ':root': globalStyles };
    }
    return { ':root': globalStyles };
    // We can't scope styles to only the rootClass otherwise
    // we won't have the ability to set body because cssVars
    // will be out of scope. Add the ability to do this as
    // an optional flag for embedded webviews - specifically
    // this will keep all feature
    // return { [`.${rootClassName}`]: globalStyles };
  };

  render() {
    const { noContainer, children } = this.props;
    const { globalStyles, currentTheme } = this.state;

    return (
      <sdkCore.ThemeContext.Provider value={currentTheme}>
        <Global styles={globalStyles} />
        {noContainer ? (
          children
        ) : (
          <this.Container className={rootClassName}>{children}</this.Container>
        )}
      </sdkCore.ThemeContext.Provider>
    );
  }
}
