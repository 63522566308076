import {
  FETCHING_ACCOUNTS_ON_ME,
  RECIEVE_ACCOUNTS_ON_ME,
  // ACCOUNT_ERROR,
} from './types';
import * as ApiGateway from '../../lib/api-gateway';
import { userNotLoggedInMsg } from 'lib/errorHelpers';
import { loggedInStatusChanged } from './authActions';
import log from 'lib/logging';
// import history from '../lib/history';

/**
 * Fetch accounts that this user has access to
 */
export const fetchAccountsOnMe = () => (dispatch) => {
  dispatch({ type: FETCHING_ACCOUNTS_ON_ME });
  return ApiGateway.fetchAccountsOnMe()
    .then((accounts) => {
      dispatch({ type: RECIEVE_ACCOUNTS_ON_ME, accounts });
    })
    .catch((error) => {
      if (error.message === userNotLoggedInMsg) {
        dispatch(loggedInStatusChanged(false));
        sessionStorage.setItem('isLoggedIn', 'false');
      } else {
        log.error('caught error fetching accounts', error);
        dispatch({ type: RECIEVE_ACCOUNTS_ON_ME, error });
      }
    });
};
