const defaultInsets = { top: 0, bottom: 0, left: 0, right: 0 };

export const globalStyleFeatures = [
  {
    needsUpdate: (projProps, prevProjProps, theme, prevTheme) => {
      if (!projProps.hiddenFields) {
        return false;
      }
      if (
        !prevProjProps.hiddenFields ||
        prevProjProps.hiddenFields.insets !== projProps.hiddenFields.insets
      ) {
        return true;
      }
      return false;
    },
    getGlobalStyles: (projProps, theme) => {
      let insets = defaultInsets;
      if (projProps?.hiddenFields?.insetStyles) {
        insets = { ...insets, ...projProps.hiddenFields.insetStyles };
      }
      return {
        '--safe-area-inset-top': `${insets.top}px`,
        '--safe-area-inset-bottom': `${insets.bottom}px`,
        '--safe-area-inset-left': `${insets.left}px`,
        '--safe-area-inset-right': `${insets.right}px`,
      };
    },
  },
];
