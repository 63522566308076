/**
 * @class Project
 * SDK Main entrance point
 */

import React, { PureComponent } from 'react';
import { ProjectContext } from '/lib/ProjectContext';
import { getPublicProjectIfNeeded } from '/lib/fetchProject';
import { PrivateProjectProps } from '/ProjectProps';

export class ProjectProvider extends PureComponent<PrivateProjectProps> {
  componentDidMount() {
    this.projectPrefetch();
  }

  componentDidUpdate() {
    // We should always attempt to refetch the project
    // the prefetch logic should properly re-fetch or not based on params
    this.projectPrefetch();
  }

  projectPrefetch() {
    const { path, options, hiddenFields } = this.props;
    getPublicProjectIfNeeded(path, null, options, hiddenFields);
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <ProjectContext.Provider value={rest}>{children}</ProjectContext.Provider>
    );
  }
}
