export const sdkCallbackTopics = {
  /**
   * Called whenever the title has updated payload of form:
   * { title: 'titleString',
   *   pageIndex: 0,
   * }
   */
  updateTitle: 'updateTitle',
  /**
   * Called when the page has successfully changed
   * { pageIndex: 0
   *   title: 'titleString', // Only needed for initial page, which doesn't get titleString call
   * }
   */
  pageUpdated: 'pageUpdated',
  /**
   * Empty payload - goes to beginning
   * @type {String}
   */
  goToBeginning: 'goToBeginning',
  /**
   * Empty payload - goes to beginning and closes
   * @type {String}
   */
  close: 'close',
  /**
   * Jumps to an arbitrary page
   * { pageIndex: 0
   *   title: 'titleString',
   * }
   */
  jumpToPage: 'jumpToPage',
  /**
   * Empty payload - reloads the whole project, by default
   * only if the project isn't currently anywhere on the screen
   * @type {String}
   * { force: false, // Force the reload of the project }
   */
  reloadProject: 'reloadProject',
  action: 'action',
  /**
   * {title: 'titleString'}
   * @type {String}
   */
  nextPage: 'nextPage',
  /**
   * { actionType: filesystemTopics.download,
   *   callback: () => {}
   * }
   * See below filesystemTopics for more information
   * @type {String}
   */
  filesystem: 'filesystem',
};

// All filesystem callbacks
export const filesystemTopics = {
  /**
   * { actionType: filesystemTopics.download,
   *   callback: () => {},
   *   url: "https://example.com/tarballOrFile",
   *   relativeLocation: '',
   *   isTarGz: true,
   * }
   * Downloads a file, and can optionally attempt to unpack it as a tarball
   * @type {String}
   */
  download: 'download',
  /**
   * { actionType: filesystemTopics.download,
   *   callback: () => {},
   *   loadJson: {
   *     sdkLoad: 'path',
   *     versionLoad: 'path',
   *     metadata: {arbitrary},
   *   }
   * }
   * [download description]
   * @type {String}
   */
  updateLoadJson: 'updateLoadJson',
  /**
   * { actionType: filesystemTopics.download,
   *   callback: () => {},
   *   path: "somepath/something"
   *   isDirectory: true
   * }
   * [download description]
   * @type {String}
   */
  deletePath: 'deletePath',
  /**
   *
   * { actionType: filesystemTopics.download,
   *   callback: () => {},
   *   path: '.',
   *   depth: 0 // Optional - default is infinite depth
   * }
   * @type {String}
   */
  listFiles: 'listFiles',
  /**
   * { actionType: filesystemTopics.download,
   *   callback: () => {},
   *   path: "somepath/something"
   *   text: "Text that's going to be written to file"
   * }
   * @type {String}
   */
  writeText: 'writeText',
  /**
   * { actionType: filesystemTopics.fileMetadata,
   *   callback: () => {},
   *   path: "somepath/something"
   * }
   * @type {String}
   */
  fileMetadata: 'fileMetadata',
};

/**
 * Send a message to the "user space" code - this is either via
 * a callback that is specified by the user, and/or via a handler
 * that calls back to iOS or Android.
 * @param  {String}   topic    One of the above topics
 * @param  {Object}   payload  payload for the given topic. *Must be serializable*
 * @param  {String}   platform specified platform changes how callback is sent ios vs android
 * @param  {Function} callback Function that user specifies to get topic+payload
 */
export function callbackToMobileSdk(topic, payload, callback = null) {
  if (callback) {
    console.log('calling callback', topic, payload, callback);
    callback(topic, payload);
    console.log('finished calling callback');
  }
}

/**
 * This sends a request to the mobile SDK/caller that requires a callback.
 * In turn it turns that into a promise that can be awaited, etc.
 * Also throws errors if the "error" field is populated in the response
 * @param  {[type]} topic         One of the above topics
 * @param  {[type]} payload       payload for the given topic. *Must be serializable*
 * @param  {[type]} callbackToSdk Function that user specifies to get topic+payload
 */
export async function promiseToMobileSdk(topic, payload, callbackToSdk) {
  return new Promise((accept, reject) => {
    // Add a callback function to the payload. This is the _only_ field name
    // in the payload that doesn't have to be serializable!
    payload.callback = (response) => {
      // Our spec allows responses which are partial (meant for "updates").
      // In this case we shouldn't return from the promise because we want
      // to wait for the full response. However we also can't yet do anything
      // with these responses (as it's not possible to have a promise
      // return multiple responses)
      if (response && response.partial === true) {
        console.log('Got a partial response - currently unhandled: ', response);
      } else if (response && response.error) {
        reject(response);
      } else {
        accept(response);
      }
    };
    // Actually call the containing caller
    callbackToMobileSdk(topic, payload, callbackToSdk);
  });
}

/**
 * This is specifically for when we call filesystem related operations
 * (see `filesystemTopics` above). The main difference is that this handles
 * the extra indirection that's required by filesystem requests.
 * @param  {[type]} fsTopic       [description]
 * @param  {[type]} payload       [description]
 * @param  {[type]} callbackToSdk [description]
 * @return {[type]}               [description]
 */
export async function promiseFilesystemAccess(fsTopic, payload, callbackToSdk) {
  const filesystemPayload = { ...payload, actionType: fsTopic };
  console.log('Filesystem payload to SDK: ', filesystemPayload);
  return promiseToMobileSdk(
    sdkCallbackTopics.filesystem,
    filesystemPayload,
    callbackToSdk
  );
}

export async function promiseOsCustomCode(
  codeName,
  parameters,
  callbackToSdk,
  retvals,
  varStore
) {
  const response = await promiseToMobileSdk(
    sdkCallbackTopics.action,
    { parameters, actionType: 'customCode', key: codeName },
    callbackToSdk
  );
  console.log('response', response);
  if (retvals) {
    for (let [returnName, varId] of Object.entries(retvals)) {
      if (response && response[returnName]) {
        varStore.set(varId, response[returnName]);
      }
    }
  }
}
