import _get from 'lodash.get';
import { sdkVersion } from '../consts';
import { fetchNativeInfo } from '../lib/staticFilesystemFiles';

// Note - don't use arrow functions in here, or you'll get an compiler warning
export const builtInVarDefs = {
  userId: {
    isOptions: true,
    path: 'userId',
    name: 'User Id',
    description:
      'Uniquely identifies user submitting form, email address is recommended if available',
  },
  os: {
    isOptions: false,
    path: 'os',
    name: 'Client OS',
    description: 'The operating system of the end user, ios, android, etc',
  },
  isNative: {
    isOptions: false,
    path: 'isNative',
    name: 'Native client',
    description: 'End user is running on a native OS',
  },
  hostAppVersion: {
    isOptions: false,
    path: 'bundleInfo.hostAppVersion',
    name: 'Host App Version',
    description: 'The version of the app that has the SDK',
  },
  hostAppBuild: {
    isOptions: false,
    path: 'bundleInfo.hostAppBuild',
    name: 'Host App Build',
    description: 'The build number of the app that has the SDK',
  },
  deviceModel: {
    isOptions: false,
    path: 'deviceInfo.model',
    name: 'Device Model',
    description: 'Category of device, eg: iPhone',
  },
  deviceModelName: {
    isOptions: false,
    path: 'deviceInfo.modelName',
    name: 'Model Name',
    description: 'Full consistent identifier for device, eg: iPhone12,5',
  },
  deviceName: {
    isOptions: false,
    path: 'deviceInfo.name',
    name: 'Device Name',
    description: 'Name of the device, eg: iPhone 11 Pro Max',
  },
  osVersion: {
    isOptions: false,
    path: 'deviceInfo.osVersion',
    name: 'OS version',
    description:
      'The operating system of the target device, eg for an iOS device: 13.2.2',
  },
  osName: {
    isOptions: false,
    path: 'deviceInfo.osName',
    name: 'OS Name',
    description: 'The OS name - eg: iOS',
  },
  deviceIdiom: {
    isOptions: false,
    path: 'deviceInfo.idiom',
    name: 'Device idiom',
    description: 'Eg: phone, tablet, tv, car',
  },
  deviceUuid: {
    isOptions: false,
    path: 'deviceInfo.deviceUuid',
    name: 'Device ID',
    description: 'Unique ID for a device',
  },
  engineVersion: {
    name: 'SupportChef Engine Version',
    description: 'Version of the SupportChef core that runs your project',
    value: () => {
      return sdkVersion;
    },
  },
  wrapperSdkVersion: {
    fromNativeInfo: true,
    name: 'SupportChef SDK version',
    description: 'Version of the SupportChef iOS/Android/etc sdk',
    value: async function (options, hiddenFields) {
      if (hiddenFields.os === 'ios' && !hiddenFields.noFs) {
        const nativeInfo = await fetchNativeInfo();
        return nativeInfo.iosSdkVer;
      }
      return undefined;
    },
  },
  sdkUuid: {
    fromNativeInfo: true,
    name: 'Downloaded SDK UUID',
    description: 'UUID for downloaded SDK, regenerated on each re-download',
    value: async function (options, hiddenFields) {
      if (hiddenFields.isNative && !hiddenFields.noFs) {
        const nativeInfo = await fetchNativeInfo();
        return nativeInfo.uuid;
      }
      return undefined;
    },
  },
};

async function getVarfromOptions(options, hiddenFields, varDef) {
  const containingObject = varDef.isOptions ? options : hiddenFields;
  if (varDef.path) {
    return _get(containingObject, varDef.path, varDef.default);
  } else if (varDef.value) {
    return await varDef.value(options, hiddenFields);
  }
  return varDef.default;
}

export async function extractVarsFromOptions(options, hiddenFields, vars) {
  const builtInVars = {};
  const varPromises = Object.entries(builtInVarDefs).map(([key, varDef]) => {
    return getVarfromOptions(options, hiddenFields, varDef).then((varVal) => {
      builtInVars[key] = varVal;
    });
  });
  await Promise.all(varPromises);

  return [builtInVars, vars];
}
