import { uuidv4 } from '/lib/utils';
import { TraverserState } from '/GraphTraverser';
import { VariableAccessor } from 'supportchef-sdk-core';

/**
 * varJson structure:
 *  {
 * 	  <varname>: {
 * 	 	  variable: {
 * 	 	  	value: <variable value>
 * 	 	  	(custom metadata could be added here)
 * 	 	  },
 * 	 	  listening: {
 * 	 	    <uuid1>: <callback1>,
 * 	 	    <uuid2>: <callback2>
 * 	 	    ...
 * 	 	  }
 * 	  }
 *  }
 *
 * For multivar variable instead is an array of objects:
 *  variable: [
 *  	{value: xyz},
 *  	{value: blah}
 *  ]
 */
export default class VariableStore {
  protected varJson: any;
  private traverser: TraverserState;

  constructor() {
    this.varJson = {};
  }

  /**
   * Stringify for saving to localstorage for restoration
   * Format differs from normal varstore, including only values
   *  {
   *    <var1>: <value1>,
   *    <var2>: <value2>,
   *    <multi1>: [<v1>, <v2> ...]
   *  }
   * @return {string} JSON string representing the varstore
   */
  stringify = () => {
    let v = {};
    for (const key in this.varJson) {
      v[key] = this.get(key);
    }
    return JSON.stringify(v);
  };

  /**
   * Receives json string generated by stringify to reconstruct varstore from
   * previously saved session
   * @param {string} json JSON String generated by this class stringify.
   */
  initFromJson = (json: string) => {
    console.info(`Reinflating varStore from json ${json}`);
    let v = JSON.parse(json);
    for (const varname in v) {
      const value = v[varname];
      this.initcheck(varname, Array.isArray(value));
      Array.isArray(value)
        ? this.add(varname, value)
        : this.set(varname, value);
    }
    console.info(`Inflated varStore: ${this.stringify()}`);
  };

  setTraverser = (traverser: TraverserState) => {
    this.traverser = traverser;
  };

  initvar = (varname) => {
    this.varJson[varname] = { variable: { value: undefined }, listening: {} };
  };

  initmultivar = (varname) => {
    this.varJson[varname] = { variable: [], listening: {} };
  };

  // initializes var only if not yet initialized
  initcheck = (varname, multi = false) => {
    (varname in this.varJson && this.varJson[varname]) ||
      (multi ? this.initmultivar(varname) : this.initvar(varname));
  };

  getraw = (varname) => {
    return this.varJson[varname] && this.varJson[varname].variable;
  };

  get = (varname) => {
    let raw = this.getraw(varname);
    return raw && (Array.isArray(raw) ? raw.map((o) => o.value) : raw.value);
  };

  // For single value variables
  set = (varname, value) => {
    this.varJson[varname].variable.value = value;
    this.callback(varname);
    // console.log('SET: -> ' + varname + ': ' + value, this.varJson);
  };

  // For multivar variables
  // value: single or array of values
  add = (varname, value) => {
    if (Array.isArray(value)) {
      this.varJson[varname].variable.push(
        ...value.map((v) => {
          return { value: v };
        })
      );
    } else {
      this.varJson[varname].variable.push({ value });
    }

    this.callback(varname);
  };

  // For multivar variables
  // reject: function:boolean
  // Removes all values that match "reject" function
  // e.g. reject = (o) => {return o.value == "remove_me"}
  remove = (varname, reject) => {
    const variable = this.varJson[varname].variable;
    let i = 0;
    while (i < variable.length) {
      if (reject(variable[i])) {
        variable.splice(i, 1);
      } else {
        i++;
      }
    }
    this.callback(varname);
  };

  // For multivar
  // Removes all objects matching value.
  removeVal = (varname, value) => {
    this.remove(varname, (o) => o.value === value);
  };

  // Changes to var will trigger callback function
  listen = (varname, callback, uuid) => {
    this.varJson[varname].listening[uuid] = callback;
  };

  // Clean up your callback functions!
  unmount = (varname, uuid) => {
    delete this.varJson[varname].listening[uuid];
  };

  callback = (varname) => {
    Object.entries(this.varJson[varname].listening).forEach(([uuid, f]) => {
      (f as Function)(this.get(varname));
    });

    // TODO: Is this save needed here? Probably - Fix this
    // this.traverser.save();
  };

  // If you need to listen more than once, use a new Accessor!
  getAccessor = (varname, multi = false) => {
    return new Accessor(this, varname, multi);
  };
}

export class Accessor implements VariableAccessor {
  protected varstore: VariableStore;
  protected varname: string;
  protected uuid: string;

  constructor(varstore: VariableStore, varname: string, multi = false) {
    varstore.initcheck(varname, multi);
    this.varstore = varstore;
    this.varname = varname;
    this.uuid = uuidv4();
  }

  get = () => {
    return this.varstore.get(this.varname);
  };

  set = (value) => {
    this.varstore.set(this.varname, value);
  };

  add = (value) => {
    this.varstore.add(this.varname, value);
  };

  remove = (reject) => {
    this.varstore.remove(this.varname, reject);
  };

  removeValue = (value) => {
    this.varstore.removeVal(this.varname, value);
  };

  listen = (callback) => {
    this.varstore.listen(this.varname, callback, this.uuid);
  };

  unmount = () => {
    this.varstore.unmount(this.varname, this.uuid);
  };
}
