/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import * as React from 'react';

import * as imageStyle from './ImageStyles';
import {
  CoreType,
  ThemePropsType,
  SubComponentPropsInterface,
} from 'supportchef-sdk-core';
import Symbols from '/Symbols/index';

export interface BaseImageProps extends SubComponentPropsInterface {
  onChange: any;
  isLoading?: boolean;
  themedProps: ThemePropsType;
}

export interface BaseImageComponentState {}

export class BaseImageComponent extends React.Component<
  BaseImageProps,
  BaseImageComponentState
> {
  static componentName = 'BaseImageComponent';

  render() {
    const { isEditing, element, error, isLoading, themedProps } = this.props;
    const { StyledComponent: StyledImageContainer } = themedProps;

    let toRender;
    const { symbol } = element;
    if (symbol) {
      const SymbolToRender = Symbols[symbol];
      toRender = <SymbolToRender />;
    }

    return <StyledImageContainer>{toRender}</StyledImageContainer>;
  }
}

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(imageStyle, BaseImageComponent);
