import { fetchNativeInfo } from '../staticFilesystemFiles';

// This handles unpacking either "native" provided project paths, or
// traditional string project paths
export async function unpackProjectPath(path) {
  if (path === 'native') {
    return getDetailsFromNative();
  }
  return unpackProjectPathPartially(path);
}

// This unpacks the project path that's provided to the SDK
// ProjectPath example:
// {accountId}${stage}${projectId}?key1:val1&key2:val2
// a-sldf-32jsa$prod$p-IJHR5?v=1.0.0
//
// Returned dict:
// { error, details, accountStageProjectId, libOptions}
//   error: If error occured
//   details: {accountId, stage, projectId, queryParams}
//   accountStageProjectId: the path to send to
export function unpackProjectPathPartially(path) {
  let returnDictionary = {};
  // Parses projectpath:/ acctId  $  stage  $projectId ?/$  queryParams
  const projectPathRe = /([\w-]+)\$([\w-]+)\$([\w-]+)([\?\$]?[-\w\.\=\&]*)$/;

  const projectPathMatch = path.match(projectPathRe);

  const [_, accountId, stage, projectId, queryParams] = projectPathMatch;

  if (!accountId || !stage || !projectId) {
    return { error: 'Invalid project path (missing required fields)' };
  }

  // let queryParamsToSend = [];
  let queryParamsClean = '';
  if (queryParams) {
    // The lib key is a special key that can be inside the queryParams
    // This key should _not_ be passed to the server and should be parsed/used
    // by the library / sdk (this key should be a base64 encoded object)
    const searchForLibRegex = /(?:\$|\?)lib=([\w=]*)/i;

    const libMatch = queryParams.match(searchForLibRegex);

    // Extract the lib base64 encoded object
    if (libMatch && libMatch[1]) {
      const libDict = atob(libMatch[1]);
      console.log(libDict);
      try {
        // Add lib options to the returned dictionary
        returnDictionary.libOptions = JSON.parse(libDict);
      } catch (error) {
        console.error(error);
        return { error: 'Invalid base64 JSON object in "lib" key' };
      }

      // Remove the lib part from queryParams
      queryParamsClean = queryParams.replace(searchForLibRegex, '');
    } else {
      queryParamsClean = queryParams;
    }

    // Remove the leading question mark if it exists
    // (We match it because we need it for the searchForLibRegex)
    if (queryParamsClean[0] === '?') {
      queryParamsClean = queryParamsClean.splice(1);
    }
  }

  const accountStageProjectId = `${accountId}$${stage}$${projectId}`;

  // Include internal details
  returnDictionary.details = {
    accountId,
    stage,
    projectId,
    queryParams: queryParamsClean,
  };

  returnDictionary.accountStageProjectId = accountStageProjectId;

  return returnDictionary;
}

const getDetailsFromNative = async () => {
  console.log('Fetching from native');
  const nativeInfo = await fetchNativeInfo();
  console.log('Fetched from native', nativeInfo);
  const returnDictionary = {};
  const {
    projectId,
    stage,
    accountId,
    apiUrl,
    projectVer,
    isPublished,
  } = nativeInfo;

  returnDictionary.libOptions = { baseUrl: apiUrl };
  // Details also includes projectVer, isPublished
  returnDictionary.details = nativeInfo;
  returnDictionary.accountStageProjectId = `${accountId}$${stage}$${projectId}`;
  return returnDictionary;
};
