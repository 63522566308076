let toolboxCategories = {};

export const registerToolboxSections = (category, toolboxSections) => {
  // We don't allocate this until registering because
  // we only use this function when using editor
  if (!toolboxCategories[category]) {
    toolboxCategories[category] = toolboxSections;
  } else {
    toolboxCategories[category] = toolboxCategories[category].concat(
      toolboxSections
    );
  }
};

export const getToolboxCategories = () => {
  return toolboxCategories;
};
