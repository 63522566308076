/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core';
import * as React from 'react';
// We import from the themeHoc directly to avoid cyclic dependency
import { CoreType, ThemePropsType } from 'supportchef-sdk-core';
import * as rootPageStyle from './RootContainerStyles';

export interface RootContainerProps {
  themedProps: ThemePropsType;
  className: string;
}

export class RootContainer extends React.Component<RootContainerProps> {
  static componentName = 'RootContainer';

  render() {
    const { children, themedProps, className } = this.props;
    const { StyledComponent: StyledContainer } = themedProps;

    return <StyledContainer className={className}>{children}</StyledContainer>;
  }
}

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(rootPageStyle, RootContainer);
