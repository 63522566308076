import {
  FETCHING_ACCESS_TOKENS,
  RECEIVE_ACCESS_TOKENS,
  DELETING_ACCESS_TOKEN,
  DELETED_ACCESS_TOKEN,
  OPEN_CREATING_ACCESS_TOKEN_SCREEN,
  CREATING_ACCESS_TOKEN,
  ADD_ACCESS_TOKEN,
  ACCESS_TOKEN_ERROR,
} from '../actions/types';

import Config from '../../config';
import { accessTokenKey } from '../../lib/accessTokenHelper';

export const initialState = {
  allAccessTokens: new Map(),
  loadingAccessTokens: false,
  loaded: false,
  deletingAccessToken: false,
  creatingAccessToken: false,
  createdAccessTokenId: null,
  error: '',
};

// const arrayToMapFactory = keyField => array =>
//   array.reduce((obj, item) => {
//     obj[item[keyField]] = item;
//     return obj;
//   }, {});

function accessTokenToMapTuple(tokenObj) {
  return [
    accessTokenKey(tokenObj),
    {
      ...tokenObj,
      signingBundle: {
        ...tokenObj.signingBundle,
        pass: {
          ...tokenObj.signingBundle.pass,
          iot: Config.awsIotHost,
          gateway: Config.awsApiGatewayInvokeUrl,
        },
      },
    },
  ];
}

function accessTokensArrayToMap(accessTokens) {
  return accessTokens.map(accessTokenToMapTuple);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ACCESS_TOKENS:
      return {
        ...state,
        loadingAccessTokens: true,
      };
    case RECEIVE_ACCESS_TOKENS:
      return {
        ...state,
        allAccessTokens: new Map([
          ...state.allAccessTokens,
          ...accessTokensArrayToMap(action.accessTokens),
        ]),
        loadingAccessTokens: false,
        loaded: true,
      };
    case DELETING_ACCESS_TOKEN:
      return {
        ...state,
        deletingAccessToken: true,
      };
    case DELETED_ACCESS_TOKEN:
      return {
        ...state,
        allAccessTokens: new Map(
          Array.from(state.allAccessTokens).filter(
            ([key, item]) => key !== action.token
          )
        ),
        deletingAccessToken: false,
      };
    case OPEN_CREATING_ACCESS_TOKEN_SCREEN:
      return {
        ...state,
        createdAccessTokenId: null,
      };
    case CREATING_ACCESS_TOKEN:
      return {
        ...state,
        creatingAccessToken: true,
      };
    case ADD_ACCESS_TOKEN:
      return {
        ...state,
        allAccessTokens: new Map([
          ...state.allAccessTokens,
          accessTokenToMapTuple(action.token),
        ]),
        createdAccessTokenId: accessTokenKey(action.token),
        creatingAccessToken: false,
        error: '',
      };
    case ACCESS_TOKEN_ERROR:
      return {
        ...state,
        error: action.error,
        creatingAccessToken: false,
        deletingAccessToken: false,
      };
    default:
      return state;
  }
};
