/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

// Auth actions
export const LOGGED_IN_STATUS_CHANGED = 'LOGGED_IN_STATUS_CHANGED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const AUTH_FORM_UPDATE = 'AUTH_FORM_UPDATE';
export const IDENTITY_UPDATED = 'IDENTITY_UPDATED';
export const LOGOUT = 'LOGOUT';
export const REGISTER_USER_CONFIRM_SUCCESS = 'REGISTER_USER_CONFIRM_SUCCESS';
export const REGISTER_USER_CONFIRM_NEEDED = 'REGISTER_USER_CONFIRM_NEEDED';
export const REGISTER_USER_CONFIRM_FAILED = 'REGISTER_USER_CONFIRM_FAILED';
export const REGISTER_USER_CONFIRM_CODE = 'REGISTER_USER_CONFIRM_CODE';
export const SEND_CONFIRM_CODE = 'SEND_CONFIRM_CODE';
export const SEND_CONFIRM_CODE_SUCCESS = 'SEND_CONFIRM_CODE_SUCCESS';
export const SEND_CONFIRM_CODE_FAILURE = 'SEND_CONFIRM_CODE_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_CODE_SENT = 'FORGOT_PASSWORD_CODE_SENT';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const AUTHED_EMAIL_CHANGED = 'AUTHED_EMAIL_CHANGED';

// User actions
export const FETCHED_USER = 'FETCHED_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export const UPDATE_USER_HELP_CONTENT = 'UPDATE_USER_HELP_CONTENT';
export const UPDATED_USER = 'UPDATED_USER';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

// IoT actions
export const PUBLIC_IOT_POLICY_ATTACHED = 'PUBLIC_IOT_POLICY_ATTACHED';
export const PUBLIC_IOT_POLICY_ATTACHING = 'PUBLIC_IOT_POLICY_ATTACHING';
export const PUBLIC_IOT_POLICY_ERROR = 'PUBLIC_IOT_POLICY_ERROR';
export const DEVICE_CONNECTED_STATUS_CHANGED =
  'DEVICE_CONNECTED_STATUS_CHANGED';
export const MESSAGE_HANDLER_ATTACHED = 'MESSAGE_HANDLER_ATTACHED';

// Project Actions
export const FETCHING_PROJECTS = 'FETCHING_PROJECTS';
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';
export const OPEN_CREATING_PROJECT_SCREEN = 'OPEN_CREATING_PROJECT_SCREEN';
export const CREATING_PROJECT = 'CREATING_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETING_PROJECT = 'DELETING_PROJECT';
export const DELETED_PROJECT = 'DELETED_PROJECT';
export const FETCHING_PROJECT = 'FETCHING_PROJECT';
export const RECEIVED_PROJECT = 'RECEIVED_PROJECT';
export const PROJECT_LOADING_ERROR = 'PROJECT_LOADING_ERROR';
export const FETCHING_PROJECT_VERSION = 'FETCHING_PROJECT_VERSION';
export const RECEIVED_PROJECT_VERSION = 'RECEIVED_PROJECT_VERSION';
export const PROJECT_VERSION_LOADING_ERROR = 'PROJECT_VERSION_LOADING_ERROR';
export const NAME_CHANGE_PROJECT = 'NAME_CHANGE_PROJECT';
export const DESCRIPTION_CHANGE_PROJECT = 'DESCRIPTION_CHANGE_PROJECT';
export const SAVING_PROJECT = 'SAVING_PROJECT';
export const SAVED_PROJECT = 'SAVED_PROJECT';
export const PROJECT_SAVING_ERROR = 'PROJECT_SAVING_ERROR';
export const NEXT_SAVE_TS = 'NEXT_SAVE_TS';
export const CHANGING_PUBLISH_SETTINGS_PROJECT =
  'CHANGING_PUBLISH_SETTINGS_PROJECT';
export const CHANGED_PUBLISH_SETTINGS_PROJECT =
  'CHANGED_PUBLISH_SETTINGS_PROJECT';
export const DEFAULT_UI_VER_CHANGE_PROJECT = 'DEFAULT_UI_VER_CHANGE_PROJECT';
export const ERROR_PUBLISH_SETTINGS_PROJECT = 'ERROR_PUBLISH_SETTINGS_PROJECT';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const UNDO_PROJECT = 'UNDO_PROJECT';
export const REDO_PROJECT = 'REDO_PROJECT';
export const CLEAR_UNDO_HISTORY_PROJECT = 'CLEAR_UNDO_HISTORY_PROJECT';

// Project Version Actions
export const NAME_CHANGE_PROJECT_VERSION = 'NAME_CHANGE_PROJECT_VERSION';
export const VERSION_CHANGE_PROJECT_VERSION = 'VERSION_CHANGE_PROJECT_VERSION';
export const SAVING_PROJECT_VERSION = 'SAVING_PROJECT_VERSION';
export const SAVED_PROJECT_VERSION = 'SAVED_PROJECT_VERSION';
export const PROJECT_VERSION_SAVING_ERROR = 'PROJECT_VERSION_SAVING_ERROR';
export const DELETING_PROJECT_VERSION = 'DELETING_PROJECT_VERSION';
export const DELETED_PROJECT_VERSION = 'DELETED_PROJECT_VERSION';
export const CREATING_PROJECT_VERSION = 'CREATING_PROJECT_VERSION';
export const ADD_PROJECT_VERSION = 'ADD_PROJECT_VERSION';
export const PROJECT_VERSION_ERROR = 'PROJECT_VERSION_ERROR';
export const UPDATING_PROJECT_VERSION = 'UPDATING_PROJECT_VERSION';
export const UPDATED_PROJECT_VERSION = 'UPDATED_PROJECT_VERSION';
export const PUBLISHING_PROJECT_VERSION = 'PUBLISHING_PROJECT_VERSION';
export const PUBLISHED_PROJECT_VERSION = 'PUBLISHED_PROJECT_VERSION';
export const UPDATE_PROJECT_VERSION_THEME = 'UPDATE_PROJECT_VERSION_THEME';
export const IMPORTED_PROJECT_VERSION = 'IMPORTED_PROJECT_VERSION';

// Diagram Actions
export const DIAGRAM_CONTAINER_RESIZE = 'DIAGRAM_CONTAINER_RESIZE';
export const DIAGRAM_CONTAINER_SIZE_UPDATE = 'DIAGRAM_CONTAINER_SIZE_UPDATE';
export const TEST_DEVICE_VISIBLE_NODES = 'TEST_DEVICE_VISIBLE_NODES';

// Node Actions
export const ADD_NODE = 'ADD_NODE';
export const REMOVE_NODE = 'REMOVE_NODE';
export const MOVE_NODE = 'MOVE_NODE';
export const MOVE_NODE_END = 'MOVE_NODE_END';
export const SET_SOURCE_PORT_DRAG = 'SET_SOURCE_PORT_DRAG';
export const SET_NODE_NAME = 'SET_NODE_NAME';
export const SET_NODE_PAGE_STYLE = 'SET_NODE_PAGE_STYLE';
export const SET_NODE_NAME_IS_TITLE = 'SET_NODE_NAME_IS_TITLE';
export const MODIFY_NODE = 'MODIFY_NODE';
export const SET_DEFAULT_ENTRY_NODE = 'SET_DEFAULT_ENTRY_NODE';
export const SET_NAME_ENTRY_NODE = 'SET_NAME_ENTRY_NODE';

// Link Actions
export const ADD_LINK = 'ADD_LINK';
export const REMOVE_LINK = 'REMOVE_LINK';

// Project Node Actions
export const ADD_FORM_ENTRY = 'ADD_FORM_ENTRY';
export const REORDER_FORM_ENTRY = 'REORDER_FORM_ENTRY';
export const DELETE_FORM_ENTRY = 'DELETE_FORM_ENTRY';
export const MODIFY_FORM_ENTRY = 'MODIFY_FORM_ENTRY';
export const MODIFY_FORM_ENTRY_AT_PATH = 'MODIFY_FORM_ENTRY_AT_PATH';

// Variable Actions
export const CREATE_VARIABLE = 'CREATE_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
export const REMOVE_VARIABLE = 'REMOVE_VARIABLE';

// Reference Actions
export const ADD_REFERENCE = 'ADD_REFERENCE';
export const UPDATE_REFERENCE = 'UPDATE_REFERENCE';
export const DELETE_REFERENCE = 'DELETE_REFERENCE';

// Entry Node Actions
export const UNSET_OUTPUT = 'UNSET_OUTPUT';
export const SET_NAMED_OUTPUT_FOR_NODE_ENTRY =
  'SET_NAMED_OUTPUT_FOR_NODE_ENTRY';
export const SET_IF_OUTPUT_DEFINED_NODE_ENTRY =
  'SET_IF_OUTPUT_DEFINED_NODE_ENTRY';
export const SET_LOCAL_VARIABLE_ENTRY = 'SET_LOCAL_VARIABLE_ENTRY';
export const ADD_ENTRY_NODE_INPUT = 'ADD_ENTRY_NODE_INPUT';
export const UPDATE_ENTRY_NODE_INPUT = 'UPDATE_ENTRY_NODE_INPUT';
export const DELETE_ENTRY_NODE_INPUT = 'DELETE_ENTRY_NODE_INPUT';

// Action Node Actions
export const SET_ACTION_NODE_ACTION_TYPE = 'SET_ACTION_NODE_ACTION_TYPE';
export const ADD_ACTION_NODE_OUTPUT = 'ADD_ACTION_NODE_OUTPUT';
export const UPDATE_ACTION_NODE_OUTPUT = 'UPDATE_ACTION_NODE_OUTPUT';
export const DELETE_ACTION_NODE_OUTPUT = 'DELETE_ACTION_NODE_OUTPUT';
export const UPDATE_ACTION_NODE_METADATA = 'UPDATE_ACTION_NODE_METADATA';

// Conditional Node Actions
export const SET_CONDITIONAL_MODE = 'SET_CONDITIONAL_MODE';
export const ADD_CONDITIONAL_ENTRY = 'ADD_CONDITIONAL_ENTRY';
export const REORDER_CONDITIONAL_ENTRY = 'REORDER_CONDITIONAL_ENTRY';
export const DELETE_CONDITIONAL_ENTRY = 'DELETE_CONDITIONAL_ENTRY';
export const MODIFY_CONDITIONAL_ENTRY = 'MODIFY_CONDITIONAL_ENTRY';
export const MODIFY_CONDITIONAL_ENTRY_AT_PATH =
  'MODIFY_CONDITIONAL_ENTRY_AT_PATH';

// Access Token Actions
export const FETCHING_ACCESS_TOKENS = 'FETCHING_ACCESS_TOKENS';
export const RECEIVE_ACCESS_TOKENS = 'RECEIVE_ACCESS_TOKENS';
export const OPEN_CREATING_ACCESS_TOKEN_SCREEN =
  'OPEN_CREATING_ACCESS_TOKEN_SCREEN';
export const CREATING_ACCESS_TOKEN = 'CREATING_ACCESS_TOKEN';
export const ADD_ACCESS_TOKEN = 'ADD_ACCESS_TOKEN';
export const DELETING_ACCESS_TOKEN = 'DELETING_ACCESS_TOKEN';
export const DELETED_ACCESS_TOKEN = 'DELETED_ACCESS_TOKEN';
export const FETCHING_ACCESS_TOKEN = 'FETCHING_ACCESS_TOKEN';
export const RECIEVED_ACCESS_TOKEN = 'RECIEVED_ACCESS_TOKEN';
export const ACCESS_TOKEN_ERROR = 'ACCESS_TOKEN_ERROR';

// Account Actions
export const FETCHING_ACCOUNTS_ON_ME = 'FETCHING_ACCOUNTS_ON_ME';
export const RECIEVE_ACCOUNTS_ON_ME = 'RECIEVE_ACCOUNTS_ON_ME';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';

// Navigation Actions
export const NAV_TITLE_CHANGE = 'NAV_TITLE_CHANGE';
export const NAV_SUBTITLE_CHANGE = 'NAV_SUBTITLE_CHANGE';
export const NAV_INC_SIDEBAR_COUNT = 'NAV_INC_SIDEBAR_COUNT';
export const NAV_DEC_SIDEBAR_COUNT = 'NAV_DEC_SIDEBAR_COUNT';

// Help Actions
export const ADD_WALKTHROUGH_RUN = 'ADD_WALKTHROUGH_RUN';
export const REMOVE_WALKTHROUGH_RUN = 'REMOVE_WALKTHROUGH_RUN';
export const HELP_OVERLAY_STATUS = 'HELP_OVERLAY_STATUS';
