import * as React from 'react';
import * as Persistence from '/lib/Persistence';
import VariableStore from '/Variables/VariableStore';
import { callbackToMobileSdk, sdkCallbackTopics } from '/lib/mobileCallback';
import core, { CoreType } from 'supportchef-sdk-core';
import { ProjectState } from 'Project';
import {
  getInitialNodePageStyle,
  getInitialNodePageTitle,
  hasEndNodeInChain,
} from '/lib/shared/graphUtils';
import { uuidv4 } from '/lib/utils';
import { onEndFunction } from '/ProjectProps';

export interface GraphTraverserProps {
  traverserState: TraverserState;
  hiddenFields?: any;
  options?: any;
  vars?: any;
  onEnd: onEndFunction;
}

interface GraphTraverserState {
  modified: number;
  title: string;
  localPageIndex: number;
}

const componentPlaceholder = { isEditing: false };

export class GraphTraverser extends React.Component<
  GraphTraverserProps,
  GraphTraverserState
> {
  public containerRef: any;
  public core: CoreType = core;
  private Header: any;
  private PageContainer: any;

  constructor(props: GraphTraverserProps) {
    super(props);
    this.state = { modified: Date.now(), title: '', localPageIndex: 0 };
    this.props.traverserState.register(this.update);
    this.containerRef = React.createRef();

    this.Header = this.core.ComponentFactoryFromClass(
      'Header',
      componentPlaceholder
    );
    this.PageContainer = this.core.ComponentFactoryFromClass(
      'PageContainer',
      componentPlaceholder
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { hiddenFields, vars, traverserState } = this.props;
    if (
      prevProps.hiddenFields?.pageIndex !== hiddenFields?.pageIndex ||
      prevProps.hiddenFields?.preload !== hiddenFields?.preload ||
      prevProps.traverserState !== traverserState
    ) {
      this.updatePageBeingRendered();
    }

    if (hiddenFields?.shownNodesCallback) {
      this.updateShownNodes();
    }

    // Let entry node set initial vars
    if (
      vars !== prevProps.vars ||
      prevProps.traverserState !== traverserState
    ) {
      const didChange = traverserState.pageStack[0].setInitialVars(this);
      didChange && this.update();
    }
  }

  componentDidMount() {
    const { hiddenFields, traverserState, vars } = this.props;
    const payloadForCallback = {
      pageIndex: this.getPageIndex(),
      // Really only necessary to update title for page 0 - since it doesn't get the "new page edge"
      title: getInitialNodePageTitle(this.getCurrentNode(), traverserState),
    };
    this.callbackToMobile(sdkCallbackTopics.updateTitle, payloadForCallback);

    if (hiddenFields?.shownNodesCallback) {
      this.updateShownNodes();
    }

    // Let entry node set initial vars
    if (vars) {
      const didChange = traverserState.pageStack[0].setInitialVars(this);
      didChange && this.update();
    }
  }

  getPageIndex = () => {
    return this.props.hiddenFields?.pageIndex ?? this.state.localPageIndex;
  };

  setLocalPageIndex = (newPageIndex) => {
    if (this.props.hiddenFields?.pageIndex === undefined) {
      this.setState({ localPageIndex: newPageIndex });
    }
  };

  getCurrentNode = () => {
    const { traverserState } = this.props;
    return traverserState.getPage(this.getPageIndex());
  };

  callbackToMobile = (topic, payload) => {
    const { hiddenFields } = this.props;
    const callback = hiddenFields?.sdkCallback;
    callbackToMobileSdk(topic, payload, callback);
    if (payload && payload.title) {
      this.setState({ title: payload.title });
    }
  };

  goToBeginning = () => {
    this.callbackToMobile(sdkCallbackTopics.goToBeginning, {});
    this.props.traverserState.expire();
    this.props.onEnd && this.props.onEnd();
  };

  update = () => {
    this.setState({ modified: Date.now() });
  };

  nextPage = (newNode) => {
    const { traverserState } = this.props;
    const currentPage = this.getPageIndex();
    console.log('Going to next page - this is our current page', currentPage);
    const newIndex = traverserState.nextPage(newNode, currentPage);

    this.setLocalPageIndex(newIndex);
    this.callbackToMobile(sdkCallbackTopics.nextPage, {
      title: getInitialNodePageTitle(newNode, traverserState),
      pageIndex: newIndex,
      newPage: true,
    });
  };

  prevPage = () => {
    const currentIndex = this.getPageIndex();
    const newIndex = currentIndex - 1;

    if (newIndex >= 0) {
      this.setLocalPageIndex(newIndex);
      this.callbackToMobile(sdkCallbackTopics.jumpToPage, {
        pageIndex: newIndex,
        newPage: false,
      });
    } else {
      this.callbackToMobile(sdkCallbackTopics.close, {});
    }
  };

  updatePageBeingRendered = () => {
    const { hiddenFields, traverserState } = this.props;
    const pageIndex = this.getPageIndex();
    const payloadForCallback = {
      pageIndex,
      // Really only necessary to update title for page 0 - since it doesn't get the "new page edge"
      title: getInitialNodePageTitle(this.getCurrentNode(), traverserState),
    };

    traverserState.save(pageIndex);

    // We do two callbacks, one to tell iOS that we are "switching" to the
    // new view, and the second call tells iOS to actually pull the snapshot
    // behind this new view (moving the view is the expensive iOS operation)
    // setTimeout 0 generally calls immediately after the layout reflow is
    // done - confirmed via many safari debug session
    this.callbackToMobile(sdkCallbackTopics.pageUpdated, payloadForCallback);
    window.setTimeout(() => {
      this.callbackToMobile('pageUpdatedAndReflowed', payloadForCallback);
    }, 0);
  };

  updateShownNodes = () => {
    const { shownNodesCallback } = this.props.hiddenFields;
    const shownNodes = [];
    let nextNode = this.getCurrentNode();
    while (nextNode) {
      shownNodes.push(nextNode.nodeId);
      nextNode = nextNode.getNextPrimaryNode(this.props.traverserState);
    }

    shownNodesCallback && shownNodesCallback(shownNodes);
  };

  // REDO SECTION:
  getContentArray = () => {
    const contentArray = [];
    let nextNode = this.getCurrentNode();
    while (nextNode) {
      // console.log('getting content node nextNode', nextNode);
      // Secondary Nodes will not work properly with changes made recently
      // Specifically they can potentially be executed many times if they
      // are not on a "new page" edge
      // const secondaryNodes = nextNode.getSecondaryNodes();
      // secondaryNodes.forEach(node => node.executeSecondaryAction());

      if (nextNode.isContentNode()) {
        contentArray.push(...nextNode.getContent(this));
      }

      nextNode = nextNode.getNextPrimaryNode(this.props.traverserState);
    }

    this.props.traverserState.firstPageLoad = false;
    return contentArray;
  };

  followLink = (sourceElement, node, callback = null) => {
    let thisNode = node || this.getCurrentNode();
    let nextNode = null;
    do {
      nextNode = thisNode.getNodeFromLink(sourceElement);
      thisNode = thisNode.getNextPrimaryNode(this.props.traverserState);
    } while (thisNode !== null && !nextNode);
    if (nextNode) {
      if (hasEndNodeInChain(nextNode, this.props.traverserState)) {
        this.goToBeginning();
      } else if (nextNode.triggerNextPage(this)) {
        this.nextPage(nextNode);
      } else {
        // Loading not yet complete (action node)
        return nextNode.showLoading();
      }
    }
    return false;
  };

  // Make sure this is fully serializable if you send to mobile SDK!
  executeAction = async (actionType, data) => {
    const { hiddenFields, traverserState } = this.props;
    const { actions } = traverserState.options;

    const dataToSend = { action: data };
    if (hiddenFields?.isNative) {
      console.log('Action being sent to Mobile SDK');
      this.callbackToMobile(sdkCallbackTopics.action, data);
    } else {
      console.log('Action not being sent to Mobile SDK');
      if (actions[actionType]) {
        return actions[actionType](data);
      } else {
        return actions['default'](data);
      }
    }
  };

  // END REDO SECTION

  render = () => {
    const { hiddenFields } = this.props;
    const pageIndex = this.getPageIndex();

    // Sean:  Why is this here?
    this.props.traverserState.register(this.update);
    console.log('header object exists:', this.Header);

    return (
      <React.Fragment>
        {hiddenFields?.showUiHeader && (
          <this.Header
            title={this.state.title}
            goBack={this.prevPage}
            hideFirstPageBack={hiddenFields?.hideFirstPageBack}
            pageIndex={pageIndex}
            isWeb={false}
            graphTraverser={this}
          />
        )}
        <this.PageContainer
          styleNames={[
            getInitialNodePageStyle(
              this.getCurrentNode(),
              this.props.traverserState
            ),
          ]}
          containerRef={this.containerRef}
          key={this.props.traverserState.uuid}
          graphTraverser={this}
        >
          {this.getContentArray()}
        </this.PageContainer>
      </React.Fragment>
    );
  };
}

export class TraverserState {
  projectState: ProjectState;
  entryname: string;
  uuid: string;
  firstPageLoad: boolean;

  pageStack: any[];
  // protected currentPage: number;
  varStore: VariableStore;
  protected update: Function;
  protected vars: any;
  options: any;
  expired: boolean;
  initialized = false;

  constructor(startingNode, varStore, vars, options, projectState, entryname) {
    this.pageStack = [startingNode];
    // this.currentPage = 0;
    this.varStore = varStore;
    varStore.setTraverser(this);
    this.vars = vars;
    this.options = options;
    this.expired = false;
    this.projectState = projectState;
    this.entryname = entryname;
    this.uuid = uuidv4();
    this.firstPageLoad = true;
  }

  nextPage = (node, pageIndex) => {
    const nextIndex =
      pageIndex !== undefined ? pageIndex + 1 : this.pageStack.length;
    this.pageStack.splice(nextIndex);
    this.pageStack.push(node);
    pageIndex || this.update();
    this.firstPageLoad = true;
    this.save(nextIndex);
    return nextIndex;
  };

  getPage = (page: number) => {
    if ((!page && page != 0) || page >= this.pageStack.length) {
      return this.pageStack[this.pageStack.length - 1];
    }
    return this.pageStack[page];
  };

  stacksize = () => this.pageStack.length;

  register = (update: Function) => {
    this.update = update;
  };

  expire = () => {
    delete this.projectState.activeSessions[this.entryname];
    this.projectState.update();
    Persistence.remove(this.projectState.path, this.entryname);
  };

  save = (pageIndex) => {
    Persistence.save(this, pageIndex);
  };
}
