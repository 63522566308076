import { GraphTraverser } from '/GraphTraverser';

const primaryNodeKey = 'bottom';

// Primary (path) node = same page
// Secondary node = there are none.
// Next page is not even anywhere in here (only in link object)
export class BaseGraphNode {
  public nodeId: string;
  public nodeType: string;
  public node: any;
  protected graph: any;
  protected links: any;
  // protected linksTo: any;
  protected isPrimaryPathNodeInternal: boolean;

  constructor(nodeId: string, graph: any) {
    this.nodeId = nodeId;
    this.graph = graph;
    this.node = graph.nodes[nodeId];
    this.nodeType = this.node.nodeType;
    this.links = [];
    // this.linksTo = [];

    this.isPrimaryPathNodeInternal = true;

    this.parseLinks();
    if (!graph.nodeObjs) {
      graph.nodeObjs = { [this.nodeId]: this };
    } else {
      graph.nodeObjs[this.nodeId] = this;
    }
  }

  parseLinks() {
    Object.values(this.graph.links).forEach((link: any) => {
      if (link.source === this.nodeId) {
        this.links.push(link);
      }
      // if (link.target === this.nodeId) {
      //   this.linksTo.push(link);
      // }
    });
  }

  linkIsPrimary(link, traverserState = undefined) {
    return link.sourceName === primaryNodeKey;
  }

  getNodeFromLink(linkSourceName) {
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].sourceName === linkSourceName) {
        return this.graph.nodeObjs[this.links[i].target];
      }
    }
  }

  getNextNodeOfType = (searchForPrimaryNode, traverserState) => {
    const linkedNodes = [];

    // console.log('linksFrom', this.linksFrom);

    this.links.forEach((link) => {
      const node = this.graph.nodeObjs[link.target];
      // console.info('link from ', node, searchForPrimaryNode);
      // If the target node is of type "primary path" AND the link is the primary path link
      if (
        node &&
        node.isPrimaryPathNode() === searchForPrimaryNode &&
        this.linkIsPrimary(link, traverserState) === searchForPrimaryNode
      ) {
        linkedNodes.push(node);
      }
    });

    return linkedNodes;
  };

  // TODO: remove possibility for infinite loop here
  getNextPrimaryNode = (traverserState) => {
    const primaryNodes = this.getNextNodeOfType(true, traverserState);
    return primaryNodes.length === 0 ? null : primaryNodes[0];
  };

  getSecondaryNodes = () => {
    return this.getNextNodeOfType(false, undefined);
  };

  isPrimaryPathNode = () => {
    return this.isPrimaryPathNodeInternal;
  };

  isContentNode = () => {
    return false;
  };

  getContent = (graphTraverser: GraphTraverser) => {
    return null;
  };

  getMetadata = () => {
    return this.node && this.node.metadata;
  };

  // This gets the container for the current page being viewed
  get pageStyle() {
    // console.log(this.node);
    return this.node.pageStyle;
  }

  get nameIsTitle() {
    return this.node.nameIsTitle;
  }

  get name() {
    return this.node.name;
  }

  isEndNode = () => {
    return false;
  };

  // This is called when this node is the target for a next page
  // It returns if you should actually switch to this node for the next page
  triggerNextPage = (graphTraverser: GraphTraverser) => {
    return true;
  };

  executeSecondaryAction = () => {};

  getName = () => {};
  getIsDefault = () => {};

  showLoading = () => {
    return true;
  };

  setInitialVars = (graphTraverser: GraphTraverser) => false;
}
