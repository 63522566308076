import { baseUrl as baseUrlConst, sdkVersion } from '../consts';
import { unpackProjectPath } from './shared/projectUtils';
import { fetchContentUrl } from './payloadViaJs';
import { extractVarsFromOptions } from '../Variables/VarUtils';

function postData(url = '', data = {}) {
  // Default options are marked with *
  return fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then((response) => response.json()); // parses JSON response into native JavaScript objects
}

export async function getPublicProject(
  path,
  options = {},
  hiddenFields = {},
  vars = {},
  updateInBackground = null
) {
  const {
    error,
    accountStageProjectId,
    libOptions,
    details,
  } = await unpackProjectPath(path);
  const { loadJson } = hiddenFields;
  let loadJsonObj = loadJson && JSON.parse(loadJson);

  if (error) {
    return { error };
  }

  const baseUrlSafe = (libOptions && libOptions.baseUrl) || baseUrlConst;

  try {
    let results;
    console.log(`loadJson.versionLoad ${loadJsonObj}`);

    // If the SDK has native implementation - which loads before the cloud gives a response
    // The local version loads first and then requests a new version from server.
    // Cases:
    //  - local version is the latest -> no response from server
    //     - If unpublished, send up date modified as well for server to determine
    //  - If unpublished - always be in debug mode.
    //  - If new project or SDK is available/required, tell iOS to download, if succeeds:
    //     - Load new project+SDK immediately if nothing loaded
    //     - Otherwise: tell SDK to store new value to load next time.
    const hasNativeImpl = loadJsonObj && loadJsonObj.versionLoad;
    const usingNativeImpl = hasNativeImpl && !options.forceVersion;

    const [builtInVars, userVars] = await extractVarsFromOptions(
      options,
      hiddenFields,
      vars
    );

    const projDataPath = `${baseUrlSafe}/api/v1/public/projects/${accountStageProjectId}`;
    const postDataBody = {
      cachedVers: [], // Versions that the SDK has cached
      cachedSdks: [], // Versions of SDK's iOS has cached
      resources: [], // Resources that the SDK has cached
      debug: !!options?.forceDebug, // If debug mode is forced on
      sdkInputs: builtInVars, // Information provided by the SDK (eg: platform)
      userInputs: userVars, // Fields provided by the user to the SDK
      hasNativeImpl, // If the SDK has native implementation in case no SC anything is available
      forceVersion: options?.forceVersion, // If a particular version is being forced to load
      forceTarGz: usingNativeImpl, // Force tarballs
      details, // Details from info.js that's included in the project in case
      //          of native: the required fields are projectVer / isPublished
    };

    console.log(`${projDataPath}, ${postDataBody}`);

    if (usingNativeImpl) {
      // console.log('native implementation:', usingNativeImpl);
      // This is a very fast fetch since it's off of the local file system
      const projectVer = await fetchContentUrl(loadJsonObj.versionLoad);
      results = { projectVer };

      // Also fetch from server (and store new version / sdk if needed)
      // This is done via a promise that we don't wait to await for!
      postData(projDataPath, postDataBody).then((response) => {
        updateInBackground && updateInBackground(response);
      });
    } else {
      // console.log('Getting project from:', projDataPath);
      results = await postData(projDataPath, postDataBody);
    }
    return { results };
  } catch (error) {
    return { error };
  }
}

export async function postAction(path, projectVersion, payload) {
  console.log('Post action payload', { path, projectVersion, payload });
  const {
    error,
    accountStageProjectId,
    libOptions,
    details,
  } = await unpackProjectPath(path);

  if (error) {
    throw new Error(error);
  }

  const baseUrlSafe = libOptions?.baseUrl || baseUrlConst;

  const url = `${baseUrlSafe}/api/v1/public/projects/${accountStageProjectId}/versions/${projectVersion}/response`;

  console.log(url);
  return postData(url, payload);
}
