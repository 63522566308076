import {
  NAV_TITLE_CHANGE,
  NAV_SUBTITLE_CHANGE,
  NAV_INC_SIDEBAR_COUNT,
  NAV_DEC_SIDEBAR_COUNT,
} from '../actions/types';

export const initialState = {
  title: null,
  subtitle: null,
  subtitleDescription: null,
  sidebarCount: 0,
  projectId: null,
  projectVersion: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NAV_TITLE_CHANGE:
      return {
        ...state,
        title: action.title,
        subtitle: null,
        subtitleDescription: null,
        projectId: null,
        projectVersion: null,
      };
    case NAV_SUBTITLE_CHANGE:
      return {
        ...state,
        subtitle: action.subtitle,
        subtitleDescription: action.subtitleDescription
          ? action.subtitleDescription
          : state.subtitleDescription,
        projectId: action.projectId ? action.projectId : state.projectId,
        projectVersion: action.projectVersion
          ? action.projectVersion
          : state.projectVersion,
      };
    case NAV_INC_SIDEBAR_COUNT:
      return {
        ...state,
        sidebarCount: state.sidebarCount + 1,
      };
    case NAV_DEC_SIDEBAR_COUNT:
      return {
        ...state,
        sidebarCount: state.sidebarCount - 1,
      };
    default:
      return state;
  }
};
