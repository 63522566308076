import message from 'antd/lib/message';
import log from 'lib/logging';

message.config({
  maxCount: 3,
});

export const userNotLoggedInMsg = 'User is not logged in';

const ERROR_WITH_FIELD_TYPE = 'ErrorWithField';

export function filterForFieldErrors(errors, fieldName) {
  const errorsFound =
    errors &&
    Array.isArray(errors) &&
    errors.filter((error) => error.type === ERROR_WITH_FIELD_TYPE);

  if (errorsFound) {
    return errorsFound.reduce(function (map, err) {
      map[err.field] = err.description;
      return map;
    }, {});
  }
  return {};
}

export function nonFieldErrorMessage(errors) {
  let errorFound =
    errors &&
    Array.isArray(errors) &&
    errors.find((error) => {
      return error.type !== ERROR_WITH_FIELD_TYPE;
    });

  // Deal with the case where maybe the returned error isn't an array
  if (!errorFound && errors && !Array.isArray(errors)) {
    errorFound = errors;
  }

  if (errorFound) {
    if (errorFound.message) {
      return errorFound.message;
    }
    return errorFound;
  }
  return null;
}

export function getServerErrorSafely(error) {
  // This is an error _object_ - so to get the message you must access message first
  // Example: {"status":false,"error":[{"field":"version","description":"Version must be three numbers separated by periods","type":"ErrorWithField"}]}
  // log.info('error object:', error.message);
  try {
    const errorObj = JSON.parse(error.message).error;
    // log.info('errorObj', errorObj);
    if (errorObj.message) {
      return String(errorObj.message);
    } else {
      return errorObj;
    }
  } catch (e) {
    log.info('error in parsing', e);
    try {
      return String(error.message);
    } catch (e) {
      try {
        return JSON.stringify(error);
      } catch (e) {
        log.error('Generic Error failed to decode', error);
      }
    }
  }
  return 'Error - Internal Error';
}

export function displayError(error) {
  log.error('Error: ', error);
  message.error(error);
}
