/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import { SubComponentPropsInterface } from 'supportchef-sdk-core';
import { CoreType, ThemePropsType } from 'supportchef-sdk-core';
import * as multChoiceStyle from './MultipleChoiceStyles';

export interface BaseMultipleChoiceProps extends SubComponentPropsInterface {
  focused?: any;
  adjoined?: any;
  error?: any;
  disabled?: any;
  // size?: any;
  theme: any;
  children?: any;
  autoFocus?: any;
  inputRef?: any;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  checked?: any;
  type?: string;
  name?: string; // Is this used?
  themedProps: ThemePropsType;
  coreObj: CoreType;
}

export interface BaseMultipleChoiceComponentState {
  focused: boolean;
}

const defaultValues = {
  text: 'Placeholder Text',
};

export class BaseMultipleChoiceComponent extends React.Component<
  BaseMultipleChoiceProps,
  BaseMultipleChoiceComponentState
> {
  static componentName = 'BaseMultipleChoice';

  static defaultProps = {
    'aria-label': null,
    'aria-labelledby': null,
    'aria-describedby': null,
    autoComplete: 'on',
    autoFocus: false,
    disabled: false,
    error: false,
    name: '',
    inputRef: React.createRef(),
    onBlur: () => {},
    onChange: () => {},
    onKeyDown: () => {},
    onKeyPress: () => {},
    onKeyUp: () => {},
    onFocus: () => {},
    overrides: {},
    placeholder: '',
    required: false,
    // size: SIZE.default,
    type: 'text',
  };

  constructor(props: BaseMultipleChoiceProps) {
    super(props);

    this.state = {
      focused: this.props.autoFocus || false,
    };
  }

  componentDidMount() {
    const { autoFocus, inputRef } = this.props;
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }

  onFocus = (e) => {
    this.setState({ focused: true });
    this.props.onFocus(e);
  };

  onBlur = (e) => {
    this.setState({ focused: false });
    this.props.onBlur(e);
  };

  getInputProps = (props) => {
    const {
      autoComplete,
      disabled,
      error,
      id,
      inputRef,
      ref,
      name,
      onChange,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      required,
      rows,
      type,
      value,
      autoFocus,
      checked,
    } = props;

    return {
      ref: ref || inputRef,
      'aria-label': props['aria-label'],
      'aria-labelledby': props['aria-labelledby'],
      'aria-describedby': props['aria-describedby'],
      'aria-invalid': !!error,
      'aria-required': required,
      autoComplete,
      disabled,
      id,
      name,
      onBlur: this.onBlur,
      onChange,
      onFocus: this.onFocus,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      type,
      autoFocus,
      checked,
      // ...(type === CUSTOM_INPUT_TYPE.textarea ? { rows } : {}),
    };
  };

  render() {
    const {
      isEditing,
      element,
      checked,
      type,
      children,
      themedProps,
      coreObj,
    } = this.props;
    const { StyledComponent: InputWrapper, classNames } = themedProps;

    const customTextProps = { ...defaultValues, ...(element || {}) };

    const inputSharedProps = { ...this.props, ...this.state };

    // const baseStyle = getInputStyles(inputSharedProps);
    // const containerStyle = getInputContainerStyles(inputSharedProps);
    const inputProps = this.getInputProps(inputSharedProps);

    // const { value } = inputSharedProps;

    // Type is radio or checkbox
    let className = `multChoiceBox ${type} ${classNames} `;

    className += coreObj.boolPropsToClassName(inputSharedProps, [
      'checked',
      'error',
      'focused',
      'disabled',
    ]);

    return (
      <InputWrapper>
        <span className={className}>
          <input {...inputProps} tabIndex={isEditing ? -1 : undefined} />
          <span className="multChoiceBoxInner" />
        </span>
        {children}
      </InputWrapper>
    );
  }
}

export default (coreObj: CoreType) =>
  coreObj.registerStyledComponent(multChoiceStyle, BaseMultipleChoiceComponent);
