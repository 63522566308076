/*
  Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except
  in compliance with the License. A copy of the License is located at

      http://aws.amazon.com/apache2.0/

  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import {
  LOGGED_IN_STATUS_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  REGISTER_USER,
  AUTH_FORM_UPDATE,
  IDENTITY_UPDATED,
  LOGOUT,
  REGISTER_USER_CONFIRM_NEEDED,
  REGISTER_USER_CONFIRM_SUCCESS,
  REGISTER_USER_CONFIRM_FAILED,
  REGISTER_USER_CONFIRM_CODE,
  SEND_CONFIRM_CODE,
  SEND_CONFIRM_CODE_SUCCESS,
  SEND_CONFIRM_CODE_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_CODE_SENT,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  AUTHED_EMAIL_CHANGED,
} from '../actions/types';

export const initialState = {
  authedEmail: '',
  username: '',
  password: '',
  confirmationCode: '',
  error: '',
  forgotPasswordError: '',
  changePasswordError: '',
  notice: '',
  loading: false,
  loggedIn: false,
  user: null,
  needEmailConfirmation: false,
  identityId: '',
  sendingConfirmCode: false,
  confirmCodeSentTimestamp: null,
  sendingForgotPasswordCode: false,
  sendingForgotPasswordReset: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: '',
        notice: '',
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...initialState,
        user: action.user,
        authedEmail: state.username,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        error: action.error || 'Authentication Failed',
        password: '',
        loading: false,
      };
    case LOGGED_IN_STATUS_CHANGED:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case LOGOUT:
      return initialState;
    case AUTH_FORM_UPDATE:
      return {
        ...state,
        [action.prop]: action.value,
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: '',
        notice: '',
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        username: action.username,
        authedEmail: action.username,
        notice:
          'Registration successful. Please enter confirmation code in your email',
      };
    case REGISTER_USER_FAILED:
      return {
        ...initialState,
        error: action.error || 'Registration Failed',
      };
    case REGISTER_USER_CONFIRM_NEEDED:
      return {
        ...state,
        needEmailConfirmation: true,
        loading: false,
      };
    case REGISTER_USER_CONFIRM_SUCCESS:
      return {
        ...state,
        needEmailConfirmation: false,
        loading: false,
      };
    case REGISTER_USER_CONFIRM_FAILED:
      return {
        ...state,
        loading: false,
        needEmailConfirmation: true,
        error: action.error || 'Email confirmation code is incorrect',
      };
    case REGISTER_USER_CONFIRM_CODE:
      return {
        ...initialState,
        loading: true,
      };
    case SEND_CONFIRM_CODE:
      return {
        ...state,
        sendingConfirmCode: true,
      };
    case SEND_CONFIRM_CODE_SUCCESS:
      return {
        ...state,
        sendingConfirmCode: false,
        confirmCodeSentTimestamp: action.timestamp,
      };
    case SEND_CONFIRM_CODE_FAILURE:
      return {
        ...state,
        sendingConfirmCode: false,
        error: action.error || 'Registration Failed',
      };
    case IDENTITY_UPDATED:
      return {
        ...state,
        identityId: action.identityId,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        sendingForgotPasswordCode: true,
      };
    case FORGOT_PASSWORD_CODE_SENT:
      return {
        ...state,
        sendingForgotPasswordCode: false,
        forgotPasswordCodeSentTimestamp: action.timestamp,
        forgotPasswordError: '',
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        sendingForgotPasswordReset: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        sendingForgotPasswordReset: false,
        forgotPasswordError: '',
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        sendingForgotPasswordCode: false,
        sendingForgotPasswordReset: false,
        forgotPasswordError: action.error || 'Password reset failed',
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        startingPasswordChange: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        startingPasswordChange: false,
        changePasswordError: '',
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        startingPasswordChange: false,
        changePasswordError: action.error || 'Password change failed',
      };
    case AUTHED_EMAIL_CHANGED:
      return {
        ...state,
        authedEmail: action.email,
      };
    default:
      return state;
  }
};
