/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import {
  SubComponentPropsInterface,
  VariableAccessor,
  StyledComponentType,
} from 'supportchef-sdk-core';
import { BaseInputComponent, BaseInputProps } from './BaseInputComponent';

export interface InputComponentProps extends SubComponentPropsInterface {
  isFocused: any;
  adjoined: any;
  error: any;
  disabled: any;
  size: any;
  theme: any;
  children: any;
  placeholder?: string;
  varname: string;
}

export interface InputComponentState {
  value: any;
}

const defaultValues = {
  text: 'Placeholder Text',
};

export class InputComponent extends React.Component<
  InputComponentProps,
  InputComponentState
> {
  static componentName = 'InputComponent';
  static variableDefs = [{ types: ['string'] }];

  Input: StyledComponentType<BaseInputComponent, BaseInputProps>;

  private varstore: VariableAccessor;
  constructor(props: InputComponentProps) {
    super(props);
    this.varstore = props.utils.getVariableAccessor(props);
    this.state = { value: this.varstore && this.varstore.get() };

    this.Input = props.coreObj.StyledComponentFactory<
      BaseInputComponent,
      BaseInputProps
    >(BaseInputComponent.componentName, props);
  }

  componentDidMount = () => {
    if (this.varstore) {
      this.varstore.listen(() => {
        this.setState({ value: this.varstore.get() });
      });
    }
  };

  componentWillUnmount = () => {
    if (this.varstore) {
      this.varstore.unmount();
    }
  };

  onChange = (evt) => {
    const { modifyFormEntryAtPath, path, isEditing } = this.props;
    const placeholderUnsafe = evt.target.value;
    const placeholder = placeholderUnsafe === '' ? null : placeholderUnsafe;

    if (isEditing) {
      console.log(path, placeholder);
      modifyFormEntryAtPath(path, { placeholder });
    } else {
      this.varstore.set(evt.target.value);
    }
    // modifyCustom({ text });
  };

  render() {
    const { isEditing, element, placeholder } = this.props;
    const { value } = this.state;

    return (
      <this.Input onChange={this.onChange} {...this.props} value={value} />
    );
  }
}
