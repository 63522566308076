import RegisterGenericContainerComponent, {
  GenericContainerComponentName,
} from './GenericContainerComponent';
import * as GenericContainerStyles from './GenericContainerStyles';

import { registerBlock } from '/lib/loadComponents';
registerBlock((coreObj) => {
  RegisterGenericContainerComponent(coreObj);
  coreObj.registerStyles(GenericContainerStyles);
});

export { GenericContainerStyles, GenericContainerComponentName };
