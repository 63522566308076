export const entryNode = 'entryNode';
export const actionNode = 'actionNode';
export const subFormNode = 'subFormNode';
export const conditionalNode = 'conditionalNode';
export const endpointNode = 'endpointNode';

export default {
  entryNode,
  actionNode,
  subFormNode,
  conditionalNode,
  endpointNode,
};
