import * as React from 'react';
import { BaseGraphNode } from './BaseGraphNode';
import { getVarStore } from '/lib/componentUtils';
import { GraphTraverser } from 'GraphTraverser';

export class EntryGraphNode extends BaseGraphNode {
  getName = () => {
    const metadata = this.getMetadata();
    return metadata?.name;
  };

  getIsDefault = () => {
    const metadata = this.getMetadata();
    return !!metadata?.isDefault;
  };

  /**
   * Sets initial variables imported by an entry node
   * @param graphTraverser
   * @returns If a variable has changed (meaning a re-render is needed)
   */
  setInitialVars = (graphTraverser: GraphTraverser) => {
    const varStore = getVarStore(graphTraverser);
    // console.log('entry node this.node', this.node);
    const { inputs } = this.node?.metadata;
    let hasVariableChanged = false;

    if (inputs) {
      const { vars } = graphTraverser.props;
      if (!vars) {
        return hasVariableChanged;
      }
      inputs.forEach((singleVar) => {
        if (singleVar.name) {
          const foundVal = vars[singleVar.name];
          if (foundVal) {
            varStore.initcheck(singleVar.output);
            const curVal = varStore.get(singleVar.output);
            if (curVal !== foundVal) {
              varStore.set(singleVar.output, foundVal);
              hasVariableChanged = true;
            }
          }
        }
      });
    }
    return hasVariableChanged;
  };
}
