let editorItems = null;
const editorKeySeparator = '&';

export const editorKey = (category, key) =>
  `${category}${editorKeySeparator}${key}`;

export const registerEditorItem = (editorDefinition) => {
  // console.log('Registering editor item', editorDefinition.key);

  const { key } = editorDefinition;
  editorDefinition.config.editorKey = key;

  // We don't allocate this until registering because
  // we only use this function when using editor
  if (!editorItems) {
    editorItems = new Map();
  }
  editorItems.set(editorDefinition.key, editorDefinition);
};

export const getEditorItemByKey = (editorKey) => {
  return editorItems.get(editorKey);
};
